

import { Component, Prop, Watch } from 'vue-property-decorator';
import { PageBase } from '@/core/models/shared';

@Component
export default class AceiteTermos extends PageBase {

    @Prop() private value!: boolean;

    dialog: boolean = false;
    loadingButton: boolean = false;

    aceitouTermosDeUso: boolean = false;
    aceitouPoliticaPrivacidade: boolean = false;
    abrirTermoUso: boolean = false;

    @Watch('value')
    async WatchValue() {
        this.dialog = this.value;

    }

    Cadastrar() {
        this.dialog = false;
        this.$emit('Aceitou');
    }
    
    Fechar() {
        this.dialog = false;
        this.$emit('fechou');
    }
}
