
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { MedicaoService} from "@/core/services/projeto";
import {  Medicao,} from "@/core/models/projeto";
import { TipoDocumento } from "@/core/models/financeiro";
import { TipoDocumentoService } from "@/core/services/financeiro";
import * as jsonpatch from "fast-json-patch"; 

@Component
export default class ContratoFornecedor extends Vue {
  @Prop() public item!: Medicao;
  @Prop() public value!: string;

  service = new MedicaoService();
  despesa = new Modelo();
  execucao = new Medicao();

  tipoDocumentos = [] as TipoDocumento[];
  dialogExecucoes: boolean = false;
  dialogDespesa: boolean = false;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  tabActive = null;
  tab = null;
 
  valid = true;
  dialog = false;
  
  $refs!: {
    form: HTMLFormElement;
    formDespesa: HTMLFormElement;
  };

  listaTiposIntervalosVencimentoParcela: any[] = [
    { id: 1, name: "Diário" },
    { id: 7, name: "Semanal" },
    { id: 15, name: "Quinzenal" },
    { id: 30, name: "Mensal" },
  ];

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Número", value: "numero", align: "center" },
    { text: "Prestador", value: "fornecedorId", align: "center" },
    { text: "Empreendimento", value: "empreendimentoId", align: "center" },
    { text: "Data da medição", value: "data", align: "center" },
    { text: "Valor", value: "total", align: "center" },
    { text: "Situação", value: "despesaId", align: "center" },
  ];
 
  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("despesa")
  Despesa() {
    if (this.$refs.formDespesa) {
      this.$refs.formDespesa.resetValidation();
    }
  }
 
  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
    if (this.dialog) {
      this.observer = jsonpatch.observe(this.item);
    } else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }
 
  AbrirDespesa(item) {
    this.execucao = item;
    this.dialogDespesa = true;
  }

  SalvarDespesa() {
    if(this.$refs.formDespesa.validate()){
      let item = this.despesa;
      
      this.service.GerarDespesa(this.execucao.id, item).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Atualizar();
          this.CloseDespesa();
        },
        (err) => {
          this.$swal("Aviso",err.response.data, err.response.status == 400 ? "warning" : "error") 
       }
      );
    }
  } 

  CloseDespesa(){
    this.despesa = new Modelo();
    this.dialogDespesa = false;
  }

  Close() {
    this.dialog = false;
  }

  observer!: jsonpatch.Observer<Medicao>;

  AbrirExecucoes(item) {
    this.service.ObterPorId(item.id, 'Execucoes.Unidades, Execucoes.Documentos, Execucoes.ContratoItem.Etapa, Execucoes.Responsavel, ContratoFornecedor,ContratoFornecedor.Fornecedor, ContratoFornecedor.Empreendimento').then(res => {
      this.execucao = res.data
      this.dialogExecucoes = true;
    },
    err => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    })
  }

  Atualizar() {
    let filter = {contratoFornecedorId:this.execucao.contratoFornecedorId}
    new MedicaoService().ObterMedicoes(-1, -1, [],[],'',[],filter, '' , '', 'Execucoes,ContratoFornecedor.Fornecedor, ContratoFornecedor.Empreendimento').then(
      (res) => {
        this.item = res.data;
      }, 
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }
    ) 
  }

  ExcluirMedicao(item){
   this.execucao.contratoFornecedorId = item.contratoFornecedorId;

    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id).then(
          (res) => {
            if (res.status == 200) {
              this.Atualizar();
              return res;
            }
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result:any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
      }
    });
  }

  situacaoColor(item) {
    if (item.despesaId > 0) {
      return "green darken-0";
    } 
    else return "blue lighten-2"
  }

  mounted() {
    new TipoDocumentoService().ListarTudo().then(
      res => this.tipoDocumentos = res.data.items
    )}  
  }

class Modelo{
  public dataPrimeiroVencimento: string = "";
  public tipoDocumentoId: number = 0
  public prazoDiasParcela: number = 0
  public quantidadeParcela: number = 0
}
