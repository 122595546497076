
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { EnumSituacaoReceitaParcela, EnumTipoReceita, Receita,ReceitaParcela, ValoresAdicionais} from "@/core/models/financeiro";
import { SituacaoReceitaParcelaService } from "@/core/services/financeiro";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { CalcularDataParcela, SituacaoColor, ObterMelhorDia} from '@/assets/scripts/helper';
import { TipoIntervaloParcelasService } from "@/core/services/contratos";

@Component
export default class GerenciamentoReceita extends Vue {
  @Prop() public item!: Receita;
  @Prop() public receitaEntrada!:Receita;
  @Prop() public receitaIntermediaria!:Receita;
  @Prop() public value!: string;
    
  parcela = new ReceitaParcela();  
  situacaoParcelas = [];
  listaEmpresa = [];
  tiposIntervaloParcelas = [];

  tipoIntervaloParcela:number = 1;
  dialogParcela:boolean = false; 
  dialog:boolean = false;
  isLoading: boolean = false;

    
  valorInicialParcelas:number = 0;
  numeroParcelas:number = 0;
  parcelaIndex:number = -1;

  headerParcelas: any[] = [
    { text: "", value: "actions", sortable: false},
    { text: "Nº Parcela", value: "parcela", sortable: false},
    { text: "Valor", value: "valor", sortable: false},
    { text: "Vencimento", value: "dataVencimento", sortable: false},
    { text: "Situação", value: "situacaoId", sortable: false}
  ];

  listaTiposIntervalosVencimentoParcela = [
    { id: 1, nome: "Mensal" },
    { id: 2, nome: "Anual" }, 
  ];

  @Watch("dialog")
  Dialog() { 
    if (!this.dialog) {
      this.$emit("fechou");  
    }else{
      this.ObterMelhorDia(this.item.dataInicialParcelas);
    } 
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 
  } 

  @Watch("item.gerarFinanciamentoPosEntrada")
  FinanciamentoPosEntrada(){
    if(this.item.gerarFinanciamentoPosEntrada == true){
      const ultimaParcela = this.receitaEntrada.parcelas[this.receitaEntrada.parcelas.length - 1];
      
      if(ultimaParcela){
        const dataVencimentoParcela = new Date(ultimaParcela.dataVencimento);
        dataVencimentoParcela.setMonth(dataVencimentoParcela.getMonth() +1)
        this.item.dataInicialParcelas = new Date(dataVencimentoParcela).yyyymmdd();
      } 

    }else{
      this.item.dataInicialParcelas = new Date().yyyymmdd();
    }
  }
     
  @Watch("item.dataInicialParcelas")
  ObterMelhorDia(value){
    const dataVencimentoParcela = new Date(value);
    dataVencimentoParcela.setDate(dataVencimentoParcela.getDate() + 1);
    this.item.melhorDia = dataVencimentoParcela.getDate();
  }
   
  Salvar(){
    if(this.item.empresaId == 0)
      return this.$swal("Aviso","É necessário selecionar a Empresa.", "warning");
    
    if(this.item!.parcelas.length == 0)
      return this.$swal("Aviso","É necessário gerar as parcelas.", "warning");
      
    this.Close();
  }  

  Reset(){
    this.item.parcelas.forEach((x:any) => { x.id = 0; x.receitaId = 0; x.contratoId = 0; x.situacao = null; x.tipoServicoId = null; x.ultimaDataPagamento = null; x.ultimaFormaPagamento = null}) 
    this.numeroParcelas = 0;
    this.valorInicialParcelas = 0;
    this.tipoIntervaloParcela = 1;
  }
 
  Close() {
    this.Reset();
    this.dialog = false;
  }

  GerarParcelas(numeroParcelas: number, dataInicialParcelas: string, valorInicialParcelas: number, tipoIntervaloParcela: any) {
    this.isLoading = true;
    let dataInicial = new Date(Date.parse(`${dataInicialParcelas} 00:00:00`));
    let mes = new Date(dataInicialParcelas).getMonth();
    this.item.parcelas = [];

    for (let i = 1; i <= numeroParcelas; i++) {
      const dia = ObterMelhorDia(dataInicial, this.item.melhorDia, i);
      let valoresAdicionais = {taxaBoleto: this.item.valoresAdicionaisParcela.taxaBoleto}

      let parcela: any = {
        parcela: i,
        valor: valorInicialParcelas,
        dataVencimento: CalcularDataParcela(dataInicial, tipoIntervaloParcela, i, dia, mes),
        dataVencimentoOriginal: CalcularDataParcela(dataInicial, tipoIntervaloParcela, i, dia, mes),
        situacaoId: EnumSituacaoReceitaParcela.Aberto,
        valoresAdicionais: valoresAdicionais,
      };

      // Integração da lógica adicional para calcular vencimentos intermediários
      if (this.item.tipoId == EnumTipoReceita.Titulo) {
          const intermediariaParcelas = this.receitaIntermediaria.parcelas;
          let vencimentoCalculadoAux;

          if (this.item.parcelas.length > 0) {
            vencimentoCalculadoAux = new Date(this.item.parcelas[this.item.parcelas.length - 1].dataVencimento);
            vencimentoCalculadoAux.setMonth(vencimentoCalculadoAux.getMonth() + 1);
            vencimentoCalculadoAux.setDate(dia);
            } else {
            vencimentoCalculadoAux = new Date(parcela.dataVencimento);
          }

          if (intermediariaParcelas && this.item.gerarFinanciamentoComIntermediaria) {
            if (intermediariaParcelas.some(x => new Date(x.dataVencimento).getMonth() == vencimentoCalculadoAux.getMonth() && new Date(x.dataVencimento).getFullYear() == vencimentoCalculadoAux.getFullYear())) {
              intermediariaParcelas.forEach(x => {
                if (new Date(vencimentoCalculadoAux).getMonth() == new Date(x.dataVencimento).getMonth() && new Date(vencimentoCalculadoAux).getFullYear() == new Date(x.dataVencimento).getFullYear()) {
                  vencimentoCalculadoAux.setMonth(vencimentoCalculadoAux.getMonth() + 1);
                }
              });
            }
          }

          parcela.dataVencimento = vencimentoCalculadoAux.toISOString(); // Convertendo para o formato desejado (exemplo: YYYY-MM-DD)
          parcela.dataVencimentoOriginal = parcela.dataVencimento;
      }
      this.item.parcelas.push(parcela);
    }
    this.isLoading = false;
  }


  ExcluirParcela(item) {
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja excluir o registro atual?",
    icon: "question",
    showCancelButton: true, 
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm:true,
      preConfirm:() => {
        const index = this.item.parcelas!.indexOf(item);
        this.item.parcelas!.splice(index, 1);
        return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
      } 
    })
  } 

  EditarParcela(item) {
    this.parcela = item;
    this.parcela.dataVencimento = new Date(item.dataVencimento).yyyymmdd();
    this.dialogParcela = true;
  }

  Confirmar(){
   // Obtenha o índice da parcela editada na lista item.parcelas
    let indiceParcelaEditada = this.item.parcelas.indexOf(this.parcela);
    let valorParcelaEditada = this.parcela.valor;

    // Calcule o valor total das parcelas anteriores à parcela editada
    let valorParcelasAnteriores = 0;
    for (let i = 0; i < indiceParcelaEditada; i++) {
      valorParcelasAnteriores += this.item.parcelas[i].valor;
    }

    // Calcule o saldo restante após a edição da parcela
    let saldoRestante = this.item.valor - valorParcelasAnteriores - valorParcelaEditada;
    
    // Atualize as parcelas posteriores à parcela editada
    for (let i = indiceParcelaEditada + 1; i < this.item.parcelas.length; i++) {
      let parcela = this.item.parcelas[i];

      let saldo = (saldoRestante > 0 ? saldoRestante : 0) / (this.item.parcelas.length - (indiceParcelaEditada + 1));
      parcela.valor = parseFloat(Number(saldo).toFixed(2)); 
    }

    this.item.valor = this.item.parcelas.reduce((total, parcela) => total + parcela.valor, 0);
    this.parcela = new ReceitaParcela();
    this.dialogParcela = false;
  }
  
  SituacaoColor(item:ReceitaParcela){
    return SituacaoColor(item)
  }

  mounted() {
    new TipoIntervaloParcelasService().ListarTudo().then(
      res => {this.tiposIntervaloParcelas = res.data.items}) 

    new SituacaoReceitaParcelaService().ListarTudo().then(
      res => {this.situacaoParcelas = res.data.items});

    new EmpresaService().Listar(-1, -1, ['nomeFantasia'],[],'',[], '', '' , 'Id, nomeFantasia', '').then(
      res => {this.listaEmpresa = res.data.items});
  }  
}
