
import { Contrato, ContratoCliente, ContratoCorretor,Unidade } from "@/core/models/cadastros";
import Pessoa from "@/core/models/cadastros/Pessoa";
import { EmpreendimentoService, PessoaService, TipoIndiceService } from "@/core/services/cadastros";
import { ContratoService } from "@/core/services/cadastros/ContratoService";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { ContaCorrenteService, IntervaloReajusteService, ParametroFinanceiroService, ReceitaService, TipoAmortizacaoService, TipoContratoService, TipoDocumentoService} from "@/core/services/financeiro";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Encargo, EnumSituacaoReceitaParcela, EnumTipoIntervaloParcelas, EnumTipoReceita, Receita, ReceitaParcela, TipoAmortizacao, ValoresAdicionais } from "@/core/models/financeiro";
import { PlanoPagamentoModelo } from "@/core/models/contratos";
import {  EnumTipoMesReajuste } from "@/core/models/contratos/PlanoPagamentoModelo";
import { TratarErroApi, ObterTipoAno, ObterTipoMes, CalcularDataParcela, ObterMelhorDia } from "@/assets/scripts/helper";
import { TipoIntervaloParcelasService, TipoOperacaoFinanceiraService } from "@/core/services/contratos";
  
@Component
export default class CadastroContrato extends Vue {
  @Prop() public item!: Contrato; 
  @Prop() public value!: string;
       
  service = new ContratoService();
  empreendimentoService = new EmpreendimentoService();
  receitaService = new ReceitaService();

  receitaGerenciada = new Receita(); 
  receitaSaldoDevedor = new Receita(1);
  receitaEntrada = new Receita(3); 
  receitaIntermediaria = new Receita(5);
  planoPagamento = new PlanoPagamentoModelo();

  listaEmpresas = []; 
  tiposContratos = [];
  gatways = [];
  tiposAmortizacao = [];
  tiposIntervalos = [];
  listaEmpreendimentos = []; 
  listaGrupos = [];
  listaUnidades = [];
  listaIndices = [];
  listaTiposDocumentos:any = []; 
  tiposIntervaloParcelas = [];
  listaContaCorrentes = [];
  tiposOperacaoContrato = [];
   
  valoresAdicionaisParcela = new ValoresAdicionais();
  contratoCorretor = new ContratoCorretor();
  contratoCliente = new ContratoCliente();
  pessoa = new Pessoa();
  unidade = new Unidade();

  itemIndex:number = -1;
  itemIndexCliente:number = -1;
  stepHorizontal:number = 1;
  e13:number = 1;
  tab:number = 0;

  show:boolean = false;
  dialogCadastroPessoa:boolean = false;
  dialogGerencial:boolean = false;
  dialogMultiLotes:boolean = false;
  validCliente:boolean = true;
  validCorretor:boolean = false; 
  valid:boolean = true;
  overlay:boolean = false;
  dialog:boolean = false;
  dialogGerencialPrice:boolean = false;
  AplicarDiasProtesto = false;
  
  tipoMesReajusteId:number = 0;
  errorAlert:boolean = false;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement; 
    formCorretor: HTMLFormElement;
    formCliente: HTMLFormElement; 
  }; 

  ObterUnidade(){
    this.empreendimentoService.ObterUnidade(this.item.unidadePrincipalId, 'PlanoPagamento.Financiamento.Tipo, PlanoPagamento.Financiamento.TipoIntervaloParcela,PlanoPagamento.Entrada.Tipo, PlanoPagamento.Entrada.TipoIntervaloParcela, PlanoPagamento.Intermediaria.Tipo, PlanoPagamento.Intermediaria.TipoIntervaloParcela, Imovel, Lote, PlanoPagamento.IntervaloReajuste, PlanoPagamento.TipoAnoInicioReajuste, PlanoPagamento.TipoMesReajuste, PlanoPagamento.TipoIndice, PlanoPagamento.TipoAmortizacao, PlanoPagamento.TipoValorTotal').then(
      (res) => {
        this.unidade = res.data;
        this.planoPagamento = new PlanoPagamentoModelo(this.unidade.planoPagamento);

        if(this.unidade.planoPagamento != null){

          let valoresAdicionais = this.valoresAdicionaisParcela;
 
          this.valoresAdicionaisParcela = {gestao:this.planoPagamento.taxaGestao,valorMPI:0, percentualMPI:this.planoPagamento.seguroMPI,valorDFI:0, percentualDFI:this.planoPagamento.seguroDFI, correcaoIndice:0, isDFIFixo:this.planoPagamento.isDFIFixo, taxaBoleto:valoresAdicionais.taxaBoleto, nomeTaxaAdicional:'', valorFixoTaxaAdicional:0}
          this.item.valor = this.unidade.valorTotalUnidade;
          this.ConfigurarPlano();
        }else{
          return this.errorAlert = true;
        }
      })
  };  
  
  ConfigurarPlano() { 
    this.ResetReceitas();  
    this.ConfigurarContrato(this.planoPagamento)    
    this.ObterDadosReceita(this.planoPagamento.entrada.prazo, this.item.data, this.unidade.valorEntradaPlanoPagamento, EnumTipoReceita.TituloEntrada,this.valoresAdicionaisParcela, EnumTipoIntervaloParcelas.Mensal);
    this.ObterDadosReceita(this.planoPagamento.intermediaria.prazo, this.item.data, this.unidade.valorIntermediariaPlanoPagamento, EnumTipoReceita.TituloIntermediaria, this.valoresAdicionaisParcela, this.planoPagamento.intermediaria.tipoIntervaloParcelaId);
    this.ObterDadosReceita(this.planoPagamento.financiamento.prazo, this.dataPrimeiroVencimento(), this.unidade.valorFinanciamentoPlanoPagamento, EnumTipoReceita.Titulo, this.valoresAdicionaisParcela, EnumTipoIntervaloParcelas.Mensal);
    this.ObterMesAno(this.planoPagamento.tipoMesReajusteId, this.planoPagamento.tipoAnoInicioReajusteId);
  }

  ConfigurarContrato(planoPagamento){
    this.item.data = new Date().yyyymmdd();
    this.item.tipoIndiceId = planoPagamento.tipoIndiceId;
    this.item.intervaloReajusteId = planoPagamento.intervaloReajusteId;
    this.item.tipoAmortizacaoId = planoPagamento.tipoAmortizacaoId;
    this.item.jurosAmortizacao = planoPagamento.jurosTabela;
    this.tipoMesReajusteId = planoPagamento.tipoMesReajusteId;
  }

  dataPrimeiroVencimento(){
    const ultimaParcela = this.receitaEntrada.parcelas[this.receitaEntrada.parcelas.length - 1];
    const dataInicial = new Date(this.item.data);

    if(ultimaParcela && this.planoPagamento.financiamento.gerarFinanciamentoPosEntrada == true){
      const dataVencimentoParcela = new Date(ultimaParcela.dataVencimento);
      dataVencimentoParcela.setMonth(dataVencimentoParcela.getMonth() + 1);
      return new Date(dataVencimentoParcela).yyyymmdd();
    }

    return dataInicial.yyyymmdd();
  } 

  @Watch("contratoCorretor")
  ContratoCorretor() {
  if (this.$refs.formCorretor) {
      this.$refs.formCorretor.resetValidation();
    }
  }
  
  @Watch("contratoCliente")
  ContratoCliente() {
  if (this.$refs.formCliente) {
      this.$refs.formCliente.resetValidation();
    }
  }

  @Watch("item")
  Item() {
  if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }
 
  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }else{
      this.ObterMesAno();

      if(this.item.empreendimentoId > 0){
        this.ObterEmpreendimentos(this.item.tipoId), this.ObterGrupos(), this.ObterUnidades();
      }

      if(this.item.empreendimentoId && this.item.unidadePrincipalId){
        this.ObterUnidade();
      }
    } 
  }
  
  headersCliente: any[] = [
    { text: "", value:"actions", sortable: false },
    { text: "Nome CPF/CNPJ", value: "clienteId" },
  ];

  headersCorretor: any[] = [
    { text: "", value:"actions", sortable: false },
    { text: "Nome CPF/CNPJ", value: "corretorId" },
    { text: "Valor", value: "percentual" },
  ];

  meses = [
    { id: 1, nome: "Janeiro" },
    { id: 2, nome: "Fevereiro" },
    { id: 3, nome: "Março" },
    { id: 4, nome: "Abril" }, 
    { id: 5, nome: "Maio" },
    { id: 6, nome: "Junho" },
    { id: 7, nome: "Julho" },
    { id: 8, nome: "Agosto" },
    { id: 9, nome: "Setembro" },
    { id: 10, nome: "Outubro" }, 
    { id: 11, nome: "Novembro" },
    { id: 12, nome: "Dezembro" }, 
  ];
 
  @Watch('receitaEntrada.valor')
  @Watch('receitaIntermediaria.valor')
  @Watch('item.valor')
  gerenciarValores(){
    this.receitaSaldoDevedor.valor = Math.max(this.item.valor - this.receitaEntrada.valor - this.receitaIntermediaria.valor, 0);
  }
   
  Salvar() {
    this.$refs.form.validate();
    
    if (!this.Validacoes()) { 
      this.overlay = !this.overlay; 
      this.item.receitas = [];

      let ReceitasAdicionais = [this.receitaSaldoDevedor, this.receitaEntrada, this.receitaIntermediaria];
      let modelo = this.item.PrepararContrato(this.item, ReceitasAdicionais);

      this.service.GerarContrato(modelo, this.item.quantidadeParcelas).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close(); 
        },
        (err) => {
          TratarErroApi(err);
        }
      ).finally(() => this.overlay = false);
    }
  } 

  Close() {
    this.Reset();   
    this.dialog = false;
  }
     
  ObterParametroGatway(item){
    if(item){
      this.item.encargoFinanceiro = item.encargoFinanceiro;
      this.item.tipoGatewayId = item.tipoId;
    }else{
      this.item.encargoFinanceiro = new Encargo();
      this.item.tipoGatewayId = 0;
    } 
  }
 
  ObterParametroPorId(id){
    new ParametroFinanceiroService().ObterParametroEmpresaPorId(id, 'Gatways.Tipo').then(
      (res) => { 
        this.gatways = res.data.gatways;
        this.item.tipoAmortizacaoId = res.data.tipoAmortizacaoId;
        this.item.jurosAmortizacao = res.data.jurosAmortizacao;
        this.valoresAdicionaisParcela = res.data.valoresAdicionaisParcela;
      }, 
      (err) => {
        this.gatways = [];
        this.valoresAdicionaisParcela = new ValoresAdicionais();
        this.item.encargoFinanceiro = new Encargo();
      })
  }
  
  ObterEmpreendimentos(id){
    const filter = { tipoId: id == 2 ? 3 : id };
    this.item.tipoProdutoId = filter.tipoId == 1 ? 2 : 1;

    this.empreendimentoService.Listar(-1, -1, ['nome'], [], undefined, [], filter, '', "Id,Nome", "").then(
      (res) => {
        this.listaEmpreendimentos = res.data.items;
        filter.tipoId = 0;
      })
  }

  ObterGrupos(){
    this.empreendimentoService.ListarGrupo(this.item.empreendimentoId).then(
      (res) => {
        this.listaGrupos = res.data;  
      }) 
  }

  ObterUnidades(){ 
    this.empreendimentoService.ListarUnidade(this.item.grupoId).then(
      (res) => {
        this.listaUnidades = res.data.filter(x => x.situacaoId == 1);
      })
  }
   
  AdicionarCorretor() {
    if (this.$refs.formCorretor.validate()) {
      const corretorId = this.contratoCorretor.corretorId;
      const corretorExistente = this.item.corretores.find((x, index) => x.corretorId === corretorId && index !== this.itemIndex);
      const novoPercentualTotal = this.itemIndex > -1 ? this.item.corretores.reduce((acc, cur, index) => index !== this.itemIndex ? acc + +cur.percentual : acc, 0) + +this.contratoCorretor.percentual : this.item.corretores.reduce((acc, cur) => acc + +cur.percentual, 0) + +this.contratoCorretor.percentual;

      if (corretorExistente) 
        return this.$swal("Aviso", "Esta pessoa já foi adicionada na lista.", "warning");
      
      if (novoPercentualTotal > 100) 
        return this.$swal("Erro!", "Limite de percentual por corretor atingido. O valor máximo é de 100%", "warning");
      
      this.itemIndex > -1 ? Object.assign(this.item.corretores[this.itemIndex], this.contratoCorretor) : this.item.corretores.push(this.contratoCorretor);

      this.contratoCorretor = new ContratoCorretor();
      this.itemIndex = -1;
    }
  }  
  
  ExcluirCorretor(item) {
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja excluir o registro atual?",
    icon: "question",
    showCancelButton: true,  
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm:true,
    preConfirm:() => {
      const index = this.item.corretores.indexOf(item);
      this.item.corretores.splice(index, 1);
      return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
    },
    // @ts-ignore: Unreachable code error
    allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){ 
      }
    })
  }

  EditarCorretor(item){
    this.contratoCorretor = Object.assign({}, item);
    this.itemIndex = this.item.corretores.indexOf(item);
  }

  AdicionarCliente() {
    if (this.$refs.formCliente.validate()) {
      const clienteId = this.contratoCliente.clienteId;
      const clienteExistente = this.item.clientes.find((x, index) => x.clienteId === clienteId && index !== this.itemIndexCliente);

      if (clienteExistente) 
        return this.$swal("Aviso", "Esta pessoa já foi adicionada na lista.", "warning");

      if (this.itemIndexCliente > -1) {
        Object.assign(this.item.clientes[this.itemIndexCliente], this.contratoCliente);
      } else {
        this.item.clientes.push(this.contratoCliente);
        this.contratoCliente = new ContratoCliente();
      }

      this.contratoCliente = new ContratoCliente();
      this.itemIndexCliente = -1;
    }
  } 

  ExcluirCliente(item) {
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja excluir o registro atual?",
    icon: "question",
    showCancelButton: true,  
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm:true,
    preConfirm:() => {
      const index = this.item.clientes.indexOf(item);
      this.item.clientes.splice(index, 1);
      return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
    },
    // @ts-ignore: Unreachable code error
    allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
      }
    })
  }

  EditarCliente(item){
    this.contratoCliente = Object.assign({}, item);
    this.itemIndexCliente = this.item.clientes.indexOf(item);
  }

  CadastrarPessoa(){
    this.pessoa = new Pessoa();
    this.dialogCadastroPessoa = true;
  }
  
  ObterMesAno(tipoMesReajusteId?:number, tipoAnoInicioReajusteId?:number){
    const data = new Date(this.item.data);
  
    const tipoMesReajuste = (tipoMesReajusteId !== EnumTipoMesReajuste.UmaParcelaEntrada && tipoMesReajusteId !== EnumTipoMesReajuste.UmaParcelaFinanciamento) ? Number(ObterTipoMes(tipoMesReajusteId)) : 0;
    const tipoAnoInicioReajuste = Number(ObterTipoAno(tipoAnoInicioReajusteId));

    data.setMonth(data.getMonth() + tipoMesReajuste);
    data.setFullYear(data.getFullYear() + tipoAnoInicioReajuste);

    if (tipoMesReajusteId !== EnumTipoMesReajuste.UmaParcelaEntrada && tipoMesReajusteId !== EnumTipoMesReajuste.UmaParcelaFinanciamento) {
      this.item.mesReajuste = data.getMonth() + 1;
    }

    this.item.anoPrimeiroReajuste = data.getFullYear();
  } 

  ObterDadosReceita(qtdeParcela: number, dataInicialParcela: string, valorReceita: number, tipoReceitaId: number, valoresAdicionaisParcela:any = {}, tipoIntervaloParcelaId?:EnumTipoIntervaloParcelas) {
      if(tipoReceitaId == EnumTipoReceita.TituloEntrada){
        this.GerarParcelasEntrada(qtdeParcela, dataInicialParcela, valorReceita);

      }else if(tipoReceitaId == EnumTipoReceita.TituloIntermediaria){
        this.GerarParcelasIntermediarias(qtdeParcela, dataInicialParcela, valorReceita, tipoIntervaloParcelaId);

      }else if(tipoReceitaId == EnumTipoReceita.Titulo){
        if(this.item.tipoAmortizacaoId == TipoAmortizacao.Padrao){
          this.GerarParcelasFinanciamento(qtdeParcela, dataInicialParcela, valorReceita, tipoReceitaId, tipoIntervaloParcelaId);

        }else{
          this.receitaService.ParcelamentoPrice(valorReceita, this.item.jurosAmortizacao, qtdeParcela,valoresAdicionaisParcela.isDFIFixo, valoresAdicionaisParcela.percentualMPI, valoresAdicionaisParcela.percentualDFI,valoresAdicionaisParcela.gestao).then(
            (res) => {
              this.GerarParcelasSac(res.data, dataInicialParcela);
            },
            (err) => {
              TratarErroApi(err);
            }
          )
        }
      }

     this.CarregarTipoReceita(tipoReceitaId, valorReceita);
  }
  
  ObterReceita(receitaSelecionada){
    this.CarregarTipoReceita(receitaSelecionada.tipoId, receitaSelecionada.valor);
    this.receitaGerenciada = receitaSelecionada; 

   if(this.item.tipoAmortizacaoId == TipoAmortizacao.Price && receitaSelecionada.tipoId == EnumTipoReceita.Titulo){
      this.dialogGerencialPrice = true;
    } else {
      this.dialogGerencial = true;
    }
  } 

  CarregarTipoReceita(tipoId, valorReceita){
    switch (tipoId) {
      case EnumTipoReceita.TituloEntrada:
          this.ConfigurarReceita(this.receitaEntrada, valorReceita, "Entrada");
        break;

      case EnumTipoReceita.TituloIntermediaria:
          this.ConfigurarReceita(this.receitaIntermediaria, valorReceita, "Intermediaria");
        break;

      case EnumTipoReceita.Titulo:
          this.ConfigurarReceita(this.receitaSaldoDevedor, valorReceita, "Financiamento");
        break;
      }  
  } 
 
  ConfigurarReceita(receita, valorReceita, titulo) {
    receita.valor = valorReceita;
    receita.melhorDia = this.item.melhorDia;
    receita.dataInicialParcelas = this.item.primeiroVencimento;
    receita.empresaId = this.item.empresaId;
    receita.tipoAmortizacaoId = this.item.tipoAmortizacaoId;
    receita.jurosAmortizacao = this.item.jurosAmortizacao;
    receita.valoresAdicionaisParcela = this.valoresAdicionaisParcela;
    receita.tipoDocumentoId = this.listaTiposDocumentos[0].id;
    receita.gerarFinanciamentoComIntermediaria = this.planoPagamento.financiamento.gerarFinanciamentoComIntermediaria;
    receita.gerarFinanciamentoPosEntrada = this.planoPagamento.financiamento.gerarFinanciamentoPosEntrada;
    receita.titulo = titulo;
  };

  @Watch('receitaSaldoDevedor.parcelas', { deep: true })
  ObservarFinanciamento(parcelas: any[]){
    if (parcelas.length > 0 && this.tipoMesReajusteId == EnumTipoMesReajuste.UmaParcelaFinanciamento) {
      const primeiroItem = parcelas[0];
      this.item.mesReajuste = new Date(primeiroItem.dataVencimento).getMonth() + 1;
    }
  };

  @Watch('receitaEntrada.parcelas', {deep: true})
  ObservarReceitaEntrada(parcelas:any []){
    if(parcelas.length > 0 && this.tipoMesReajusteId == EnumTipoMesReajuste.UmaParcelaEntrada){
      const primeiroItem = parcelas[0];
      this.item.mesReajuste = new Date(primeiroItem.dataVencimento).getMonth() + 1;
    }
  };
 
  Validacoes(){
    if(!this.item.empresaId){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      this.e13 = 1;
      return true; 
    } 
    
    if (this.item.corretores.length == 0) {
      this.$swal("Alerta!", "É necessário incluir pelo menos 1 corretor neste contrato.", "warning");
      this.e13 = 1;
      return true;
    }

    if (this.item.clientes.length == 0) {
      this.$swal("Alerta!", "É necessário incluir pelo menos 1 cliente neste contrato.", "warning");
      this.e13 = 1;
      return true;
    }

    if(!this.item.tipoId ||  !this.item.empreendimentoId || !this.item.grupoId || !this.item.unidadePrincipalId){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      this.e13 = 2;
      return true; 
    }

    if(!this.item.data || !this.item.mesReajuste || !this.item.tipoIndiceId || !this.item.tipoAmortizacaoId || !this.item.intervaloReajusteId || !this.item.anoPrimeiroReajuste){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      this.e13 = 3;
      return true;
    }

    if(this.receitaSaldoDevedor.parcelas.length == 0){
      this.$swal("Aviso", "É necessário gerar parcelas de financiamento.", "warning");
      this.e13 = 4;
      return true;
    }

    if(!this.item.tipoOperacaoId){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      this.e13 = 4;
      return true;
    }
  }
  
  Reset(){ 
    this.receitaSaldoDevedor = new Receita(1);
    this.receitaEntrada = new Receita(3);
    this.receitaIntermediaria = new Receita(5);
    this.valoresAdicionaisParcela = new ValoresAdicionais();   
    this.planoPagamento = new PlanoPagamentoModelo();

    this.$refs.formCorretor.resetValidation();
    this.$refs.formCliente.resetValidation();
    this.tab = 0;
    this.e13 = 1;
    this.show = false;
    this.overlay = false;
  }

  ResetReceitas(){
    this.receitaSaldoDevedor.parcelas = [];
    this.receitaEntrada.parcelas = [];
    this.receitaIntermediaria.parcelas = [];
  }
 
  get anos() {
    var currentYear = new Date().getFullYear();
    var min = currentYear - 10;
    var max = currentYear + 5;

    const anos:any = []; 

    for (let i = min; i <= max; i++) {
      anos.push(i);
    }

    return anos; 
  }

  GerarParcelasEntrada(qtdeParcela, dataInicialParcela, valorReceita) {
   const mes = new Date(dataInicialParcela).getMonth();

   if (qtdeParcela > 0 && valorReceita > 0) {
      let quantidadeParcela = qtdeParcela;

      for (let i = 1; i <= quantidadeParcela; i++) {
         let parcela = new ReceitaParcela();
         let dataInicial = new Date(`${dataInicialParcela}T00:00:00`);
         const dia = ObterMelhorDia(dataInicial, this.item.melhorDia, i);

         const numeroParcela = i;
         const dataVencimento = CalcularDataParcela(dataInicial, undefined, numeroParcela, dia, mes).yyyymmdd();
         parcela.parcela = numeroParcela;
         parcela.dataVencimento = dataVencimento;
         parcela.dataVencimentoOriginal = parcela.dataVencimento;
         parcela.valor = Number((valorReceita / quantidadeParcela).toFixed(2));
         parcela.situacaoId = EnumSituacaoReceitaParcela.Aberto;
         parcela.valoresAdicionais.taxaBoleto = this.valoresAdicionaisParcela.taxaBoleto;

        this.receitaEntrada.parcelas.push(parcela);
      }
      this.item.melhorDia = 0;
   }
  }

  GerarParcelasIntermediarias(qtdeParcela, dataInicialParcela, valorReceita, tipoIntervaloParcelaId){
    const mes = new Date(dataInicialParcela).getMonth();

   if (qtdeParcela > 0 && valorReceita > 0) {
      let quantidadeParcela = qtdeParcela;

      for (let i = 1; i <= quantidadeParcela; i++) {
        let parcela = new ReceitaParcela();
        let dataInicial = new Date(`${dataInicialParcela}T00:00:00`);
        const dia = ObterMelhorDia(dataInicial, this.item.melhorDia, i);

        const numeroParcela = i;
        const dataVencimento = CalcularDataParcela(dataInicial, tipoIntervaloParcelaId, numeroParcela, dia, mes).yyyymmdd();
        parcela.parcela = numeroParcela;
        parcela.dataVencimento = dataVencimento;
        parcela.dataVencimentoOriginal = parcela.dataVencimento;
        parcela.valor = Number((valorReceita / quantidadeParcela).toFixed(2));
        parcela.situacaoId = EnumSituacaoReceitaParcela.Aberto;
        parcela.valoresAdicionais.taxaBoleto = this.valoresAdicionaisParcela.taxaBoleto;

        this.receitaIntermediaria.parcelas.push(parcela);
      }
      this.item.melhorDia = 0;
    }
  }

  GerarParcelasFinanciamento(qtdeParcela:number, dataInicialParcela:string, valorReceita:number, tipoReceitaId:number, tipoIntervaloParcelaId?:EnumTipoIntervaloParcelas){  
    let mes = new Date(dataInicialParcela).getMonth();

    if(qtdeParcela > 0 && valorReceita > 0){
      for (let i = 1; i <= qtdeParcela; i++) {

        let parcela = new ReceitaParcela();
        let dataInicial = new Date(Date.parse(`${dataInicialParcela} 00:00:00`));
        const dia = ObterMelhorDia(dataInicial, this.item.melhorDia, i);

        parcela.parcela = i;
        parcela.dataVencimento = CalcularDataParcela(dataInicial, tipoIntervaloParcelaId, i, dia, mes).yyyymmdd();       
        parcela.dataVencimentoOriginal = parcela.dataVencimento;
        parcela.valor = Number((valorReceita / qtdeParcela).toFixed(2));
        parcela.situacaoId = EnumSituacaoReceitaParcela.Aberto;    
        parcela.valoresAdicionais.taxaBoleto = this.valoresAdicionaisParcela.taxaBoleto;   
              
        if(tipoReceitaId == EnumTipoReceita.Titulo){
          const intermediariaParcelas = this.receitaIntermediaria.parcelas;
          let vencimentoCalculadoAux;

          if(this.receitaSaldoDevedor.parcelas.length > 0){
            vencimentoCalculadoAux = new Date(this.receitaSaldoDevedor.parcelas[this.receitaSaldoDevedor.parcelas.length -1].dataVencimento);
            vencimentoCalculadoAux = new Date((vencimentoCalculadoAux).setMonth(new Date(vencimentoCalculadoAux).getMonth() + 1));
          }
          else
          {
           vencimentoCalculadoAux = new Date(parcela.dataVencimento);
          }

        if(intermediariaParcelas && this.planoPagamento.financiamento.gerarFinanciamentoComIntermediaria == true){
          if(intermediariaParcelas.some(x=>new Date(x.dataVencimento).getMonth() == new Date(vencimentoCalculadoAux).getMonth() && new Date(x.dataVencimento).getFullYear() == new Date(vencimentoCalculadoAux).getFullYear()))
          {
              intermediariaParcelas.forEach(x => {
              if(new Date(vencimentoCalculadoAux).getMonth() ==  new Date(x.dataVencimento).getMonth() && new Date(vencimentoCalculadoAux).getFullYear() ==  new Date(x.dataVencimento).getFullYear()){
                vencimentoCalculadoAux = new Date((vencimentoCalculadoAux).setMonth(new Date(vencimentoCalculadoAux).getMonth() +1))
              }      
            })
          }
        }

          parcela.dataVencimento = new Date(vencimentoCalculadoAux).yyyymmdd();
          parcela.dataVencimentoOriginal = parcela.dataVencimento;
          this.receitaSaldoDevedor.parcelas.push(parcela);
        };
      }

      this.item.melhorDia = 0;
      qtdeParcela = 0;
    }
  }

  GerarParcelasSac(resultado, dataInicialParcela:string){
    for(let i = 0; i < resultado.length; i++){
      let parcela = new ReceitaParcela();

      let dataInicial = new Date(Date.parse(`${dataInicialParcela} 00:00:00`));
      const dia = ObterMelhorDia(dataInicial, this.item.melhorDia, i+1);
       
      parcela.parcela = resultado[i].parcela;
      parcela.valor = resultado[i].valorParcela.toFixed(2);
      parcela.saldoAmortizacaoPeriodoOriginal = resultado[i].saldoInicialPeriodo;
      parcela.saldoAmortizacaoInicioPeriodoCorrigido = resultado[i].saldoInicialPeriodo;
      parcela.saldoAmortizacaoFimPeriodoCorrigido = resultado[i].saldoFinalPeriodo;
      parcela.situacaoId = EnumSituacaoReceitaParcela.Aberto;
      parcela.dataVencimento = CalcularDataParcela(dataInicial, 0, i+1, dia, 0, ).yyyymmdd();
      parcela.dataVencimentoOriginal = parcela.dataVencimento;
      parcela.juros = resultado[i].juros;
      parcela.amortizacao = resultado[i].amortizacao;
      parcela.valoresAdicionais = resultado[i].valoresAdicionais;
      parcela.valoresAdicionais.taxaBoleto = this.valoresAdicionaisParcela.taxaBoleto;

      if(this.receitaSaldoDevedor.tipoId == EnumTipoReceita.Titulo){
        const intermediariaParcelas = this.receitaIntermediaria.parcelas;
        let vencimentoCalculadoAux;

        if(this.receitaSaldoDevedor.parcelas.length > 0){
          vencimentoCalculadoAux = new Date(this.receitaSaldoDevedor.parcelas[this.receitaSaldoDevedor.parcelas.length -1].dataVencimento);
          vencimentoCalculadoAux = new Date((vencimentoCalculadoAux).setMonth(new Date(vencimentoCalculadoAux).getMonth() + 1));
        }
        else
        {
          vencimentoCalculadoAux = new Date(parcela.dataVencimento);
        }

      if(intermediariaParcelas && this.planoPagamento.financiamento.gerarFinanciamentoComIntermediaria == true){
        if(intermediariaParcelas.some(x=>new Date(x.dataVencimento).getMonth() == new Date(vencimentoCalculadoAux).getMonth() && new Date(x.dataVencimento).getFullYear() == new Date(vencimentoCalculadoAux).getFullYear()))
        {
            intermediariaParcelas.forEach(x => {
            if(new Date(vencimentoCalculadoAux).getMonth() ==  new Date(x.dataVencimento).getMonth() && new Date(vencimentoCalculadoAux).getFullYear() ==  new Date(x.dataVencimento).getFullYear()){
              vencimentoCalculadoAux = new Date((vencimentoCalculadoAux).setMonth(new Date(vencimentoCalculadoAux).getMonth() +1))
            }      
          })
        }
      }

        parcela.dataVencimento = new Date(vencimentoCalculadoAux).yyyymmdd();
        parcela.dataVencimentoOriginal = parcela.dataVencimento;
        this.receitaSaldoDevedor.parcelas.push(parcela);
      };
    }
  }

  listaClientes = [];
  listaCorretores = [];

  AtualizarPessoa(){
    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,cnpjCpf, nomeCnpjCpf, isCliente, isCorretor', '').then(
      (res) => {
        this.listaClientes = res.data.items.filter((x:any)=> x.isCliente == true);
        this.listaCorretores = res.data.items.filter((x:any)=> x.isCorretor == true);
      })
  }

  ObterEncargosContaCorrente(){
    new ContaCorrenteService().ObterPorId(this.item.contaCorrenteId).then(
      res => {
       this.item.encargoFinanceiro = res.data.encargoFinanceiro;
      }
    )
  }
     
  mounted(){
    this.AtualizarPessoa();

    const tipoOperacaoContrato = new TipoOperacaoFinanceiraService();
    tipoOperacaoContrato.ListarTudo().then( 
      (res) => {
        this.tiposOperacaoContrato = res.data.items;
      }
    )

    const contaCorrenteService = new ContaCorrenteService();
    contaCorrenteService.ListarTudo().then(
      (res) => {
        this.listaContaCorrentes = res.data.items;
      }
    )

    new TipoIntervaloParcelasService().ListarTudo().then(
      res => {
        this.tiposIntervaloParcelas = res.data.items
      })

    new TipoDocumentoService().ListarTudo().then(
      (res) => {
        this.listaTiposDocumentos = res.data.items;
      })

    new IntervaloReajusteService().ListarTudo().then(
      (res) => {
        this.tiposIntervalos = res.data.items;
      })

    new TipoAmortizacaoService().ListarTudo().then(
      (res) => {
        this.tiposAmortizacao = res.data.items.filter(x => x.id != 3);
      })
  
    new TipoContratoService().ListarTudo().then(
      (res) => {
        this.tiposContratos = res.data.items;
      })
     
    new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,nomeFantasia', '').then(
      (res) => {
        this.listaEmpresas = res.data.items;
      })

    new TipoIndiceService().ListarTudo().then(
      (res) => {
        this.listaIndices = res.data.items;
      })
  } 
}
