
import { Contrato } from "@/core/models/cadastros";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
 
@Component
export default class EditarParcelaDespesa extends Vue {
  @Prop() public item!: Contrato;
  @Prop() public value!: string;

  valid = true;
  dialog = false;

  options = {
    itemsPerPage: 5
  };

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Situação", value: "situacaoId" },
    { text: "Data de Alteração", value: "dataAlteracao" },
    { text: "Responsável", value: "usuario.login" },
  ];

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 
  } 

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    } 
  }

  Close() {
    this.dialog = false;
  }  

  mounted(){

  }
}
