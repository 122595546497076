var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"persistent":"","width":"1200","no-click-animation":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.Close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Contrato Digital")])],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('Editor',{attrs:{"api-key":"y3gvhk7lpn1czxmsvxuw6iqnwoofoxpro2jmjevriklszz7g","init":{
          allow_script_urls: true,
          selector: 'textarea',
          isTyping: false,
          plugins: 'advlist autolink lists link image charmap preview',
          toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright | bullist numlist',
          /*plugins: 'advlist autolink charmap code codesample directionality emoticons fullscreen help hr image insertdatetime link lists media nonbreaking pagebreak paste preview print save searchreplace table template textpattern visualblocks visualchars wordcount',
          toolbar: 'fontselect fontsizeselect formatselect | bold italic underline strikethrough forecolor backcolor | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link table removeformat code',*/
          height: 800,
          menubar: true,
          language: 'pt_BR',
          image_advtab: true,
          branding: false,
      }},model:{value:(_vm.contratoDigital.conteudoEditavel),callback:function ($$v) {_vm.$set(_vm.contratoDigital, "conteudoEditavel", $$v)},expression:"contratoDigital.conteudoEditavel"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }