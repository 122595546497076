
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ReceitaService, TipoAntecipacaoService } from "@/core/services/financeiro";
import { Receita } from "@/core/models/financeiro";
import * as jsonpatch from 'fast-json-patch';

@Component
export default class Simular extends Vue {
  @Prop() public item!: Receita;
  @Prop() public value!: string;

  isQtdeParcela = false;
  qtdeParcela = 0;

  receitaService = new ReceitaService();
  observer! : jsonpatch.Observer<Receita>;

  simularAntecipacao = new SimularAntecipacao();
  simulacaoAmortizacao = new SimulacaoAmortizacao();

  saldoDevedor:number = 0;

  tiposAntecipacao = [];
  reduzirParcelas = false;
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
 
  ObterSimulacao(){
    this.simularAntecipacao.receitaId = this.item.id;
    if(this.reduzirParcelas == false){
      this.reduzirParcelas = !this.reduzirParcelas;
    }

    if(this.reduzirParcelas == true)
      this.receitaService.ObterSimulacaoAmortizacao(this.simularAntecipacao.receitaId, this.simularAntecipacao.valorAmortizado,this.simularAntecipacao.dataPagamento, this.simularAntecipacao.tipoAntecipacaoId).then(res =>{ 
      this.simulacaoAmortizacao = res.data;
    }, 
      (err) => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    })
  }

  GetQuitacao() {
    if ((this.simularAntecipacao.dataPagamento.length === 10 && parseInt(this.simularAntecipacao.dataPagamento.charAt(0)) > 0) && this.qtdeParcela > 0 && this.isQtdeParcela == true) {
      new ReceitaService().GetTotalSimulacaoQuitacao(this.item.id, this.qtdeParcela, this.simularAntecipacao.dataPagamento, 3).then(
        res => {
          this.simularAntecipacao.valorAmortizado = res.data;
          this.valid = true;
        },
          (err) => {  
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning");
          } else {
            this.$swal("Aviso",err.response.data,err.response.status == 400 ? "warning" : "error");
          }
        }
      )
    }else{
      this.simularAntecipacao.valorAmortizado = 0;
    }
  }

  ObterSaldoDevedor(){
    this.receitaService.ObterSaldoDevedor(this.item.id, this.simularAntecipacao.dataPagamento).then(res => {
      this.saldoDevedor = res.data;
    })
  }
  
  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }else{
      this.ObterSaldoDevedor();
    }
  }

  $refs!: { 
    form: HTMLFormElement;
  }; 

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    } 
  }

  Close() { 
    this.dialog = false;
    this.reduzirParcelas = false;
    this.isQtdeParcela = false;
    this.qtdeParcela = 0;
    this.simulacaoAmortizacao = new SimulacaoAmortizacao();
    this.simularAntecipacao = new SimularAntecipacao();
  }
 
  GerarParcela() {
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja Gerar uma parcela de Amortização?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm: true,
      preConfirm: () => {
         this.receitaService.CriarParcelaAmortizacao(this.simularAntecipacao.receitaId, this.simularAntecipacao.valorAmortizado,this.simularAntecipacao.dataPagamento, this.simularAntecipacao.tipoAntecipacaoId).then(res =>{ 
          this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        }, 
          (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        })
       return;
      }, 
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }); 
  }
  
  mounted(){
    new TipoAntecipacaoService().ListarTudo().then(
      res => {
        this.tiposAntecipacao = res.data.items;
      }
    )
  }
}

class SimularAntecipacao{
  receitaId:number = 0;
  valorAmortizado:number = 0;
  dataPagamento = new Date().yyyymmdd();
  tipoAntecipacaoId:number = 1;
}

class SimulacaoAmortizacao{
  quantidadeParcelasAmortizadas:number = 0;
  quantidadeParcelasAtual:number = 0;
  quantidadeParcelasNovo:number = 0;
  saldoAmortizacaoAtual:number = 0;
  saldoAmortizacaoNovo:number = 0;
  valorAmortizado:number = 0;
  valorParcelaAtual:number = 0;
  valorParcelaNovo:number = 0;
  valorReduzidoParcela:number = 0;
}
