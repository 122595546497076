import { http } from '@/core/api';
import { Service } from '../Service';

export default class ClasseComposicaoService extends Service {
    constructor(){
        super('classecomposicao');
    }

  
}
 