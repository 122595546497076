
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Empreendimento, LadoAdicional } from "@/core/models/cadastros";
import { EmpreendimentoService } from "@/core/services/cadastros";
import { TratarErroApi } from "@/assets/scripts/helper";

@Component
export default class LadosAdicionais extends Vue {
  @Prop() public item!: Empreendimento;
  @Prop() private value!: string;
  @Prop() private lista!: any;
  @Prop() public patch!: boolean;

  valid = true;
  dialog = false;
  dialogLado = false;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  editedIndex = -1;
  ladoItem = new LadoAdicional();

  get formTitle () {
    return this.editedIndex === -1 ? 'Novo Lado' : 'Editar Lado'
  }

  Adicionar(){
    if (this.editedIndex > -1) {
       Object.assign(this.lista[this.editedIndex], this.ladoItem);

      } else {
        this.lista.push(this.ladoItem);
      }

    this.ladoItem = new LadoAdicional();
    this.editedIndex = -1;
    this.dialogLado = false;
  }

  Fechar(){
    this.ladoItem = new LadoAdicional();
    this.dialogLado = false;
    this.editedIndex = -1;
  }
  
  EditarItem(item){
    this.editedIndex = this.lista.indexOf(item);
    this.ladoItem = Object.assign({}, item);
    this.dialogLado = true;
  }

  ExcluirItem(item:LadoAdicional){
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja excluir o registro atual?",
    icon: "question",
    showCancelButton: true, 
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm:true,
    preConfirm:() => {
      if(item.id > 0 && this.patch){
        item.excluido = true;
        const index = this.lista.indexOf(item);
        delete this.lista[index];
        this.dialog = false;
        this.dialog = true;
        return true;
      }else{
        if(item.id > 0){
          new EmpreendimentoService().DeletarLadoUnidade(item.id).then(
            (res) => {
             this.$emit("salvou"); 
           },
            (err) => TratarErroApi(err)
            )
          }
          const index = this.lista.indexOf(item);
          this.lista.splice(index, 1);
          return this.$swal("Aviso", "Operação realizada com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
      }).then((result) => {
      if(result.value){

      }
    })
  }

  headers = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Nome', value: 'nome',},
    { text: 'M', value: 'valor' },
  ]

  $refs!: {
    form: HTMLFormElement;
  };

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  Close() {
    this.dialog = false;
  }
}
