
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';
import { OrcamentoEtapa, OrcamentoObras} from "@/core/models/orcamentoObras";
import { ClasseComposicaoService, ComposicaoService, ModeloOrcamentoEtapaService, OrcamentoEtapaItemService, OrcamentoEtapaService, OrcamentoObrasService, OrigemDadosService, TipoComposicaoService } from "@/core/services/orcamentoObras";
import { EmpreendimentoService} from "@/core/services/cadastros";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { EnderecoService } from "@/core/services/geral/EnderecoService";
import { UnidadeMedidaService } from "@/core/services/almoxarifado";
import OrcamentoEtapaItem from "@/core/models/orcamentoObras/OrcamentoEtapaItem";

@Component
export default class CadastroOrcamento extends Vue {
  @Prop() public item!: OrcamentoObras;
  @Prop() public value!: string; 


  active: any[] = [];
  open: any[] = [];
  tree = [];
  panels= []

  isExpanded(item) {
    return this.open.includes(item.id);
  }
 
  expandEtapa(item) {
    const index = this.open.indexOf(item.id);
    if (index === -1) {
      // Se o item não estiver expandido, adiciona o id na lista
      this.open.push(item.id);
    } else {
      // Caso contrário, remove o id da lista
      this.open.splice(index, 1);
    }
  }

  modeloetapaId:number = 0;

  itemOrcamento = new OrcamentoObras();
  orcamentoEtapa = new OrcamentoEtapa();

  service = new OrcamentoObrasService();
  orcamentoEtapaService = new OrcamentoEtapaService();
  orcamentoEtapaItemService = new OrcamentoEtapaItemService();
  composicaoService = new ComposicaoService();

  expandedItems:any = []; 

  listaEmpresas = [];
  listaEmpreendimentos = [];
  listaOrigemDados = [];
  listaEstados = [];
  expanded:any = [];
  listaClassesComposicao = [];
  unidadeMedidas = [];
  tiposComposicao = [];
  listaDatas = [];

  composicoesAdicionadas:any = [];
  composicoesPesquisada = [];
  listaModelosEtapas = [];

  etapas:any = [];
  titulo:string ="";
  tab:number = 0;
  tabComposicao:number = 0;
   
  dialog:boolean = false;
  dialogEtapa:boolean = false;
  dialogComposicao:boolean = false;
  errorAlert:boolean = false;
  valid:boolean = true;
  loading: boolean = true;
  validOrcamentoEtapa:boolean = true;
   
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
    formOrcamentoEtapa: HTMLFormElement;
  }; 

  optionsComposicoes: any = {
    itemsPerPage: 15
  };

  filterComposicao = {origemId: 0, classeId:0, tipoId:0, unidadeId:0, descricao:'' };

  headersComposicoes = [
    { text: '', value: 'data-table-expand', sortable: false },
    { text: 'Base',value: 'composicao.origem.nome', sortable: false},
    { text: 'Cód.', value: 'composicao.codigo', sortable: false },
    { text: 'Descrição', value: 'composicao.descricao', sortable: false },
    { text: 'Unidade', value: 'composicao.unidade.nome', sortable: false },
    { text: 'Total Valor Desonerado', value: 'valorDesonerado', sortable: false },
    { text: 'Total Valor Não Desonerado', value: 'valorNaoDesonerado', sortable: false },
    { text: 'Classe', value: 'composicao.classe.nome', sortable: false },
    { text: 'Tipo', value: 'composicao.tipo.nome', sortable: false },
    { text: 'Ações', value: 'actions', sortable: false },
  ];  

  headersComposicoesEtapaItens = [
    { text: 'Base',value: 'composicao.origem.nome', sortable: false},
    { text: 'Cód.', value: 'composicao.codigo', sortable: false },
    { text: 'Descrição', value: 'composicao.descricao', sortable: false },
    { text: 'Unidade', value: 'composicao.unidade.nome', sortable: false },
    { text: 'Quantidade', value: 'composicao.quantidade', sortable: false },
    { text: 'Total Valor Desonerado', value: 'valorDesonerado', sortable: false },
    { text: 'Total Valor Não Desonerado', value: 'valorNaoDesonerado', sortable: false },
    { text: 'Classe', value: 'composicao.classe.sigla', sortable: false },
    { text: 'Tipo', value: 'composicao.tipo.sigla', sortable: false },
    { text: 'Ações', value: 'actions', sortable: false },
  ]

  @Watch("itemOrcamento")
  ItemOrcamento() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation(); 
    } 
  }

  @Watch("orcamentoEtapa")
  OrcamentoEtapa() {
    if (this.$refs.formOrcamentoEtapa) {
      this.$refs.formOrcamentoEtapa.resetValidation(); 
    } 
  }

  observer!: jsonpatch.Observer<OrcamentoObras>; 
 
  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
  }
 
  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }else{
      this.itemOrcamento = this.item;
    }
  } 

  ValidarCampos(){
    if(!this.itemOrcamento.descricao || !this.itemOrcamento.empresaId || !this.itemOrcamento.empreendimentoId){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      this.tab = 0;
      return true;
    }

     if(!this.itemOrcamento.referencia || !this.itemOrcamento.estadoId){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      this.tab = 1;
      return true;
    }
  }
  
  Salvar() {
    this.$refs.form.validate();

    if (!this.ValidarCampos()) {
      this.observer = jsonpatch.observe(this.itemOrcamento);
      let pacthModel = jsonpatch.generate(this.observer);
      
        (this.itemOrcamento.id > 0 ? this.service.Patch(pacthModel, this.itemOrcamento.id) : this.service.Salvar(this.itemOrcamento)).then(
          (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.Atualizar(res.data.id);
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          } 
        }
      )
    }
  }

  Close() {
    this.dialog = false;
    this.tab = 0;
    this.modeloetapaId = 0;
  }

  CloseDialogComposicao(){
    this.orcamentoEtapa = new OrcamentoEtapa();
    this.dialogComposicao = false;
    this.composicoesPesquisada = [];
  }

  AtualizarEstado(){
    if(this.itemOrcamento.id > 0){
      this.observer = jsonpatch.observe(this.itemOrcamento);
      let pacthModel = jsonpatch.generate(this.observer);
        
      this.service.Patch(pacthModel, this.itemOrcamento.id).then(
        (res) => {
          this.Atualizar(res.data.id);
        })
    }
  } 

  totalLista:number = 0;

  @Watch("optionsComposicoes", { deep: true })
  async PesquisarComposicao(){
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.optionsComposicoes;
    this.loading = true;

    await this.composicaoService.ListarComposicoesValoresAtualizados(this.filterComposicao, this.item.estadoId, this.item.referencia,page, itemsPerPage, sortBy, sortDesc,search, columns, this.filterComposicao, 'Origem, Unidade, Itens.Insumo.Tipo, Itens.Insumo.Unidade, Itens.Insumo.Origem, Tipo, Classe').then(
      (res) => {
        this.composicoesPesquisada = res.data.items;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        } 
      }
    ).finally(() => this.loading = false); 
  }

  OpenDialogComposicao(item){
    this.orcamentoEtapa = item;
    this.dialogComposicao = true;
  }

  PrepararComposicoes(){
    if(this.composicoesAdicionadas.length == 0){
      this.$swal("Aviso","É obrigatório adicionar os insumos","warning");
      return;
    };

      this.composicoesAdicionadas.forEach(item => {
      const itemExistente = this.orcamentoEtapa.itens.find(etapa => etapa.composicaoId === item.composicao.id);
    
      if(itemExistente){
       // console.log(`O insumo com ID ${item.composicao} já foi adicionado.`);
      }else{
        let etapaItem = new OrcamentoEtapaItem();
          etapaItem.etapaId = this.orcamentoEtapa.id;
          etapaItem.composicaoId = item.composicao.id;
          etapaItem.composicao = item.composicao;
          etapaItem.quantidade = item.composicao.quantidade;
          this.orcamentoEtapa.itens.push(etapaItem);
        } 
    });

    this.orcamentoEtapa = new OrcamentoEtapa();
    this.composicoesAdicionadas = [];
    this.dialogComposicao = false;
    this.Salvar();     
    //return this.$swal("Aviso","Item adicionado com sucesso!","success");
  }

  AdicionarComposicao(item){
      
    const composicaoExistente = this.composicoesAdicionadas.find(x => x.composicao.id === item.composicao.id);
    if(composicaoExistente){
      this.$swal("Aviso","Esse item já foi adicionado na lista!","warning");
      return;
    };

    this.composicoesAdicionadas.push(item);
    this.errorAlert = true;
  }

  ExcluirComposicaoPesquisado(item){
    let indiceItem = this.composicoesAdicionadas.indexOf(item);
    this.composicoesAdicionadas.splice(indiceItem, 1);
  }

  editedIndex = -1;
  

  AdicionarEtapa(item){
    if (this.$refs.formOrcamentoEtapa.validate()) {

    if(this.modeloetapaId > 0)
      {
        let modelo = this.itemOrcamento;
        this.AplicarModeloOrcamento(this.modeloetapaId, item.etapaPaiId, modelo);
      }
      else if(item.etapaPaiId > 0 && this.editedIndex == EnumEtapa.novaSubEtapa)
      {
        this.itemOrcamento.etapas.push(item);
         this.Salvar();
         this.orcamentoEtapa = new OrcamentoEtapa();
       }
      else if(this.editedIndex == EnumEtapa.novaEtapa)
       {
          let orcamentoEtapa = new OrcamentoEtapa();
          orcamentoEtapa.id = 0;
          orcamentoEtapa.etapaPaiId = null;
          orcamentoEtapa.titulo = this.orcamentoEtapa.titulo;
          orcamentoEtapa.descricao = this.orcamentoEtapa.descricao;
          orcamentoEtapa.quantidade = this.orcamentoEtapa.quantidade;
          this.itemOrcamento.etapas.push(orcamentoEtapa);
          this.Salvar();
          this.orcamentoEtapa = new OrcamentoEtapa();
      }
    } 
  }

  EditarEtapa(item){
    this.orcamentoEtapaService.Salvar(item).then(
        (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.Atualizar(res.data.id);
      },
    )
  }

  AplicarModeloOrcamento(modeloetapaId:number, etapaPaiId?:any, modelo?:any){
    let paId = etapaPaiId ? etapaPaiId : ''
 
    new ModeloOrcamentoEtapaService().AplicarModeloOrcamento(modeloetapaId, paId, modelo).then(
      res => {
        this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.Atualizar(res.data);
      },
      (err) => {
        if(this.itemOrcamento.id == 0)
          this.Close();

          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          } 
        }
    )
  }

  ExcluirOrcamentoEtapa(item){
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja excluir o registro atual?",
    icon: "question",
    showCancelButton: true, 
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm:true,
    preConfirm:() => {
      if(item.id){
        this.orcamentoEtapaService.Excluir(item.id).then(
          (res) => {
            this.$swal("Aviso", res.data, "success");
            this.Atualizar(this.itemOrcamento.id);
          },
          (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            } 
          }
        )
       }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
      }).then((result) => {
      if(result.value){

      }
    })
  }

  SalvarEtapaItem(item){
    this.orcamentoEtapaItemService.Salvar(item).then(
      (res) => {
      this.Atualizar(res.data.id);
    })
  }

  ExcluirEtapaItem(item){
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja excluir o registro atual?",
    icon: "question",
    showCancelButton: true, 
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm:true,
    preConfirm:() => {
      if(item.id){
        this.orcamentoEtapaItemService.Excluir(item.id).then(
          (res) => {
            this.$swal("Aviso", res.data, "success");
            this.Atualizar(this.orcamentoEtapa.id);
          },
          (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            } 
          }
        )
       }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
      }).then((result) => {
      if(result.value){

      }
    })
  }

  Atualizar(id) {
    if (this.observer) {
      jsonpatch.unobserve(this.itemOrcamento, this.observer);
    }

    this.service.ObterPorId(id ?? this.itemOrcamento.id, 'Estado, Etapas.Itens.Composicao,Etapas.Itens.Composicao.Tipo,Etapas.Itens.Composicao.Classe, Etapas.Itens.Composicao.Unidade, Etapas.Itens.Composicao.Itens.Insumo ').then(
      (res) => {
        this.itemOrcamento = res.data;
        this.observer = jsonpatch.observe(this.itemOrcamento);
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error"); 
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        } 
      }
    );
  }

  SalvarModelo(item){
    console.log(item)
    this.$swal({
    title: "Atenção!",
    text: "Deseja Salvar como Modelo de etapa?",
    icon: "question",
    showCancelButton: true, 
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm:true,
    preConfirm:() => {
      if(item.id){
        new ModeloOrcamentoEtapaService().SalvarModeloEtapa(item.id, this.item.estadoId).then(
          (res) => {
            this.$swal("Aviso", res.data, "success");
            this.Atualizar(this.itemOrcamento.id);
          },
          (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            } 
          }
        )
       }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
      }).then((result) => {
      if(result.value){

      }
    })
  }

  @Watch('itemOrcamento.etapas')
  ObterEtapasChildren(etapas) {
    if (etapas == null) return;

    const mapaEtapas = new Map();
   
   etapas.forEach((etapa) => 
    {
        const { id, etapaPaiId } = etapa;

        if (!mapaEtapas.has(id)) 
        {
          mapaEtapas.set(id, { ...etapa, children: [] });
        }

        if (etapaPaiId !== null) 
        {
          if (!mapaEtapas.has(etapaPaiId)) {
            mapaEtapas.set(etapaPaiId, { children: [] });
          }
          mapaEtapas.get(etapaPaiId).children.push(mapaEtapas.get(id));
        }
      }
    );

    // Aplicar cálculo após a construção completa do mapa
    mapaEtapas.forEach((etapa) => {
      this.calcularValorTotalDesonerado(etapa);
    });

  
    // valortotalitemDesonerado * o (bdi do orcamento / 100)
    this.etapas = Array.from(mapaEtapas.values()).filter((item) => item.etapaPaiId === null || item.etapaPaiId == undefined);
  }

  calcularValorTotalDesonerado(etapa) {
    if (!etapa.itens) {
      etapa.itens = [];
    }

    const valorDesoneradoDireto = etapa.itens.reduce((total, item) => total + Number(item.valorTotalItemDesonerado), 0) * etapa.quantidade;
    const valorDesoneradoFilhos = etapa.children.reduce((total, child) => total + this.calcularValorTotalDesonerado(child), 0);
    etapa.valorTotalDesonerado = valorDesoneradoDireto + valorDesoneradoFilhos;
     
    return etapa.valorTotalDesonerado;
  }
  

  @Watch("dialog")
  ObterEmpreendimentos(){
    let filter = {empresaId:this.itemOrcamento.empresaId};
    
    if(this.dialog){
      const empreendimentoService =  new EmpreendimentoService();
      empreendimentoService.Listar(-1, -1, ['nome'],[],'',[], filter, '' , 'Id, nome, empresaId', '').then(
        (res) => {
          this.listaEmpreendimentos = res.data.items;
        }
      )
    }
  }

  get allExpanded() {
    return this.expanded.length === this.etapas.length;
  }

  get validacaoOrcamento() {
    return !this.itemOrcamento.descricao || !this.itemOrcamento.empresaId || !this.itemOrcamento.empreendimentoId || !this.itemOrcamento.origemId || !this.itemOrcamento.referencia || !this.itemOrcamento.estadoId;
  }
 
  get isOrcamentoBloqueado() {
    return this.validacaoOrcamento;
  }

  DialogOrcamentoEtapa(item){
    if(item){
      this.orcamentoEtapa = new OrcamentoEtapa();
      this.orcamentoEtapa.etapaPaiId = item.id;   
      this.dialogEtapa = true;
    }else{
      this.orcamentoEtapa = new OrcamentoEtapa();
      this.dialogEtapa = true;
    }
  }

  mounted(){
    new ModeloOrcamentoEtapaService().ListarTudo().then(
      res => {
        this.listaModelosEtapas = res.data.items.filter(x => x.modeloEtapaPaiId == null);
       }
    )
 
    const datasService = new ComposicaoService();
    datasService.ObterDatas().then(
      (res) => {
        this.listaDatas = res.data;
      }
    )

    const estadoService = new EnderecoService();
    estadoService.Listar(1,-1, ['nome'], [],undefined, [], '', '', '', '').then(
      (res) => {
        this.listaEstados = res.data.items
      } 
    )  

    const empresaService =  new EmpresaService();
    empresaService.Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,nomeFantasia', '').then(
      (res) => {
        this.listaEmpresas = res.data.items;
      }
    )

    const origemService =  new OrigemDadosService();
    origemService.Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id ,nome', '').then(
      (res) => {
        this.listaOrigemDados = res.data.items;
      }
    )

    const unidadeMedidaService = new UnidadeMedidaService();
    unidadeMedidaService.ListarTudo().then(
      (res) => {
        this.unidadeMedidas = res.data.items;
      } 
    )

    const tipoComposicaoService = new TipoComposicaoService();
    tipoComposicaoService.ListarTudo().then(
      (res) => { 
        this.tiposComposicao = res.data.items;
      } 
    )

    const classeComposicaoService = new ClasseComposicaoService();
    classeComposicaoService.ListarTudo().then(
      (res) => {
        this.listaClassesComposicao = res.data.items;
      } 
    )
  }
}

enum EnumEtapa {
  novaEtapa = 1,
  novaSubEtapa = 2,
}
