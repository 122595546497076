
import { Documento } from '@/core/models/geral'
import { UsuarioService } from '@/core/services/cadastros';
import { ArquivoService } from '@/core/services/geral/ArquivoService';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class VCardDocumento extends Vue {
  @Prop() private lista?: any[];
  @Prop() private exibirCampos;

  documento: Documento = new Documento();
  inputDocumento: any = null;
  dialog = false;
  usuarios:any = [];

  headersDocumentos: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Nome", value: "nome", align: "center" },
    { text: "Arquivo", value: "dados", align: "center", sortable: false },
    { text: "Data", value: "dataEmissao", align: "center", sortable: false },
    { text: "Usuário", value: "usuarioId", align: "center", sortable: false },
  ];

  async AdicionarArquivo() {
    if (!this.inputDocumento)
      return this.$swal("Aviso", "É necessário selecionar um arquivo.", "warning");

    if (this.documento.nome == '')
      return this.$swal("Aviso", "É necessário nomear o arquivo.", "warning");

    if (this.lista!.some(s => s.nome == this.documento.nome))
      return this.$swal("Aviso", "Já existe um arquivo com esse nome na lista.", "warning");


    let dados = await new ArquivoService().Ler(this.inputDocumento);
    this.documento.usuarioId = JSON.parse(localStorage.sessionApp.split(".")).dados.usuarioId;
    this.documento.dados = dados.replace(/^[^,]*,/, "");
    this.documento.nome = this.documento.nome != "" ? this.documento.nome : this.inputDocumento.name;
    this.documento.tamanho = this.inputDocumento.size;
    this.documento.tipo = this.inputDocumento.type;
    this.lista!.push(this.documento);
    this.documento = new Documento();
    this.inputDocumento = null;
  }

  ExcluirDocumento(documento: any) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        if (documento.id > 0) {
          documento.excluido = true;
          const index = this.lista!.indexOf(documento);
          delete this.lista![index]
          this.dialog = false;
          this.dialog = true;
          return true;
        } else {
          const index = this.lista!.indexOf(documento);
          this.lista!.splice(index, 1);
          return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if (result.value) {
      }
    })
  }

  BaixarArquivo(arquivo) {
    new ArquivoService().Download(arquivo);
  }

  async CompartilharArquivo(item) {
    new ArquivoService().Compartilhar(item);
  }

  @Watch("lista")
  AtualizarLista() {
    this.documento = new Documento();
    this.inputDocumento = null;
  }

  mounted() {
    new UsuarioService().ListarTudo().then(
      res => this.usuarios = res.data.items
    )
  }
}
