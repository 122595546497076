
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ReceitaService } from "@/core/services/financeiro";
import { Receita, ReceitaParcela, TipoAmortizacao } from "@/core/models/financeiro";
import { TratarErroApi } from "@/assets/scripts/helper";

@Component
export default class valoresAdicionais extends Vue {
  @Prop() public item!: Receita;
  @Prop() public valoresParcela!: ReceitaParcela;
  @Prop() public value!: string;

  receitaService = new ReceitaService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  aplicar = false;

  $refs!: {
    form: HTMLFormElement;
  };

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  Close() {
    this.dialog = false;
    this.aplicar = false;
  }
 
  parcelas: any = [];

  AtualizarValores(resultado){
    /* aplica para todas as parcelas subsequentes */
    if(this.aplicar){
      for(let i = 0; i < this.item.parcelas.length; i++){
        if(this.item.parcelas[i].parcela >= this.valoresParcela.parcela){
          this.item.parcelas[i].valor = resultado[i].valorParcela;
          this.item.parcelas[i].juros = resultado[i].juros;
          this.item.parcelas[i].amortizacao = resultado[i].amortizacao;
          this.item.parcelas[i].saldoAmortizacaoPeriodoOriginal = resultado[i].saldoInicialPeriodo;
          this.item.parcelas[i].saldoAmortizacaoInicioPeriodoCorrigido = resultado[i].saldoInicialPeriodo;
          this.item.parcelas[i].saldoAmortizacaoFimPeriodoCorrigido = resultado[i].saldoFinalPeriodo;

          this.item.parcelas[i].valoresAdicionais = resultado[i].valoresAdicionais;
        }
      } 
    }else{
      /* Aplica somente a parcela selecionada */
      for(let i = 0; i < this.item.parcelas.length; i++){
        if(this.item.parcelas[i].parcela == this.valoresParcela.parcela){
          this.item.parcelas[i].valor = resultado[i].valorParcela;
          this.item.parcelas[i].juros = resultado[i].juros;
          this.item.parcelas[i].amortizacao = resultado[i].amortizacao;
          this.item.parcelas[i].saldoAmortizacaoPeriodoOriginal = resultado[i].saldoInicialPeriodo;
          this.item.parcelas[i].saldoAmortizacaoInicioPeriodoCorrigido = resultado[i].saldoInicialPeriodo;
          this.item.parcelas[i].saldoAmortizacaoFimPeriodoCorrigido = resultado[i].saldoFinalPeriodo;
          this.item.parcelas[i].valoresAdicionais = resultado[i].valoresAdicionais;
        }
      }
    }
  }

  ObterValoresPriceSac() {
    let resultado = [];

    if(this.item.tipoAmortizacaoId == TipoAmortizacao.Price){
      this.receitaService.ParcelamentoPrice(this.item.valor, this.item.jurosAmortizacao,this.item.parcelas.length,this.item.valoresAdicionaisParcela.isDFIFixo,this.valoresParcela.valoresAdicionais.percentualMPI,this.valoresParcela.valoresAdicionais.percentualDFI,this.valoresParcela.valoresAdicionais.gestao).then(
        res => {
          resultado = res.data;
          this.AtualizarValores(resultado);
        },
        (err) => TratarErroApi(err)
      ).finally(() => this.Close())
    }else{
      this.receitaService.ParcelamentoSac(this.item.valor,this.item.jurosAmortizacao, this.item.parcelas.length, this.item.valoresAdicionaisParcela.isDFIFixo, this.valoresParcela.valoresAdicionais.percentualMPI,this.valoresParcela.valoresAdicionais.percentualDFI,this.valoresParcela.valoresAdicionais.gestao).then(
        res => {
          resultado = res.data;
          this.AtualizarValores(resultado);
        },
      (err) => TratarErroApi(err)
      ).finally(() => this.Close())  
    }  
  }
}

