
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ContratoFornecedorService } from '@/core/services/projeto';
import * as jsonpatch from "fast-json-patch";
import {ContratoFornecedor} from "@/core/models/projeto";

@Component
export default class Detalhar extends Vue {
  @Prop() public item!: ContratoFornecedor;
  @Prop() public value!: string;

  service = new ContratoFornecedorService();
   
  tabActive = null;
  tab = null;
  valid = true;
  dialog = false;

  headersEtapas = [
    { text: "Etapa",  value: "etapa.nome", align: "center"},
    { text: "Unidades", value: "quantidade", align: "center" },
    { text: "Valor por Casa", value: "valorUnitario", align: "center" },
    { text: "Valor Total", value: "valorTotal", align: "center" },
    { text: "Caução", value: "caucao", align: "center"},
    { text: "Início", value: "dataInicio", align: "center" },
    { text: "Fim", value: "dataFim", align: "center" },
  ];

  $refs!: {
    form: HTMLFormElement;
  };

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  observer!: jsonpatch.Observer<ContratoFornecedor>;

 @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog) {
      this.observer = jsonpatch.observe(this.item);
    } else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }
 
  Salvar() {
    if (this.$refs.form.validate()) {
      let modelo = this.item;

      let pacthModel = jsonpatch.generate(this.observer);
      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id): this.service.Salvar(modelo)).then(
        (res) => {
          this.$swal("Aviso", "Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
         (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        });
      }
  }

  Close() {
    this.dialog = false;
  }  
}
