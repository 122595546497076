
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BancoService, ReceitaService} from "@/core/services/financeiro";

@Component
export default class CadastroRetornoBancario extends Vue {
  @Prop() public value!: string;
  
  service = new ReceitaService();
  codigoBancario:number = 0;

  loading:boolean = false;
  valid:boolean = true;
  dialog:boolean = false;
  arquivoRetorno:any = null;

  options: any = {
    itemsPerPage: 50,
  };

  totalLista:number = 0;

  listaBancos = [];
  listaRetorno = [];

  selected = [];

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    formRetornoBancario: HTMLFormElement;
  };
 
  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: "Documento",value: "NumeroDocumento"},
    { text: "Doador", value: "Pagador.nome" },
    { text: "Vencimento", value: "DataVencimento" },
    { text: "Pagamento", value: "DataCredito" },
    { text: "Valor Título", value: "ValorTitulo" },
    { text: "Juros ao dia", value: "ValorJurosDia" },
    { text: "Desconto", value: "ValorDesconto" },
    { text: "Valor Pago", value: "ValorPago" },
    { text: "Ocorrência", value: "codigoDescricaoOcorrencia" },
    { text: "Situação", value: "ParcelaInformativo"}
  ];

  @Watch("codigoBancario")
  Item() {
    if (this.$refs.formRetornoBancario)
      this.$refs.formRetornoBancario.resetValidation();
  };

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  };

  Processar(){
    if (this.$refs.formRetornoBancario.validate()) {
      let arquivo = this.arquivoRetorno;
      this.loading = true;

      this.service.ProcessarRetorno(this.codigoBancario, arquivo).then(
        (res) => {
          this.listaRetorno = res.data;
          this.totalLista = res.data.count;
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        }
      ).finally(() => this.loading = false);
    }
  };

  RemoveArquivo(){
    this.arquivoRetorno = null;
  };

  Baixar(){
    if (this.$refs.formRetornoBancario.validate()) {
      if(this.selected.length == 0){
        this.$swal( "Aviso","É nessário selecionar a parcela que deseja baixar","warning");
        return;
      }
      
      let parcelas = this.selected;
      this.service.BaixarParcelas(parcelas).then(
        (res) => {
          this.$swal( "Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.Close();
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        }
      );
    }
  };

  Close() {
    this.dialog = false;
    this.selected = [];
    this.listaRetorno = [];
    this.RemoveArquivo();
  };

  mounted(){
   const bancoService = new BancoService();
    bancoService.ListarTudo().then(
      (res) => {
        this.listaBancos = res.data.items;
      }
    )
  }
}
