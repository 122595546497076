
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';
import { Composicao, ComposicaoItem } from "@/core/models/orcamentoObras";
import { ComposicaoService, InsumoService, OrigemDadosService } from "@/core/services/orcamentoObras";
import { UnidadeMedidaService } from "@/core/services/almoxarifado";
import { EnderecoService } from "@/core/services/geral/EnderecoService";
import ClasseComposicaoService from "@/core/services/orcamentoObras/ClasseComposicaoService";
import TipoComposicaoService from "@/core/services/orcamentoObras/TipoComposicaoService";

@Component
export default class CadastroComposicao extends Vue {
  @Prop() public item!: Composicao;
  @Prop() public value!: string;

  insumoService = new InsumoService();
  service = new ComposicaoService();
  valid:boolean = true;

  dialog:boolean = false;
  dialogInsumo:boolean = false;
  dialogComposicao:boolean = false; 
  errorAlert:boolean = false;   

  filterComposicao = {origemId: 0, classeId:0, tipoId:0, unidadeId:0};
  filterInsumo = {origemId:0, estadoId:0, unidadeId:0, codigo: "", descricao: "", mes: 0, ano: 0, comValores: true};

  listaOrigemDados = [];
  listaEstados = [];
  unidadeMedidas = [];
  tiposComposicao = [];
  listaClassesComposicao = [];
  listaMeses = [
    { id:1, nome:"Janeiro" },
    { id:2, nome:"Fevereiro" },
    { id:3, nome:"Março" },
    { id:4, nome:"Abril" },
    { id:5, nome:"Maio" },
    { id:6, nome:"Junho" },
    { id:7, nome:"Julho" },
    { id:8, nome:"Agosto" },
    { id:9, nome:"Setembro" },
    { id:10, nome:"Outubro" },
    { id:11, nome:"Novembro" },
    { id:12, nome:"Dezembro" }
  ];

  //listaMeses = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  composicoesAdicionadas:any = [];
  composicoesPesquisada = [];
   
  insumosAdicionados:any = [];
  dessertsInsumos = [];
  expanded = [];

  optionsComposicoes: any = {
    itemsPerPage: 15
  };

  tab = null;
  tabInsumos = null;
  tabComposicao = null;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
  }; 

  options: any = {
    itemsPerPage: 15
  }; 
  
  itemsPerPage = 5; 

  headers = [
    { text: '', value: 'icon', sortable: false },
    { text: 'Código', value: 'insumo.codigo' },
    { text: 'Descrição', value: 'insumo.descricao' },
    { text: 'Un', value: 'unidade.nome' },
    { text: 'Coef.', value: 'coeficiente' },
    { text: '', value: 'actions', sortable: false },
  ];

  headersComposicoes = [
    { text: '', value: 'data-table-expand' },
    { text: 'Base de dados',value: 'origem.nome'},
    { text: 'Código', value: 'codigo' },
    { text: 'Descrição', value: 'descricao' },
    { text: 'Unidade', value: 'unidade.nome' },
    { text: 'Total Coeficiente', value: 'coeficiente' },
    { text: 'Classe', value: 'classe.nome' },
    { text: 'Tipo', value: 'tipo.nome' },
    { text: 'Ações', value: 'actions', sortable: false },
  ];  

  headersInsumos = [
    { text: 'Base de dados',value: 'origemNome'},
    { text: 'Código', value: 'codigo' },
    { text: 'Descrição', value: 'descricao' },
    { text: 'Unidade', value: 'unidadeNome' },
    { text: 'Unit. desonerado', value: 'valorDesonerado' },
    { text: 'Unit. não desonerado', value: 'valorNaoDesonerado' },
    { text: 'Ações', value: 'actions', sortable: false },
  ];  

  headersInsumosAdicionados = [
    { text: 'Base de dados',value: 'origemNome'},
    { text: 'Código', value: 'codigo' },
    { text: 'Descrição', value: 'descricao' },
    { text: 'Unidade', value: 'unidadeNome' },
    { text: 'Coeficiente', value: 'coeficiente' },
    { text: 'Unit. desonerado', value: 'valorDesonerado' },
    { text: 'Unit. não desonerado', value: 'valorNaoDesonerado' },
    { text: 'Ações', value: 'actions', sortable: false },
  ]

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  } 

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation(); 
    } 
  }

  observer! : jsonpatch.Observer<Composicao>; 

  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }
  
  Salvar() {
    if (this.$refs.form.validate()) {
      let pacthModel = jsonpatch.generate(this.observer);
    
      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close(); 
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          } 
        }
      )
    }
  }

  Close() {
    this.dialog = false;
  }

  PesquisarComposicao() {
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.optionsComposicoes; 

    this.service.Listar(page, itemsPerPage, sortBy, sortDesc,search, columns, this.filterComposicao, '', '', 'Origem, Tipo, Unidade, Classe, Itens.Insumo, Itens.Insumo.Tipo, Itens.Insumo.Unidade, Itens.Insumo.Origem').then(
      (res) => {
        this.composicoesPesquisada = res.data.items;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        } 
      }
    );  
  }

  AdicionarComposicao(item){
    const composicaoExistente = this.composicoesAdicionadas.find(x => x.id === item.id);

    if(composicaoExistente){
      this.$swal("Aviso","Esse item já foi adicionado na lista!","warning");
      return;
    };

    this.composicoesAdicionadas.push(item);
    this.errorAlert = true;
  }

  ExcluirComposicaoPesquisado(item){
    let indiceItem = this.composicoesAdicionadas.indexOf(item);
    this.composicoesAdicionadas.splice(indiceItem, 1);
  }

  PrepararComposicoes(){
    if(this.composicoesAdicionadas.length == 0){
      this.$swal("Aviso","É obrigatório adicionar os insumos","warning");
      return;
    };

      this.composicoesAdicionadas.forEach(composicao => {
      const itemExistente = this.item.itens.find(item => (item.composicaoAuxiliarId || item.id) === composicao.id);

        if(itemExistente){
          console.log(`O insumo com ID ${composicao.id} já foi adicionado.`);
        }else{
          let composicaoitem = new ComposicaoItem();
            composicaoitem.composicaoAuxiliar = composicao;
            composicaoitem.composicaoAuxiliarId = composicao.id;
            composicaoitem.coeficiente = composicao.itens.reduce((total, item) => total + item.coeficiente, 0);
           
           this.item.itens.push(composicaoitem);
         }
      });

    this.composicoesAdicionadas = [];

    this.$swal("Aviso","Item adicionado com sucesso!","success");
    this.dialogComposicao = false;
    return;
  }

  ExcluirComposicao(item){
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja excluir o registro atual?",
    icon: "question",
    showCancelButton: true, 
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm:true,
    preConfirm:() => {
      if(item.id){
        item.excluido = true;
        const index = this.item.itens.indexOf(item);
        delete this.item.itens[index];
        this.dialog = false;
        this.dialog = true;
        return true;
      }else{
        const index = this.item.itens.indexOf(item);
        this.item.itens.splice(index, 1);
        return this.$swal("Aviso", "Operação realizada com sucesso!", "success");
        } 
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
      }).then((result) => {
      if(result.value){

      }
    })
  }

  PrepararInsumos(){
    if(this.insumosAdicionados.length == 0){
      this.$swal("Aviso","É obrigatório adicionar os insumos","warning");
      return;
    }
      this.insumosAdicionados.forEach(insumo => {
        const produtoExistente = this.item.itens.find(item => item.insumoId === insumo.id);
        if(produtoExistente){
          console.log(`O insumo com ID ${insumo.id} já foi adicionado.`);
        }else{
          let composicaoitem = new ComposicaoItem();
          composicaoitem.insumo = insumo;
          composicaoitem.insumoId = insumo.id;
          composicaoitem.coeficiente = Number(insumo.coeficiente) ? Number(insumo.coeficiente) : 0;
          this.item.itens.push(composicaoitem);
        }
      });

    this.insumosAdicionados = [];
    this.$swal("Aviso","Item adicionado com sucesso!","success");
    this.dialogInsumo = false;
    return;
  }

  PesquisarInsumos() {
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options; 

    this.insumoService.PesquisarInsumosDetalhado(page, itemsPerPage, sortBy, sortDesc,search, columns, this.filterInsumo, 'Tipo, Origem, Unidade').then(
      (res) => {
        this.dessertsInsumos = res.data;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        } 
      }
    );  
  }
 
  AdicionarInsumo(item){
    const produtoExistente = this.insumosAdicionados.find(x => x.id === item.id);

    if(produtoExistente){
      return this.$swal("Aviso","Esse item já foi adicionado na lista!","warning");
    };

    this.insumosAdicionados.push(item);
    this.errorAlert = true;
  } 

  ExcluirInsumo(item){
    let indiceItem = this.insumosAdicionados.indexOf(item);
    this.insumosAdicionados.splice(indiceItem, 1);
  }

  mounted(){
    const origemService = new OrigemDadosService();
    origemService.ListarTudo().then(
      (res) => {
        this.listaOrigemDados = res.data.items;
      }
    )

    const estadoService = new EnderecoService();
    estadoService.ListarTudo().then(
      (res) => {
        this.listaEstados = res.data.items;
      } 
    )

    const unidadeMedidaService = new UnidadeMedidaService();
    unidadeMedidaService.ListarTudo().then(
      (res) => {
        this.unidadeMedidas = res.data.items;
      } 
    )

    const tipoComposicaoService = new TipoComposicaoService();
    tipoComposicaoService.ListarTudo().then(
      (res) => {
        this.tiposComposicao = res.data.items;
      } 
    )

    const classeComposicaoService = new ClasseComposicaoService();
    classeComposicaoService.ListarTudo().then(
      (res) => {
        this.listaClassesComposicao = res.data.items;
      } 
    )
  }
}
