
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ContaCorrenteService, IntervaloReajusteService, ParametroFinanceiroService, TipoAmortizacaoService, TipoGatewayService } from "@/core/services/financeiro";
import * as jsonpatch from 'fast-json-patch';
import { TipoIndiceService } from "@/core/services/cadastros";
import { PlanoPagamentoService, TipoAnoInicioReajusteService, TipoIntervaloParcelasService, TipoMesReajusteService, TipoOperacaoFinanceiraService, TipoPlanoPagamentoService } from "@/core/services/contratos";
import { PlanoPagamentoModelo } from "@/core/models/contratos";
 
@Component
export default class CadastroPlanoPagamentoModelo extends Vue {
  @Prop() public item!: PlanoPagamentoModelo;
  @Prop() public value!: string;
 
  service = new PlanoPagamentoService();
  valid:boolean = true;
  dialog:boolean = false;

  inicioContagemReajustesMes = [];
  inicioContagemReajustesAno = [];
  tiposPlanosPagamento:any = []; 
  tiposIntervalos = [];
  listaIndices = []; 
  tabelas = [];
  gatways = [];
  listaContaCorrentes = [];
  tiposOperacaoContrato = [];
  tiposIntervaloParcelas = [];
  selectPrazosFinanciamentos = [];
  selectPrazosEntrada = [];
  selectPrazosIntermediaria = [];

  novoPrazoIntermediaria:number = 0;
  novoPrazoEntrada:number = 0;
  novoPrazo:number = 0;
  e1:number = 1;
  
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement
  }

  headersFinanciamento = [
    { nome: 'Ações' },
    { nome: 'Prazo' },
  ]
 
  prazos = [
    { id: 1, nome: "A vista" },
    { id: 12, nome: "12" },
    { id: 24, nome: "24" },
    { id: 36, nome: "36" },
    { id: 48, nome: "48" },
    { id: 60, nome: "60" },
    { id: 72, nome: "72" },
    { id: 84, nome: "84" },
    { id: 96, nome: "96" },
    { id: 100, nome: "100" },
    { id: 108, nome: "108" },
    { id: 120, nome: "120" },
    { id: 132, nome: "132" },
    { id: 144, nome: "144" },
    { id: 150, nome: "150" },
    { id: 156, nome: "156" },
    { id: 168, nome: "168" },
    { id: 180, nome: "180" },
    { id: 192, nome: "192" },
    { id: 200, nome: "200" },
    { id: 220, nome: "220" },
    { id: 240, nome: "240" },
  ];

  prazosEntrada = [
    { id: 1, nome: "A vista" },
    { id: 12, nome: "12" },
    { id: 24, nome: "24" },
    { id: 36, nome: "36" },
    { id: 48, nome: "48" },
    { id: 60, nome: "60" },
  ]
 
  @Watch('item') 
  Item(){
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  observer! : jsonpatch.Observer<PlanoPagamentoModelo>;

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 

     if (this.dialog){
       this.observer = jsonpatch.observe(this.item);
     }
     else {
       jsonpatch.unobserve(this.item, this.observer);
     }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }
     
  Validacoes(){
    if(!this.item.nome){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      this.e1 = 1;
      return true; 
    }

    if(!this.item.tipoIndiceId || !this.item.intervaloReajusteId || !this.item.tipoMesReajusteId || !this.item.tipoAnoInicioReajusteId){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      this.e1 = 2;
      return true; 
    }

    if(!this.item.tipoAmortizacaoId  || !this.item.tipoValorTotalId){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      this.e1 = 3;
      return true; 
    }

    if(this.item.financiamento.prazosDisponiveis.length == 0){
      this.$swal("Aviso", "É necessário adicionar os Prazos", "warning");
      this.e1 = 4;
      return true; 
    }
  
  }

  Salvar() {
    this.$refs.form.validate()
      if (!this.Validacoes()) { 

        if(this.item.id > 0){
          this.$swal({
            title: "Atenção!",
            text: "Deseja atualizar todas as unidades que possuem este plano de pagamento? \n ",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não, Apenas Salvar",
            showCloseButton: true,
            showLoaderOnConfirm: true,
      
            preConfirm: () => {
              return this.service.AtualizarPlano(this.item, this.item.id).then(
                (res) => {
                  if (res.status == 200) {
                    this.Close();
                    return res; 
                  }
                },
              (err) => {
                if (!err.response) {
                  this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } else if (err.response.status == 403) {
                  this.$swal("Aviso", err.response.data.message, "warning" );
                } else {
                  this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
                }
              }); 
            },
            // @ts-ignore: Unreachable code error
            allowOutsideClick: () => !this.$swal.isLoading(),
          }).then((result: any) => {
            if (result.value) {
              this.$swal("Aviso",result.value.data,result.value.status == 200 ? "success" : "warning");
            }else if(result.dismiss == "cancel"){
              this.Editar();
            }
          });


        }else{
          this.Editar();
        }
      } 
  }

  Editar(){
    let pacthModel = jsonpatch.generate(this.observer);
    this.item.financiamento.tipoId = 2;
    (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
      (res) => {
        this.$swal("Aviso", "Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close();
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      });
  }

  Close() {
    this.dialog = false;
  } 

  AdicionarPrazoFinanciamento() {
    if(this.selectPrazosFinanciamentos.length > 0){
      for (let i = 0; i < this.selectPrazosFinanciamentos.length; i++) {
        if (!this.item.financiamento.prazosDisponiveis.some((x) => x === this.selectPrazosFinanciamentos[i])) {
          this.item.financiamento.prazosDisponiveis.push(this.selectPrazosFinanciamentos[i]);
        }
      }
      this.selectPrazosFinanciamentos = [];
    }else{
       const prazoExistente = this.item.financiamento.prazosDisponiveis.find((x) => x === Number(this.novoPrazo));

      if (prazoExistente) {
        return this.$swal("Aviso", "Este prazo já foi adicionado na lista.", "warning");
      }

      this.item.financiamento.prazosDisponiveis.push(Number(this.novoPrazo));
      this.novoPrazo = 0;
    }

  this.item.financiamento.prazosDisponiveis.sort((a, b) => a - b);
  }
  
  ExcluirPrazoFinanciamento(item){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      focusConfirm:true,
      preConfirm:() => {
        const index = this.item.financiamento.prazosDisponiveis.indexOf(item);
        this.item.financiamento.prazosDisponiveis.splice(index, 1);
        return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result:any) => {
      if(result.value){
      }
    })
  }
 
  AdicionarPrazoEntrada() {
    if(this.selectPrazosEntrada.length > 0){
      for (let i = 0; i < this.selectPrazosEntrada.length; i++) {
        if (!this.item.entrada.prazosDisponiveis.some((x) => x === this.selectPrazosEntrada[i])) {
          this.item.entrada.prazosDisponiveis.push(this.selectPrazosEntrada[i]);
        }
      }
      this.selectPrazosEntrada = [];
    }else{
       const prazoExistente = this.item.entrada.prazosDisponiveis.find((x) => x === Number(this.novoPrazoEntrada));

      if (prazoExistente) {
        return this.$swal("Aviso", "Este prazo já foi adicionado na lista.", "warning");
      }

      this.item.entrada.prazosDisponiveis.push(Number(this.novoPrazoEntrada));
      this.novoPrazoEntrada = 0;
    }

    this.item.entrada.prazosDisponiveis.sort((a, b) => a - b);
  }

  ExcluirPrazoEntrada(item){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      focusConfirm:true,
      preConfirm:() => {
        const index = this.item.entrada.prazosDisponiveis.indexOf(item);
        this.item.entrada.prazosDisponiveis.splice(index, 1);
        return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result:any) => {
      if(result.value){
      }
    })
  }

  AdicionarPrazoIntermediaria() {
    if(this.selectPrazosIntermediaria.length > 0){
      for (let i = 0; i < this.selectPrazosIntermediaria.length; i++) {
        if (!this.item.intermediaria.prazosDisponiveis.some((x) => x === this.selectPrazosIntermediaria[i])) {
          this.item.intermediaria.prazosDisponiveis.push(this.selectPrazosIntermediaria[i]);
        }
      }
      this.selectPrazosIntermediaria = [];
    }else{
       const prazoExistente = this.item.intermediaria.prazosDisponiveis.find((x) => x === Number(this.novoPrazoIntermediaria));

      if (prazoExistente) {
        return this.$swal("Aviso", "Este prazo já foi adicionado na lista.", "warning");
      }

      this.item.intermediaria.prazosDisponiveis.push(Number(this.novoPrazoIntermediaria));
      this.novoPrazoIntermediaria = 0;
    }

    this.item.intermediaria.prazosDisponiveis.sort((a, b) => a - b);
  }

  ExcluirPrazoIntermediaria(item){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      focusConfirm:true,
      preConfirm:() => {
        const index = this.item.intermediaria.prazosDisponiveis.indexOf(item);
        this.item.intermediaria.prazosDisponiveis.splice(index, 1);
        return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result:any) => {
      if(result.value){
      }
    })
  }

  mounted(){
    const gatwayService = new TipoGatewayService();
    gatwayService.ListarTudo().then( 
      (res) => {
        this.gatways = res.data.items;
      }
    )

     const tipoOperacaoContrato = new TipoOperacaoFinanceiraService();
    tipoOperacaoContrato.ListarTudo().then( 
      (res) => {
        this.tiposOperacaoContrato = res.data.items;
      }
    )

    const contaCorrenteService = new ContaCorrenteService();
    contaCorrenteService.ListarTudo().then(
      (res) => {
        this.listaContaCorrentes = res.data.items;
      }
    )

    new TipoIntervaloParcelasService().ListarTudo().then(
      res => {this.tiposIntervaloParcelas = res.data.items})

    new TipoAnoInicioReajusteService().ListarTudo().then( 
      res => {this.inicioContagemReajustesAno = res.data.items})

    new TipoPlanoPagamentoService().ListarTudo().then(
      res => {this.tiposPlanosPagamento = res.data.items})
    
    new TipoMesReajusteService().ListarTudo().then(
      res => {this.inicioContagemReajustesMes = res.data.items})

    new TipoIndiceService().ListarTudo().then(
      res => {this.listaIndices = res.data.items})

    new TipoAmortizacaoService().ListarTudo().then(
      res => {this.tabelas = res.data.items})

    new IntervaloReajusteService().ListarTudo().then(
      res => {this.tiposIntervalos = res.data.items})
  }
}
