
import ContratoObservacao from "@/core/models/cadastros/ContratoObservacao";
import { UsuarioService } from "@/core/services/cadastros";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class VCardObservacao extends Vue {
  @Prop() lista?: any[];

  observacao = new ContratoObservacao();
  listaObservacoes:ContratoObservacao[] = [];
  usuarios = [];
  observacaoIndex = -1;
  dialog = false;

  options = {
    itemsPerPage: 5
  };

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Observação", value: "observacao" },
    { text: "Usuário", value: "usuarioId" },
    { text: "Data", value: "dataHora" },
  ];

  Adicionar() {
    this.observacao.usuarioId = JSON.parse(localStorage.sessionApp.split(".")).dados.usuarioId;
    var objeto = Object.assign({}, this.observacao);
    objeto.usuarioId = this.observacao.usuarioId;
  
    if (this.observacaoIndex == -1) {
      this.listaObservacoes.push(objeto);
      this.AtualizarLista(this.listaObservacoes);
    }
    this.observacao = new ContratoObservacao();
    this.observacaoIndex = -1;
  }

  Excluir(item: any) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        if (item.id > 0) {
          const index = this.lista!.indexOf(item);
          item.ativo = false;
          this.dialog = false;
          this.dialog = true;
          return true;
        } else {
          const index = this.lista!.indexOf(item);
          this.lista!.splice(index, 1);
          return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if (result.value) {
      }
    });
  }

  AtualizarLista(novoValor: any) {
    this.$emit("update:lista", novoValor);
  }

  @Watch("lista")
  ObservadorLista(novoValor: any) {
    this.listaObservacoes = Object.assign([], this.lista);
  }

  mounted() {
    new UsuarioService().Listar(-1, -1, ['Login'],[],'',[], '', '' , 'Id,Login', '').then(
      res => {this.usuarios = res.data.items});

      this.listaObservacoes = Object.assign([], this.lista);
  }
}
