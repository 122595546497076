
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class Historico extends Vue {
  @Prop() lista?: any[];

  listaObservacoes:any = [];
  usuarios = [];
  observacaoIndex = -1;
  dialog = false;

  options = {
    itemsPerPage: 5
  };

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Situação", value: "situacaoId" },
    { text: "Data de Alteração", value: "dataAlteracao" },
    { text: "Responsável", value: "usuario.login" },
  ];

 
  AtualizarLista(novoValor: any) {
    this.$emit("update:lista", novoValor);
  }

  @Watch("lista")
  ObservadorLista(novoValor: any) {
    this.listaObservacoes = Object.assign([], this.lista);
  }

  mounted() {
    this.listaObservacoes = Object.assign([], this.lista);
  }
}
