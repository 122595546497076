
  import { Vue, Component, Prop, Watch } from "vue-property-decorator";
  
  @Component
  export default class LinksDashboard extends Vue {
  dialogEmitirBoleto = false;
  
    $refs!: {
      form: HTMLFormElement;
    };
  
  
    mounted(){
     
    }
  }
  