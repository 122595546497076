
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Loader } from "@googlemaps/js-api-loader";
import { EmpreendimentoService } from "@/core/services/cadastros";
import { Empreendimento } from "@/core/models/cadastros";
import toGeoJSON from "togeojson";
import { TratarErroApi } from "@/assets/scripts/helper";

@Component
export default class Map extends Vue { 
@Prop() public item!: Empreendimento;
@Prop() public value!: string; 
@Prop() public grupos!: [];

    unidadeKml:any = {}
    unidades = [];
    dialog = false;

    @Watch("value")
    Value() {
    this.dialog = this.value ? true : false; 
    }

    @Watch("dialog")
    Dialog() {
    if (!this.dialog) {
        this.$emit("fechou");
     }else{
        this.ObterKml();
     }
    }

    Close() {
        this.dialog = false;
    }
     
    filter = {
        quadraId:0,
        unidadeId:0
    }
 
    regex = /^(https?:\/\/[^\/]+)/;
    url:any = window.location.href;
    
    loader = new Loader({
        apiKey: "AIzaSyCevPtst65sq0y7iDu1eW0chOPZmq2sSCc",
        version: "weekly"
    });

    $refs!: {
        uploader:HTMLFormElement
    };
 
    ObterUnidades(id){
        if(id){
            new EmpreendimentoService().ListarUnidade(id).then(
                (res) => { 
                    this.unidades = res.data;
                },
                (err) => TratarErroApi(err)
            )
        } 
    } 

    Mapear() { 
        if(this.unidadeKml.id == ""){
           this.$swal("Aviso","É necessário selecionar no mapa a unidade que deseja mapear!", "warning"); 
          return;
        }

        if(this.filter.unidadeId == 0){
            this.$swal("Aviso","É necessário selecionar o campo unidade para fazer o mapeamento!", "warning");            
            return
        }

        new EmpreendimentoService().MapearKml(this.filter.unidadeId, this.unidadeKml.id).then(
            (res) => {
            this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");            
            this.$emit("salvou");
            this.filter.unidadeId = 0;
            this.unidadeKml = {};
        },  
        (err) => TratarErroApi(err)
        )
    } 
    
    async ObterKml() {
    this.loader.load().then(() => {
      let configMap = {
        center: { lat: -14.235, lng: -51.925 },
        zoom: 4,
        mapTypeId: google.maps.MapTypeId.SATELLITE,
      };
      var origem = this.url.match(this.regex)[0].replace("http://", "");
      let mapa = new google.maps.Map(document.getElementById("map") as HTMLElement,configMap);
      const kmlUrl = origem == "development.scae.adm.br" || origem == "localhost:9091"  || origem == "demo.scae.adm.br" ? `http://api-development.scae.adm.br/api/empreendimento/${this.item.id}/kml?origem=${origem}` : `http://api.scae.adm.br/api/empreendimento/${this.item.id}/kml?origem=${origem}`;
      //const kmlUrl = origem == "development.scae.adm.br" || origem == "localhost:9091"  || origem == "demo.scae.adm.br" ? `https://e7e3-186-231-125-12.ngrok-free.app/api/empreendimento/${this.item.id}/kml?origem=${origem}` : `http://api.scae.adm.br/api/empreendimento/${this.item.id}/kml?origem=${origem}`;
      let ctaLayer = new google.maps.KmlLayer({url: kmlUrl,map: mapa});  
       
      ctaLayer.addListener('click', event => {
        this.unidadeKml = event.featureData;
      })

       fetch(kmlUrl).then((response) => response.text()).then((kmlText) => {
        const kmlParser = new DOMParser();
        const kmlDoc = kmlParser.parseFromString(kmlText, "application/xml");
        const geojson = toGeoJSON.kml(kmlDoc);

          if (geojson.features && geojson.features.length > 0) {
            for (const feature of geojson.features) {
              if (feature.geometry && feature.geometry.type === "Polygon") {
                const polygon = feature.geometry.coordinates;
                const name = feature.properties.name;
                const position = this.GetPolygonCenter(polygon);
                const nameOverlay = new NameOverlay(position, name, mapa);

                // if (feature.properties.id && name) {
                //   console.log("cheguei aqui");
                //   nameOverlay.overlayName += ' - ' + name;
                //   nameOverlay.classSituacaoUnidade = "name-overlay";
                //   nameOverlay.draw();
                // }
              }
            }
          }
        })
        .catch((error) => {
          console.error("Erro ao carregar o KML:", error);
        });
    });
  }

    GetPolygonCenter(polygon: any[]) {
        let minX = Infinity;
        let maxX = -Infinity;
        let minY = Infinity;
        let maxY = -Infinity;

        for (const ring of polygon) {
        for (const point of ring) {
            minX = Math.min(minX, point[0]);
            maxX = Math.max(maxX, point[0]);
            minY = Math.min(minY, point[1]);
            maxY = Math.max(maxY, point[1]);
        }
        }

        const centerX = (minX + maxX) / 2;
        const centerY = (minY + maxY) / 2;
        return new google.maps.LatLng(centerY, centerX);
    }
}

class NameOverlay extends google.maps.OverlayView {
  private position!: google.maps.LatLng;
  public overlayName!: string; // Renomeada para evitar conflito de nomes
  public classSituacaoUnidade!: string;
  private map!: google.maps.Map;
  private div!: HTMLDivElement | null;

  constructor(position: google.maps.LatLng, overlayName: string, map: google.maps.Map) {
    super();
    this.position = position;
    this.overlayName = overlayName; // Atualizada para o novo nome da propriedade
    this.map = map;
    this.div = null;
    this.setMap(map);
  }

  onAdd() {
    const div = document.createElement("div");
    div.className = "disponivel";
    div.innerHTML = this.overlayName; // Atualizada para o novo nome da propriedade
    this.div = div;
    const panes: any = this.getPanes();
    panes.overlayLayer.appendChild(div);
  }

  draw() {
    if (!this.div) return;

    const overlayProjection = this.getProjection();
    const position: any = overlayProjection.fromLatLngToDivPixel(this.position);

    this.div.style.left = position.x + "px";
    this.div.style.top = position.y + "px";
    console.log("mapear");
  }

  onRemove() {
    if (this.div) {
      this.div.parentNode!.removeChild(this.div);
      this.div = null;
    }
  }
}
