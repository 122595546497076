
import { Usuario } from "@/core/models/cadastros";
import { Contato } from "@/core/models/geral";
import { UsuarioService } from "@/core/services/cadastros";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';
import { ArquivoService } from "@/core/services/geral/ArquivoService";

@Component
export default class CadastroIndice extends Vue {
  @Prop() public item!: Usuario;
  @Prop() public value!: string;

  service = new UsuarioService();
  observer! : jsonpatch.Observer<Usuario>;

  tabActive = 0;
  arquivo:any = null;
  valid = true;
  dialog = false;
  show1 = false;
  show2 = false;
    
  password2 = "";
  Caracters = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }  

  $refs!: {
    form: HTMLFormElement;
  };

  rules = {
    required: (value) => !!value || "Campo obrigatório.",
    min: (v) =>(v.length >= 8 && !!this.Caracters.test(v)) || "Mínimo 8 caracteres incluindo 1 caracter especial",
  };
 
  Salvar() {
    this.$refs.form.validate();

    if (!this.Validacoes()) {
      let pacthModel = jsonpatch.generate(this.observer);
 
      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        }, 
         (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        });
      this.arquivo = null; 
    }
  }

 get mensagem(){
      if(this.item.senha != this.password2)
    {
      return  "As senhas não são iguais."
    }
      else
    {
      return ''
    }
  }
  
  Validacoes(){
    if(this.item.id == 0){
      if(!this.item.login || !this.item.senha || !this.password2 || !this.item.complementar.nome || !this.item.complementar.celular || !this.item.complementar.email){
        this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
        this.tabActive = 0;
        return true;
      }
    }
  }

  Close() {
    this.dialog = false;
    this.password2 = "";
    this.arquivo = null;
  }

  async AdicionarArquivo() {
    let src: string = <string>await new ArquivoService().Ler(this.arquivo);
    this.item.foto = src.replace(/^[^,]*,/, "");
  }
}
