
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Tinymce from '@tinymce/tinymce-vue';
import { ContratoDigital } from "@/core/models/cadastros";

@Component({
  components: {
    Editor:Tinymce
  }
})

export default class AnteciparContrato extends Vue {
  @Prop() public contratoDigital!: ContratoDigital;
  @Prop() public value!: string;

 
  
  valid = true;
  dialog = false;

  $refs!: {
    form: HTMLFormElement;
  };

  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  Close() {
    this.dialog = false;
  }
}
