
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Contrato, Unidade } from "@/core/models/cadastros";
import { EmpreendimentoService } from "@/core/services/cadastros";
import { TratarErroApi } from "@/assets/scripts/helper";

@Component
export default class LadosAdicionais extends Vue {
  @Prop() public item!: Contrato;
  @Prop() private value!: string;

  unidade = new Unidade();

  dialog = false;
  dialogUnidadeInformacao = false;

  ObterUnidade(item){
    new EmpreendimentoService().ObterUnidade(item.unidadePrincipalId > 0 ? item.unidadePrincipalId : item.unidadeId, 'Imovel, Jazigo, Lote, Lote.Infraestrutura').then(
      res => {
        this.dialogUnidadeInformacao = true;
        this.unidade = res.data;  
      },
      (err) => TratarErroApi(err)
      )
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  Close() {
    this.dialog = false;
  }
}
