
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Endereco } from "@/core/models/shared";
import { Municipio, Estado } from "../../../core/models/geral";
import ExternalServices from "@/core/services/geral/ExternalServices";
import { AssinanteService } from "@/core/services/cadastros";
import { Assinante, AssinanteContato, AssinanteDominio } from "@/core/models/cadastros";
import { EnderecoService } from "@/core/services/geral/EnderecoService";

@Component
export default class CadastroAssinante extends Vue {
  @Prop() public item!: Assinante;
  @Prop() public value!: string;
 
  contatoIndex: number = -1;
  dominioIndex: number = -1;
  tabActive = null;
  tab = null
  municipios: Municipio[] = [];
  contatoPessoa = new AssinanteContato();
  dominioPrincipal = new AssinanteDominio();
  private listaMunicipio: Municipio[] = [];
  private listaEstado: Estado[] = [];
  private serviceEndereco: EnderecoService = new EnderecoService();
  private debounce: any = null;
  private carregando:boolean = false;
 
  estados: Estado[] = [];
  readonly: boolean = true;

  service = new AssinanteService();

  @Watch("item.endereco.estadoId")
  CarregarMunicipio(id: any) {
    this.serviceEndereco.ListarMunicipiosPorIdEstadual(id).then(res => {
      this.listaMunicipio = res.data.items;
      this.listaMunicipio.sort((x,y) => (x.nome > y.nome) ? 1 : ((y.nome > x.nome) ? -1 : 0) )
    }, err => err);
  }
 
  BuscarCep(tipo:string, value:any){
    if(tipo.toLocaleLowerCase() === "cep"){
     if (value.length == 9) { 
      clearTimeout(this.debounce);
      this.carregando = true;
      this.debounce = setTimeout(async () => {
        await this.serviceEndereco.EncontrarEnderecoPorCep(this.item.endereco.cep).then((res) => {
          
          if(res.status == 204){
            this.$swal("Atenção!", `Erro ao efetuar a consulta do cep ${this.item.endereco.cep}. Verifique se o CEP informado é válido e tente novamente.`, "warning");
            this.carregando = false;
            return;  
          }
          let endereco = new Endereco();
          endereco.cep = res.data.cep;
          endereco.bairro = res.data.bairro;
          endereco.logradouro = res.data.logradouro;
          endereco.municipioId = res.data.municipioId;
          endereco.estadoId = res.data.estadoId;
         
          this.item.endereco.cep = endereco.cep;
          this.item.endereco.bairro = endereco.bairro;
          this.item.endereco.logradouro = endereco.logradouro;
          this.item.endereco.municipioId = endereco.municipioId;
          this.item.endereco.estadoId = endereco.estadoId;
          
          this.carregando = false;
        }, err => {
          this.$swal("Erro!", `Erro ao efetuar a consulta do cep ${this.item.endereco.cep}. Verifique se o CEP informado é válido e tente novamente.`, "error");          
          this.carregando = false;
        });
      }, 2500);
    }
    }
  }
  private debounce2: any = null;
  private carregando2:boolean = false;

  BuscarCepResponsavel(tipo:string, value:any){
    if(tipo.toLocaleLowerCase() === "cep"){
      if (value.length == 9) { 
      clearTimeout(this.debounce2);
      this.carregando2 = true;
      this.debounce2 = setTimeout(async () => {
        await this.serviceEndereco.EncontrarEnderecoPorCep(this.item.responsavel.endereco.cep).then((res) => {
          
          if(res.status == 204){
            this.$swal("Atenção!", `Erro ao efetuar a consulta do cep ${this.item.responsavel.endereco.cep}. Verifique se o CEP informado é válido e tente novamente.`, "warning");
            this.carregando2 = false;
            return;  
          }
           
          let endereco = new Endereco();
          endereco.cep = res.data.cep;
          endereco.bairro = res.data.bairro;
          endereco.logradouro = res.data.logradouro;
          endereco.municipioId = res.data.municipioId;
          endereco.estadoId = res.data.estadoId;
         
          this.item.responsavel.endereco.cep = endereco.cep;
          this.item.responsavel.endereco.bairro = endereco.bairro;
          this.item.responsavel.endereco.logradouro = endereco.logradouro;
          this.item.responsavel.endereco.municipioId = endereco.municipioId;
          this.item.responsavel.endereco.estadoId = endereco.estadoId;
          
          this.carregando2 = false;
        }, err => {
          this.$swal("Erro!", `Erro ao efetuar a consulta do cep ${this.item.responsavel.endereco.cep}. Verifique se o CEP informado é válido e tente novamente.`, "error");          
          this.carregando2 = false;
        });
      }, 2500);
    }
      }
  }
 
  headersDominio: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Nome", value: "nome" },
    { text: "Principal", value: "principal" },
  ];

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Nome", value: "nome" },
    { text: "Telefone", value: "telefone" },
    { text: "Celular", value: "celular" },
    { text: "Ramal", value: "ramal" },
    { text: "Email", value: "email" },
    { text: "Observaçao", value: "observacao" },
    { text: "Principal", value: "principal" },
  ];
  
  validContato = true
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  $refs!: {
    form: HTMLFormElement;
    formContato:HTMLFormElement
    formDominio:HTMLFormElement
  };
  
  @Watch('item')
  Item2(){
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }


  @Watch("contatoPessoa")
  ContatoPessoa() {
    if (this.$refs.formContato) {
      this.$refs.formContato.resetValidation();
    }
  }

  @Watch("dominioPrincipal")
  DominioPrincipal() {
    if (this.$refs.formDominio) {
      this.$refs.formDominio.resetValidation();
    }
  }

  async PesquisaCep() {
    if (this.item.endereco.cep.length == 8) {
      let endereco: any = await new ExternalServices().PesquisaCep(
        this.item.endereco.cep
      );
      endereco
        ? (this.item.endereco = endereco)
        : this.$swal("Aviso", "CEP inválido!", "error");
      endereco ? (this.readonly = true) : (this.readonly = false);
    }
  }
  //DOMINIO PRINCIPAL
  AdicionarDominio(){
     if (this.$refs.formDominio.validate()) {
      if (this.dominioIndex == -1) {
        this.item.dominios.push(this.dominioPrincipal);
      }
      this.dominioPrincipal = new AssinanteDominio();
      this.dominioIndex = -1;
    }
  }

  EditarDominio(item: AssinanteDominio) {
    if (this.dominioIndex != -1) {
      this.$swal(
        "Cuidado!",
        "Não é possível editar um registro sem finalizar uma edição já em andamento.",
        "error"
      );
      return;
    }
    this.dominioPrincipal = item;
    this.dominioIndex = this.item.dominios.indexOf(item);

    //this.item.contatos = this.item.contatos.filter(f => f.id != item.id);
  }

  ExcluirDominio(item: AssinanteDominio) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
    }).then((result) => {
      if (result.value) {
        const index = this.item.dominios.indexOf(item);
        this.item.dominios.splice(index, 1);
        this.$swal("Aviso", "Contato excluído com sucesso!", "success");
      }
    });
  }


//CONTATO PRINCIPAL
 AdicionarContato() {
    if (this.$refs.formContato.validate()) {
      if (this.contatoIndex == -1) {
        this.item.contatos.push(this.contatoPessoa);
      }
      this.contatoPessoa = new AssinanteContato();
      this.contatoIndex = -1;
    }
  }

  Editar(item: AssinanteContato) {
    if (this.contatoIndex != -1) {
      this.$swal(
        "Cuidado!",
        "Não é possível editar um registro sem finalizar uma edição já em andamento.",
        "error"
      );
      return;
    }
    this.contatoPessoa = item;
    this.contatoIndex = this.item.contatos.indexOf(item);

    //this.item.contatos = this.item.contatos.filter(f => f.id != item.id);
  }

  Excluir(item: AssinanteContato) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
    }).then((result) => {
      if (result.value) {
        const index = this.item.contatos.indexOf(item);
        this.item.contatos.splice(index, 1);
        this.$swal("Aviso", "Contato excluído com sucesso!", "success");
      }
    });
  }

  Salvar() {
    if (this.$refs.form.validate()) {
      let modelo = this.item;

      this.service.Salvar(modelo).then(
        (res) => {
          this.$swal(
            "Aviso",
            "Operação realizada com sucesso!",
            res.status == 201 || res.status == 200 ? "success" : "warning"
          );
          this.$emit("salvou");
        },
        (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
        });

    this.Close();
    }
  }

  Close() {
    this.dialog = false;
  }
 
  mounted() {
    this.serviceEndereco.ListarEstados().then(res => {
      this.listaEstado = res.data.items;
      this.listaEstado.sort((x,y) => (x.nome > y.nome) ? 1 : ((y.nome > x.nome) ? -1 : 0))
    }, err => err);

  }
}
