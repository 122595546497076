import { ArquivoService } from './ArquivoService';
import { EstadoService } from './EstadoService';
import { MunicipioService } from './MunicipioService';
import { AutenticadorService } from './AutenticadorService';
import { SexoService } from './SexoService';
import { QualificacaoService } from './QualificacaoService';
import { EnderecoService } from './EnderecoService';
import { EscolaridadeService } from './EscolaridadeService';
import { TermoUsoService } from './TermoUsoService';

export default {
    AutenticadorService,
    MunicipioService,
    EstadoService,
    SexoService,
    EnderecoService, 
    QualificacaoService,
    ArquivoService,
    EscolaridadeService,
    TermoUsoService
}