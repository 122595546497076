import { Execucao } from ".";
import { Unidade } from "../cadastros";

export default class ExecucaoUnidade implements Shared.IEntity{

    public id: number = 0;
    public execucaoId: number = 0;
    public unidadeId: number = 0;

  

}
