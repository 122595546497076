
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { EmpresaService } from "../../../core/services/compras/EmpresaService";
import { Empresa } from "@/core/models/cadastros";
import * as jsonpatch from 'fast-json-patch';
import { ArquivoService } from "@/core/services/geral/ArquivoService";
import { TipoEmpresaService } from "@/core/services/negocios/";

@Component
export default class CadastroEmpresa extends Vue {
  @Prop() public item!: Empresa;
  @Prop() public value!: string;

  service = new EmpresaService();
  loading = false;
  dialog = false;
  valid = true;

  listaTipoEmpresa = [];
  arquivoLogo:any = null;

  tabActive = 0;   
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
  };
 
  @Watch("item")
  Item() {
  if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }
   
  observer! : jsonpatch.Observer<Empresa>; 

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    } else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }else{
       this.item.gerarZoop = false;
    }
  } 

  RemoveImage(){
    this.item.logo = null;
    this.arquivoLogo = null;
  }

  async LoadImage(){
    this.loading = true;
    if (!this.arquivoLogo)
        return; 

    const arquivoService = new ArquivoService();
    const dados = await arquivoService.Ler(this.arquivoLogo);
    this.item.logo = dados.replace(/^[^,]*,/, "");
    this.loading = false;
  } 


  Validacoes(){
    if(!this.item.email || !this.item.nome || !this.item.telefone1 || !this.item.nomeFantasia || !this.item.cpfCnpj || !this.item.tipoEmpresaId){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      this.tabActive = 0;
      return true;
    }

    if(!this.item.endereco.cep || !this.item.endereco.logradouro || !this.item.endereco.bairro || !this.item.endereco.estadoId || !this.item.endereco.municipioId){
      this.$swal("Atenção", "É necessário preencher o endereço completo da Empresa.", "warning");
      this.tabActive = 0;
      return true;
    }
 
   if(!this.item.responsavel.nome || !this.item.responsavel.sobrenome || !this.item.responsavel.cpf || !this.item.responsavel.dataNascimento){
      this.$swal("Atenção", "Campos obrigatórios não preenchidos.", "warning");
      this.tabActive = 2;
      return true;
    }

    if(!this.item.responsavel.endereco.cep || !this.item.responsavel.endereco.logradouro || !this.item.responsavel.endereco.bairro || !this.item.responsavel.endereco.estadoId || !this.item.responsavel.endereco.municipioId){
      this.$swal("Atenção","É necessário preencher o endereço completo do Responsável.", "warning");
      this.tabActive = 2;
      return true;
    }
  }

  Salvar() { 
    this.$refs.form.validate()

    if (!this.Validacoes()) {     
      let pacthModel = jsonpatch.generate(this.observer);
      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        (res) => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
         (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        });
    }
  }
 
  Close() {
    this.tabActive = 0;
    this.dialog = false;
    this.arquivoLogo = null;
  }

  mounted(){
    const tipoEmpresaService = new TipoEmpresaService();
    tipoEmpresaService.ListarTudo().then((
      (res) => {
        this.listaTipoEmpresa = res.data.items.sort((a, b) => {
      return a.nome.localeCompare(b.nome);
      })
      }
    ))
  }
}
