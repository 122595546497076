

import { Component, Prop, Watch } from 'vue-property-decorator';
import { PageBase } from '@/core/models/shared';
 
import { TermoUso } from '@/core/models/geral';
import { TermoUsoService } from '@/core/services/geral/TermoUsoService';

@Component
export default class TermoUsoDialog extends PageBase {

    @Prop() private value!: boolean;

    dialog: boolean = false;
    loadingButton: boolean = false;
    termoUsoService = new TermoUsoService();
    termoUso: TermoUso = new TermoUso();

    @Watch('value')
    async WatchValue() {
        this.dialog = this.value;
        if (this.value) {
           // await this.ObterTermosDeUso();
        }
    }

    async ObterTermosDeUso() {
        const response = await this.termoUsoService.ObterTermosDeUsoVigente();
        this.termoUso = response.data;
        const div = document.getElementById('termo-de-uso');
        div!.innerHTML = this.termoUso.texto;
    }

    Fechar() {
        this.dialog = false;
        this.$emit('fechou');
    }
}
