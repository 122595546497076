
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { PessoaService } from "@/core/services/cadastros";
import { Contrato } from "@/core/models/cadastros";
import { ContratoService } from "@/core/services/cadastros/ContratoService";
import Pessoa from "@/core/models/cadastros/Pessoa";

@Component
export default class DetalharPessoa extends Vue {
  @Prop() public item!: Contrato;
  @Prop() public value!: string;
  
  service = new ContratoService();
  pessoa = new Pessoa();

  dialog = false;
  dialogCadastro:boolean = false;

  tab = null;

  clientes = [];

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  Close() {
    this.dialog = false
  } 

  /* Pessoas Envolvidas */ 
  EditarCliente(item: Pessoa) {
    this.pessoa = item.cliente ;
    this.dialogCadastro = true;
    this.pessoa.dataExpedicao = new Date(`${item.cliente.dataExpedicao}`).yyyymmdd();
    this.pessoa.dataNascimento = new Date(`${item.cliente.dataNascimento}`).yyyymmdd();
    this.pessoa.dataCadastro = new Date(`${item.cliente.dataCadastro}`).yyyymmdd(); 
    this.pessoa.conjuge.dataNascimento = new Date(`${item.cliente.conjuge.dataNascimento}`).yyyymmdd();
  }
  mounted() {
    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome', '').then((res) => {
      this.clientes = res.data.items;
    });
  }
}
