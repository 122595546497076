import { TipoPessoaService } from './TipoPessoaService';
import { NacionalidadeService } from './NacionalidadeService';
import { NaturalidadeService } from './NaturalidadeService';
import { ProfissaoService } from './ProfissaoService';
import { EmpresaService } from './EmpresaService';
import { EstadoCivilService } from './EstadoCivilService';
import { PedidoService } from './PedidoService';
import { SituacaoPedidoItemService } from './SituacaoPedidoItemService';
import { OrcamentoService } from './OrcamentoService';
import { SituacaoOrcamentoService } from './SituacaoOrcamentoService';
import { SituacaoFreteService } from './SituacaoFreteService';
import { ParametroService } from './ParametroService';
  
export default {
    OrcamentoService,
    TipoPessoaService,
    NacionalidadeService,
    NaturalidadeService,
    ProfissaoService,
    EmpresaService,
    PedidoService,
    SituacaoPedidoItemService,
    SituacaoOrcamentoService,
    SituacaoFreteService,
    EstadoCivilService,
    ParametroService
}  