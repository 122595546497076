
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Pedido} from "@/core/models/compras";
import { PedidoService } from "@/core/services/compras/PedidoService";
 
@Component
export default class Detalhar extends Vue {
  @Prop() public item!: Pedido;
  @Prop() public value!: string;

  service = new PedidoService();
 
  tabActive = null;
  loading = false;
  valid = true;
  dialog = false;
  
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: { formClassificacao: HTMLFormElement };

  /* cabeçalho das tabelas */
  headers = [
    { text: "Cod Produto", value: "codigo" },
    { text: "Produto", value: "produtoId" },
    { text: "Qtde Pedida", value: "quantidade" },
    { text: "Qtde à Receber", value: "quantidadeAReceber" },
    { text: "Qtde Recebida", value: "quantidadeRecebida" },
    { text: "Situação", value: "situacaoId" },
    { text: "Vr.Unitário", value: "valorUnitario" },
    { text: "Total", value: "total" },
    { text: "Total recebido", value: "totalRecebido" }
  ];

  @Watch("value")
  Value() {
     this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }
 
  Close() {
    this.dialog = false;
  }

  /* Estilo ao editar a Parcela */
  situacaoColor(id: any) {
    switch (id) {
      case 1:
        return "blue lighten-3";
      case 2:
        return "yellow lighten-3";
      case 3:
        return "teal lighten-3";
      case 4:
        return "lime lighten-3";
      case 5:
        return "red lighten-3";
      default:
        return "";
    }
  }
  mounted() {
  }
}
