
import { Contato } from "@/core/models/geral";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class Historico extends Vue {
  @Prop() lista?: any[];

  contatoPessoa = new Contato();
  valid = true;

  listaObservacoes:any = [];
  contatoIndex: number = -1;

  $refs!: {
    formContato: HTMLFormElement;
  };

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Nome", value: "nome" },
    { text: "Telefone", value: "telefone" },
    { text: "Celular", value: "celular" },
    { text: "Ramal", value: "ramal" },
    { text: "Email", value: "email" },
  ];

  @Watch("contatoPessoa")
  ContatoPessoa() {
    if (this.$refs.formContato) {
      this.$refs.formContato.resetValidation();
    }
  }

   AdicionarContato() {
    if (this.contatoIndex > -1) {
      Object.assign(this.listaObservacoes[this.contatoIndex], this.contatoPessoa)
    }else{
      this.listaObservacoes.push(this.contatoPessoa);
      this.AtualizarLista(this.listaObservacoes);
    }
    this.contatoPessoa = new Contato();
    this.contatoIndex = -1;
  } 
 
  Editar(item) {
    this.contatoIndex = this.listaObservacoes.indexOf(item);
    this.contatoPessoa = Object.assign({}, item);
  }

  Excluir(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
    }).then((result) => {
      if (result.value) {
        const index = this.listaObservacoes.indexOf(item);
        this.listaObservacoes.splice(index, 1);
        this.$swal("Aviso", "Contato excluído com sucesso!", "success");
      }
    });
  }

  AtualizarLista(novoValor: any) {
    this.$emit("update:lista", novoValor);
  }

  @Watch("lista")
  ObservadorLista(novoValor: any) {
    this.listaObservacoes = Object.assign([], this.lista);
  }

  mounted() {
    this.listaObservacoes = Object.assign([], this.lista);
  }
}
