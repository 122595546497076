
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Endereco } from '@/core/models/shared';
import { Estado, Municipio } from '@/core/models/geral';
import { EnderecoService } from '@/core/services/geral/EnderecoService';
 
@Component
export default class VCardEndereco extends Vue {
  @Prop() item!: Endereco;

  listaMunicipio: Municipio[] = [];
  listaEstado: Estado[] = [];
  carregando: boolean = false;
  serviceEndereco = new EnderecoService();

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  created() {
    this.carregarMunicipio();
  }

  @Watch("item.estadoId")
  carregarMunicipio() {
    if (this.item.estadoId > 0) {
      this.carregando = true;

      this.serviceEndereco.ListarMunicipiosPorIdEstadual(this.item.estadoId).then(res => {
        this.listaMunicipio = res.data.items;
        this.carregando = false;
      });
    }
  }
  

 buscarCep() {
    if (this.item.cep.length === 9) {
      this.carregando = true;
      try {
         this.serviceEndereco.EncontrarEnderecoPorCep(this.item.cep).then(
          (res) =>{
            this.item.cep = res.data.cep;
            this.item.bairro = res.data.bairro;
            this.item.logradouro = res.data.logradouro;
            this.item.estadoId = res.data.estadoId;
            this.item.municipioId = res.data.municipioId;
            this.carregando = false;
          }
        )       
      } catch (err) {
        this.$swal({title: "Atenção", text: `Erro ao efetuar a consulta do cep ${this.item.cep}. Verifique se o CEP informado é válido e tente novamente.`, timer:2000, icon: "warning", showConfirmButton:false, allowOutsideClick:false, allowEnterKey:false});
        this.carregando = false;
        this.item = new Endereco();
      }
    }
}

  mounted(){
    this.serviceEndereco.ListarEstados().then(res => {
      this.listaEstado = res.data.items;
    });
  }
}
