
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AlmoxarifadoService } from "@/core/services/almoxarifado";
import { Almoxarifado } from "@/core/models/almoxarifado";
import { Empreendimento } from "@/core/models/cadastros";
import { EmpreendimentoService } from "@/core/services/cadastros";
import * as jsonpatch from "fast-json-patch";

@Component
export default class CadastroBanco extends Vue {
  @Prop() public item!: Almoxarifado;
  @Prop() public value!: string;

  service = new AlmoxarifadoService();
  empreendimentos = [] as Empreendimento[];
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  observer!: jsonpatch.Observer<Almoxarifado>;

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog) {
      this.observer = jsonpatch.observe(this.item);
    } else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  $refs!: {
    form: HTMLFormElement;
  };

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  Salvar() {
    if (this.$refs.form.validate()) {
      let pacthModel = jsonpatch.generate(this.observer);

      (this.item.id > 0
        ? this.service.Patch(pacthModel, this.item.id)
        : this.service.Salvar(this.item)
      ).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning");
          } else {
            this.$swal("Aviso",err.response.data,err.response.status == 400 ? "warning" : "error");
          }
      });
    }
  }

  Close() {
    this.dialog = false;
  }

  mounted() {
    let empreendimentoService = new EmpreendimentoService();
    empreendimentoService.ListarTudo().then(
    (res) => {
      this.empreendimentos = res.data.items
    }); 
  }
}
