
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ReceitaService } from "@/core/services/financeiro";
import { ReceitaParcela } from "@/core/models/financeiro";

@Component
export default class AnteciparContrato extends Vue {
  @Prop() public item!: ReceitaParcela;
  @Prop() public value!: string;
 
  model = new Model();
  receitaService = new ReceitaService();
  valor:number = 0;
  
  valid = true;
  dialog = false;
  overlay = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  parcelas:any = [];

  $refs!: {
    form: HTMLFormElement;
  }; 
  
  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("model")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    } 
  }

  filter: {
    receitaId:number,
    parcelaIds: any;
    dataSimulada:string
  } = {
    receitaId: 0,
    parcelaIds:[],
    dataSimulada: ''
  }; 
 
  Simular(){
    if (this.$refs.form.validate()) {
     let parcelas:any = this.item;
  
      this.filter.parcelaIds = [];
      this.filter.receitaId = this.item[0].receitaId;
      this.filter.dataSimulada = this.model.dataVencimento
  
      parcelas.forEach(element => {
        this.filter.parcelaIds.push(element.id)
      });

      let routeLink = this.$router.resolve({ 
        name: "RelComprovante",
        query: {
          parcelaIds: this.filter.parcelaIds ? this.filter.parcelaIds.toString() : "",
          dataSimulada:this.filter.dataSimulada,
          receitaId: this.filter.receitaId ? this.filter.receitaId.toString() : ""
        },
      });
      window.open(routeLink.href, "_blank");
    }
  }
  
  Salvar() {
    if (this.$refs.form.validate()) {
      this.overlay = !this.overlay;

      this.model.parcelaIds = [];
      this.parcelas = this.item;
      
      this.parcelas.forEach(x => {
        this.model.parcelaIds.push(x.id)
      });

      this.receitaService.Antecipacao(this.item[0].receitaId, this.model).then(
        (res) => {
          this.$swal("Aviso", "Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }).finally(() => this.overlay = false);
    }
  } 

  GetAntecipacao(tipo, x) {
    if(tipo.toLocaleLowerCase() === "valor"){ 
      if(x.length == 10){
        if(x.charAt(0) > 0){

          this.model.parcelaIds = []; 
          this.parcelas = this.item;
          
          this.parcelas.forEach(x => {
            this.model.parcelaIds.push(x.id)
          });
        
          this.receitaService.ObterAntecipacao(this.item[0].receitaId, this.model.parcelaIds, this.model.dataVencimento).then(
            (res) => {
              this.valor = res.data;
              this.valid = true;
            },
            (err) => { 
              if (!err.response) {
                this.$swal("Aviso", "Não foi possível acessar a API", "error");
              } else if (err.response.status == 403) {
                this.$swal("Aviso", err.response.data.message, "warning");
              } else {
                this.$swal("Aviso",err.response.data,err.response.status == 400 ? "warning" : "error");
              }
              return this.valid = false, this.model = new Model();
            });
          }
        }
      }
  }

  Close() {
    this.model.parcelaIds = [];
    this.model = new Model();
    this.valor = 0;
    this.dialog = false;
  } 
}

class Model { 
  parcelaIds:any = []; 
  dataVencimento:string ='';
} 
