
import {PageBase } from "@/core/models/shared";
import {Component, Prop, Watch } from "vue-property-decorator";
import {DespesaParcela} from "../../../core/models/financeiro";
import {DespesaService,ContaCorrenteService,FormaPagamentoService} from "@/core/services/financeiro";
import {DespesaBaixa} from "@/core/models/financeiro";
import { ArquivoService } from "@/core/services/geral/ArquivoService";
 
@Component
export default class ParcelaBaixa extends PageBase {
  @Prop() public item!: DespesaParcela;
  @Prop() public value!: string;

  service = new DespesaService();
  baixa = new DespesaBaixa();

  formaPagamentos = [];
  contaCorrentes = [];
  baixas = [];

  percentualDesconto: number = 0.0;
  percentual: number = 0.0;
  acrescimosDescontos:boolean = false;
  valid:boolean = true;
  dialog:boolean = false;
  urlImgDoc: string = "";
  lerArquivo: any;
  arquivo = null;
  tabActive = null;

  overlay:boolean = false; 
  snack = false;
  loading:boolean = false;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
  };

  @Watch("baixa")
  Baixa() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  } 
  
  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    } else {
      this.Atualizar();
     }
  }

  headers = [
    { text: "Cancelar", value: "cancelar", sortable: false },
    { text: "Data", value: "dataPagamento" },
    { text: "Forma de pgt", value: "formaPagamento.nome" },
    { text: "Conta Corrente", value: "contaCorrente.nome"},
    { text: "Valor", value: "valor" },
    { text: "Encargos", value: "juros", sortable: false },
    { text: "Multa", value: "multa", sortable: false},
    { text: "Desconto", value: "desconto" , sortable: false},
    { text: "Total", value: "total", sortable: false },
    { text: "Usuário", value: "usuario", sortable: false },
    { text: "Status", value: "cancelado", sortable: false },
  ];

  Atualizar(){
    this.loading =  true;

    this.service.ObterParcelaPorId(this.item.id, "Baixas, Baixas.Usuario, Baixas.FormaPagamento, Baixas.ContaCorrente").then(   
      res => {
        this.baixas = res.data.baixas;
        this.baixa.valor = res.data.saldo;
        this.baixa.total = res.data.saldo;
        this.loading = false;
      }
    );
  }
 
  DescontoValor(){
    let valor = Number(Number(this.item.saldo) * Number(this.percentualDesconto) / 100).toFixed(2);
    this.baixa.desconto =  Number(valor);
    this.CalculoGeral();
  }

  @Watch("baixa.desconto")
  DescontoPercentual(desconto){
    if(desconto == null)
      return;

    let valor = Number((Number(desconto) * 100) / Number(this.item.saldo)).toFixed(2);
    this.percentualDesconto = Number(valor);
    this.CalculoGeral();
  }

  @Watch("baixa.juros")
  BaixaJuros(juros){
    if(juros == null)
      return;
    
    let percentual = Number(Number(juros * 100) / Number(this.item.saldo)).toFixed(2);
    this.percentual = Number(percentual);
    this.CalculoGeral();
  }

  BaixaPercentual(){
    let juros = Number((Number(this.item.saldo) * Number(this.percentual)) / 100).toFixed(2);
    this.baixa.juros  = Number(juros);
    this.CalculoGeral();
  }

  @Watch("baixa.valor")
  CalculoGeral(){
    if(this.baixa.valor == null)
      return; 
    
    let total = Number(Number(this.baixa.valor) + Number(this.baixa.juros) - Number(this.baixa.desconto)).toFixed(2);
    this.baixa.total =  Number(total);
  }

  Salvar(fechar: boolean = false){
    if (this.$refs.form.validate()) {
      this.overlay = true;
      this.baixa.usuarioId =  this.app.usuarioId;

      let modelo = this.baixa;
      this.service.BaixarParcela(this.item.id, modelo).then(
      (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          if(fechar){
            this.Close();
          }else{
            this.Atualizar();
          }
          this.baixa = new DespesaBaixa();
          this.percentual = 0;
          this.percentualDesconto = 0;
          this.arquivo = null;
      },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        }).finally(() => this.overlay = false); 
    }
  }

  Close() {
    this.dialog = false;
  }

  CancelarBaixa(item){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja cancelar a baixa?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.CancelarBaixa(item.id).then(
          (res) => {
            if (res.status == 200) {
            this.Atualizar();
            return res;
            }
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      },
       // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
      }
    });
  }

  Copiar(data: string, iD: string): void {
    
    // document.designMode = "on";
    // const element = document.getElementById(iD);
    // element!.contentEditable = "true";
    // const listener = (e: ClipboardEvent) => {
    //   e.clipboardData!.setData('text/plain', data);
    //   e.preventDefault();
    //   document.removeEventListener('copy', listener);
    // };
    this.snack = true;

    //document.addEventListener('copy', listener, { passive: true });
    document.execCommand('copy');
    navigator.clipboard.writeText(data).catch(err => {console.error("Erro ao copiar texto: ", err)});
  }
 
  async AdicionarArquivo() {
    this.lerArquivo = this.arquivo;
    let src: string = <string>await new ArquivoService().Ler(this.lerArquivo);
    this.urlImgDoc = src;

    this.baixa.comprovante.nome = this.baixa.comprovante.nome != "" ? this.baixa.comprovante.nome: this.lerArquivo.name;
    this.baixa.comprovante.dados = this.urlImgDoc.replace(/^[^,]*,/, "");
    this.baixa.comprovante.tamanho = this.lerArquivo.size / 1024;
    this.baixa.comprovante.tipo = this.lerArquivo.type;
  }

  mounted() {
    new ContaCorrenteService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      res => this.contaCorrentes = res.data.items)

    new FormaPagamentoService().ListarTudo().then(
      res => this.formaPagamentos = res.data.items)
  }
}


