export default class EncargosFinanceiros{
        public jurosDia: number = 0.00;
        public multaMes: number = 0.00;
        public desconto: number = 0.00; 
        public diasDesconto: number = 0.00;
        public descontoQuitacao: number = 0.00;
        public descontoQuitacaoLimite: number = 0.00;
        public descontoAntecipacao: number = 0.00;
        public descontoAntecipacaoLimite: number = 0.00;

        constructor(model?: EncargosFinanceiros){  
         
                if(!model)
                    return;
        
                this.jurosDia = model.jurosDia; 
               // this.tipo = model.tipo;
                this.multaMes = model.multaMes
                this.desconto = model.desconto;
                this.diasDesconto = model.diasDesconto;
                this.descontoQuitacao =  model.descontoQuitacao;
                this.descontoQuitacaoLimite = model.descontoQuitacaoLimite;
                this.descontoAntecipacao = model.descontoAntecipacao;
                this.descontoAntecipacaoLimite = model.descontoAntecipacaoLimite;
             }
}