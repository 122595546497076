
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';
import {  ModeloOrcamentoEtapa, ModeloOrcamentoEtapaItem } from "@/core/models/orcamentoObras";
import { ClasseComposicaoService, ComposicaoService, ModeloOrcamentoEtapaService, OrigemDadosService, TipoComposicaoService } from "@/core/services/orcamentoObras";
import { UnidadeMedidaService } from "@/core/services/almoxarifado";

@Component
export default class CadastroClasseComposicao extends Vue {
  @Prop() public item!: ModeloOrcamentoEtapa;
  @Prop() public value!: string;

  service = new ModeloOrcamentoEtapaService();
  valid = true;
  dialog = false;
  dialogComposicao = false;
  tabComposicao = null;
  errorAlert:boolean = false;

  expandedItems:any = [];
  listaOrigemDados = [];
  expanded:any = [];
  expandedItens:any = [];
  listaClassesComposicao = [];
  unidadeMedidas = [];
  tiposComposicao = []; 
 
  headersEtapas = [ 
    { text: '', value: 'icon', sortable: false},
    { text: 'Composição', value: 'composicao.descricao', sortable: false },
    { text: 'Quantidade', value: 'quantidade', sortable: false },
    { text: '', value: 'actions', sortable: false}
  ];

  headersComposicoes = [
    { text: '', value: 'data-table-expand' },
    { text: 'Base de dados',value: 'origem.nome'},
    { text: 'Código', value: 'codigo' },
    { text: 'Descrição', value: 'descricao' },
    { text: 'Unidade', value: 'unidade.nome' },
    { text: 'Total Coeficiente', value: 'coeficiente' },
    { text: 'Classe', value: 'classe.nome' },
    { text: 'Tipo', value: 'tipo.nome' },
    { text: 'Ações', value: 'actions', sortable: false },
  ];   

   headersComposicoesAdicionadas = [
      { text: '', value: 'data-table-expand' },
      { text: 'Base de dados',value: 'origem.nome'},
      { text: 'Código', value: 'codigo' },
      { text: 'Descrição', value: 'descricao' },
      { text: 'Unidade', value: 'unidade.nome' },
      { text: 'Quantidade', value: 'quantidade' },
      { text: 'Total Coeficiente', value: 'coeficiente' },
      { text: 'Classe', value: 'classe.nome' },
      { text: 'Tipo', value: 'tipo.nome' },
      { text: 'Ações', value: 'actions', sortable: false },
    ]

  etapas = [];
  filterComposicao = {origemId: 0, classeId:0, tipoId:0, unidadeId:0, descricao:'' };

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
  }; 

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation(); 
    } 
  }

  observer! : jsonpatch.Observer<ModeloOrcamentoEtapa>; 

  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  } 
  
  Salvar() {
    if (this.$refs.form.validate()) {
      let pacthModel = jsonpatch.generate(this.observer);
    
      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close(); 
       },
       (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          } 
        }
      )   
    }
  }

  Close() {
    this.dialog = false;
  }

  AdicionarComposicao(item){
    const composicaoExistente = this.composicoesAdicionadas.find(x => x.id === item.id);

    if(composicaoExistente){
      this.$swal("Aviso","Esse item já foi adicionado na lista!","warning");
      return;
    };

    this.composicoesAdicionadas.push(item);
    this.errorAlert = true;
  }

  ExcluirComposicao(item){
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja excluir o registro atual?",
    icon: "question",
    showCancelButton: true, 
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm:true,
    preConfirm:() => {
      if(item.id){
        item.excluido = true;
        const index = this.item.itens.indexOf(item);
        delete this.item.itens[index];
        this.dialog = false;
        this.dialog = true;
        return true;
      }else{
        const index = this.item.itens.indexOf(item);
        this.item.itens.splice(index, 1);
        return this.$swal("Aviso", "Operação realizada com sucesso!", "success");
        } 
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
      }).then((result) => {
      if(result.value){

      }
    })
  }

    totalLista:number = 0;
    loading = false;

    optionsComposicoes: any = {
        itemsPerPage: 15
    };

    composicaoService = new ComposicaoService();
    composicoesPesquisada:any = [];
    composicoesAdicionadas:any = [];

  @Watch("optionsComposicoes", { deep: true })
  async PesquisarComposicao(){
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.optionsComposicoes; 
    this.composicaoService.Listar(page, itemsPerPage, sortBy, sortDesc,search, columns, this.filterComposicao, '', '', 'Origem, Tipo, Unidade, Classe, Itens.Insumo, Itens.Insumo.Tipo, Itens.Insumo.Unidade, Itens.Insumo.Origem').then(
      (res) => {
        this.composicoesPesquisada = res.data.items;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        } 
      }
    );  
  }


    PrepararComposicoes(){
      if(this.composicoesAdicionadas.length == 0){
        this.$swal("Aviso","É obrigatório adicionar os insumos","warning");
        return;
      };
 
        this.composicoesAdicionadas.forEach(item => {
        const itemExistente = this.item.itens.find((etapa:any) => etapa.composicao.descricao === item.descricao);
         
         if(itemExistente){
           console.log('item já adicionado')
          }else{
            let etapaItem:any = new ModeloOrcamentoEtapaItem();
              etapaItem.composicao.codigo = item.codigo;
              etapaItem.composicao.descricao = item.descricao;
              etapaItem.composicao.classeId = item.classeId;
              etapaItem.composicao.ano = item.ano;
              etapaItem.composicao.mes = item.mes;
              etapaItem.composicao.origemId = item.origemId;
              etapaItem.composicao.valorOriginalDesonerado = item.valorDesonerado;
              etapaItem.composicao.valorOriginalNaoDesonerado = item.valorNaoDesonerado;

              etapaItem.composicao.unidadeMedidaId = item.unidadeId;
              etapaItem.quantidade = item.quantidade;
              
            this.item.itens.push(etapaItem);
          } 
        }
      );

        this.composicoesAdicionadas = [];
        this.dialogComposicao = false;
       
    }


    ExcluirComposicaoPesquisado(item){
        let indiceItem = this.composicoesAdicionadas.indexOf(item);
        this.composicoesAdicionadas.splice(indiceItem, 1);
    }

  mounted(){
    const origemService =  new OrigemDadosService();
    origemService.Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id ,nome', '').then(
      (res) => {
        this.listaOrigemDados = res.data.items;
      }
    )

    const unidadeMedidaService = new UnidadeMedidaService();
    unidadeMedidaService.ListarTudo().then(
      (res) => {
        this.unidadeMedidas = res.data.items;
      } 
    )

    const tipoComposicaoService = new TipoComposicaoService();
    tipoComposicaoService.ListarTudo().then(
      (res) => {
        this.tiposComposicao = res.data.items;
      } 
    )

    const classeComposicaoService = new ClasseComposicaoService();
    classeComposicaoService.ListarTudo().then(
      (res) => {
        this.listaClassesComposicao = res.data.items;
      } 
    )
  }
}
