
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Empreendimento,  } from "@/core/models/cadastros";
import { EmpreendimentoService } from "@/core/services/cadastros";


@Component
export default class VisualizarPlano extends Vue {
  @Prop() public item!: Empreendimento;
  @Prop() public value!: string;


  service = new EmpreendimentoService();
  qtdeQuadras = 0;
   
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  
  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;   
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  $refs!: { 
    form: HTMLFormElement;
  }; 

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    } 
  }

  Close() { 
    this.dialog = false;
  }


 
}
