
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ParametroService } from "@/core/services/compras/ParametroService";
import { Parametro } from "@/core/models/compras";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { Empresa } from "@/core/models/cadastros";
import { Almoxarifado } from "@/core/models/almoxarifado";
import { AlmoxarifadoService } from "@/core/services/almoxarifado";
import * as jsonpatch from 'fast-json-patch';

@Component
export default class CadastroParametro extends Vue {
  @Prop() public item!: Parametro;
  @Prop() public value!: string;

  empresas = [] as Empresa[];
  almoxarifados = [] as Almoxarifado[];
  service = new ParametroService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {   formAlmoxarifadoPadrao:HTMLFormElement}
  
  observer! : jsonpatch.Observer<Parametro>; 

  @Watch("item")
  Item(){
    if(this.$refs.formAlmoxarifadoPadrao){
      this.$refs.formAlmoxarifadoPadrao.resetValidation()
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }
  
  Salvar() {
   if (this.$refs.formAlmoxarifadoPadrao.validate()) { 
      let pacthModel = jsonpatch.generate(this.observer);
    
      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
      res => {
        this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close(); 
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
    });
    }
  }

  Close() {
    this.dialog = false; 
  }

  mounted() {
     new EmpresaService().Listar(-1, -1, ['nomeFantasia'],[],'',[], '', '' , 'Id, nome, nomeFantasia', '').then(
      (res) => {
        this.empresas = res.data.items;
      },
    );

   new AlmoxarifadoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      (res) => {
        this.almoxarifados = res.data.items;
      },
    );
  }
}
