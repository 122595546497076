
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {AlmoxarifadoService,ProdutoService,MovimentacaoService} from "@/core/services/almoxarifado/";
import {Almoxarifado,Produto,Transferencia} from "@/core/models/almoxarifado";

@Component
export default class TranferenciaMovimentacao extends Vue {
  @Prop() public item!: Transferencia;
  @Prop() public value!: string;

  service = new MovimentacaoService();
  almoxarifadoItens:any = [] as Almoxarifado[];
  botaoCadastroDesabilitado: boolean = true;

  valid = true;
  dialog = false;
  almoxarifados = [] as any;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  saldo = 0;

  $refs!: {
    formTransferenciaMovimentacao: HTMLFormElement;
  };

  produto: Produto = new Produto();
  onSearchProduto: any = null;
  isProdutoLoading: boolean = false;
  listaProdutos =[];

  
  ObterSaldo(produto){
    if(produto){
      this.saldo = produto.quantidade;
      this.item.produtoId = produto.produtoId;
    }
  }

  @Watch("item")
  Item() {
    if (this.$refs.formTransferenciaMovimentacao) {
      this.$refs.formTransferenciaMovimentacao.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  CarregarProdutos(){
    new AlmoxarifadoService().ObterPorId(this.item.almoxarifadoOrigemId, "Itens.Produto").then(
      (res) => { 
        this.listaProdutos = res.data.itens
        this.botaoCadastroDesabilitado = false; 
       }
      )
  }

  mounted() {
   new AlmoxarifadoService().ListarTudo().then(
      (res) => {
        this.almoxarifados = res.data.items;
      }
    ) 
  }
 
  Transferir() {
    if (this.$refs.formTransferenciaMovimentacao.validate()) {
      if (this.item.quantidade >  this.saldo) {
        this.$swal("Aviso","Atenção, a quantidade a ser transferida não pode ser maior do que o Saldo!","warning");
      }else if (this.item.almoxarifadoOrigemId == this.item.almoxarifadoDestinoId) {
        this.$swal("Aviso","Atenção, O Almoxarifado de Origem não pode ser o mesmo que o Almoxarifado de Destino!","warning");
      } else {
        this.service.Transferir(this.item).then(
          (res) => {
            this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
            this.CarregarProdutos(); 
            this.item.produtoId = 0;
            this.item.quantidade = 0;
            this.saldo = 0;
            this.$refs.formTransferenciaMovimentacao.resetValidation();
          },
          (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
          }); 
      }
  }}
}
