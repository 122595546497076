
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Pedido } from "@/core/models/compras";
import { PedidoService } from "@/core/services/compras/PedidoService";
import { Requisicao,RequisicaoItem} from "@/core/models/almoxarifado";
import { AlmoxarifadoService,RequisicaoService,UnidadeMedidaService} from "@/core/services/almoxarifado";
import { EmpreendimentoService } from "@/core/services/cadastros";
import { EtapaService } from "@/core/services/gestao";

@Component
export default class CadastroPedido extends Vue {
  @Prop() public item!: Pedido;
  @Prop() public value!: string;

  service = new PedidoService();
  serviceRequsicao = new RequisicaoService();

  requisicao = new Requisicao();
  distribuicao = new RequisicaoItem();

  empreendimentos = [];
  almoxarifados = [];
  unidades = [];
  etapas = [];

  tabActive = 0;
  valid = true;
  dialog = false;
  distribuicaoIndex = -1;

  saldo:number =0;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: { formClassificacao: HTMLFormElement };

  rules = {
    NegativoOuZero: (v) => (v > 0) || "A quantidade Requisitada não pode ser igual a zero ou negativo."
  } 

  headersRequisicao: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Etapa", value: "etapaId" },
    { text: "Produto", value: "produtoId" },
    { text: "Unidade", value: "unidadeMedidaId" },
    { text: "Quantidade", value: "quantidade" },
    { text: "Preço", value: "custoMedio" },
  ];

  headers: any[] = [
    { text: "Produtos", value: "produtoId", sortable: false },
    { text: "Qtde Pedida", value: "quantidade" },
    { text: "Qtde Recebida", value: "quantidadeRecebida" },
    { text: "Situação", value: "situacao.nome" },
  ];

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("distribuicao.quantidade")
  Distribuicao() {
    if (this.$refs.formClassificacao) {
      this.$refs.formClassificacao.resetValidation();
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }else{
      this.ObterUsuario();
    }
  }

  ObterUsuario(){
    this.requisicao.solicitante = JSON.parse(localStorage.sessionApp.split(".")).dados.usuarioNome;
  }

  CarregarSaldo(produto){
    if(produto){
      this.distribuicao.produtoId = produto.produtoId;
      this.saldo = produto.produto.saldo;
      this.distribuicao.produto = produto.produto;
      this.distribuicao.unidadeMedidaId = produto.produto.unidadeMedidaId;
      this.distribuicao.custoMedio = produto.produto.precoCustoMedio.toFixed(2);
    }  
  }

  Salvar(executar: boolean = false) {
    if (!this.requisicao.itens[0]) {
      this.$swal("Aviso","Atenção, É necessário que usuário adicione na lista","warning");
    } else {
      this.requisicao.pedidoId = this.item.id;
      this.requisicao.itens.forEach((x) => {x.etapa = undefined});

      this.requisicao.executada = executar;
      this.serviceRequsicao.Salvar(this.requisicao).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
         (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        });
     }
  }

  Close() {
    this.tabActive = 0;
    this.dialog = false;
    this.requisicao = new Requisicao();
  }
 
  Adicionar() {
    if (this.$refs.formClassificacao.validate()) {
      if(this.distribuicao.quantidade > this.saldo ){
        this.$swal("Aviso","A quantidade requisitada não pode ser maior do que o saldo","warning");
        return;
      }
      if (this.distribuicaoIndex > -1) {
        Object.assign(this.requisicao.itens[this.distribuicaoIndex], this.distribuicao)
      } else {
        this.requisicao.itens.push(this.distribuicao)
      }
      this.distribuicao = new RequisicaoItem();
      this.saldo = 0;
      this.distribuicaoIndex = -1;
    }
  }

  Excluir(item: RequisicaoItem) {
    this.$swal({
      title: "Atenção",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
    }).then((result) => {
      if (result.value) {
        const index = this.requisicao.itens.indexOf(item);
        this.requisicao.itens.splice(index, 1);
        this.$swal("Aviso", "Registro excluído com sucesso !", "success");
      }
    });
  }

  Editar(item: RequisicaoItem) {
    this.distribuicaoIndex = this.requisicao.itens.indexOf(item)
    this.distribuicao = Object.assign({}, item)
    this.saldo = this.item.itens.find(x=>x.produtoId == this.distribuicao.produtoId)!.produto!.saldo;
  }

  situacaoColor(id: any) {
  switch (id) {
    case 1:
      return "blue lighten-3";
    case 2:
      return "yellow lighten-3";
    case 3:
      return "teal lighten-3";
    case 4:
      return "lime lighten-3";
    case 5:
      return "red lighten-3";
    default:
      return "";
    }
  } 
 
  mounted() {
    new UnidadeMedidaService().ListarTudo().then(
    res => 
      this.unidades = res.data.items
    );

    new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome', '').then(
    res =>
     this.empreendimentos = res.data.items
    );

    new AlmoxarifadoService().ListarTudo().then(
    res =>
      this.almoxarifados = res.data.items
    );

    new EtapaService().Listagem().then(
      res =>  this.etapas = res.data 
    );
  }
} 
