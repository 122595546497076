
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ReceitaService } from "@/core/services/financeiro";
import { ReceitaParcela } from "@/core/models/financeiro";

@Component
export default class AnteciparContrato extends Vue {
  @Prop() public item!: ReceitaParcela;
  @Prop() public value!: string;

  modelo = new Model();
  receitaService = new ReceitaService();
  valid = true;
  dialog = false;
  overlay = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
  };

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("modelo")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  Close() {
    this.dialog = false;
    this.modelo = new Model();
  }

  parcelas: any = [];

  Salvar() {
    if (this.$refs.form.validate()) {
      this.parcelas = this.item;
      this.overlay = !this.overlay;

      this.parcelas.forEach((x) => {
        this.modelo.parcelaIds.push(x.id);
      });

      this.receitaService.AgruparParcelas(this.item[0].receitaId, this.modelo).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning");
          } else {
            this.$swal("Aviso",err.response.data,err.response.status == 400 ? "warning" : "error");
          }
        }).finally(() => this.overlay = false);
     }
  }
}

class Model {
  public dataVencimento: string = "";
  public parcelaIds: any = [];
  public aplicarEncargos: boolean = true;
}
