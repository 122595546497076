
import { TratarErroApi } from "@/assets/scripts/helper";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { DespesaParcela,SituacaoDespesaParcela} from "../../../core/models/financeiro";
import { DespesaService,SituacaoDespesaParcelaService} from "../../../core/services/financeiro";
import { boleto } from 'boleto-brasileiro-validator';
 
@Component
export default class EditarParcelaDespesa extends Vue {
  @Prop() public item!: DespesaParcela;
  @Prop() public value!: string;

  service = new DespesaService();
  situacoes: SituacaoDespesaParcela[] = [];

  valid = true; 
  dialog = false;
  tabActive = null;
  overlay : boolean = false;
  validaBoleto = [(v) => v.length == 0 || boleto(v) || "Código do boleto inválido!" ]

  $refs!: {
    form: HTMLFormElement;
  };
  
  @Watch("item.linhaDigitavelBoleto")
  Baixa() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  Salvar() { 
    this.overlay = true;

    this.service.EditarParcela(this.item.id, this.item).then(
      (res) => {
        this.$swal("Aviso", "Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close();
      },
      (err) => TratarErroApi(err)
    ).finally(() => this.overlay = false);
  }

  Close() {
    this.dialog = false;
  }
 
  mounted() {
    new SituacaoDespesaParcelaService().ListarTudo().then(
      (res) => {
        this.situacoes = res.data.items;
      },
    );
  }

  close() {
    this.dialog = false;
  }
}
