import { Service } from '../Service';

export class EstadoCivilService extends Service {
   
    constructor(){
        super('estadocivil');   
    }
  }


    