
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { MovimentacaoService,AlmoxarifadoService, ProdutoService,TipoMovimentacaoService} from "@/core/services/almoxarifado/";
import { Movimentacao } from "@/core/models/almoxarifado/";
import { EmpreendimentoService } from "@/core/services/cadastros";
import { EtapaService } from "@/core/services/gestao";

@Component
export default class CadastroMovimentacao extends Vue {
  @Prop() public item!: Movimentacao;
  @Prop() public value!: string;

  listaProdutos = [];

  almoxarifados = [];
  tipos = [];
  empreendimentos = [];
  etapas = [];
  service = new MovimentacaoService();
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    formMovimentacao: HTMLFormElement;
  };

  saldo: number = 0;
  valid = true;          
  dialog = false;

  @Watch("item")
  Item() {
    if (this.$refs.formMovimentacao) {
      this.$refs.formMovimentacao.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  /* Carrega o Almoxarifado de acordo com o Empreendimento dele */

  CarregarAlmoxarifado() {
    new AlmoxarifadoService().ListarTudoFiltro(`empreendimentoId eq ${this.item.empreendimentoId}`)
      .then(
        (res) => {
          this.almoxarifados = res.data.items;
        },
        (err) => this.$swal("Aviso", err.message, "error")
      );
  }


  CarregarProdutos(id){
    new AlmoxarifadoService().ObterPorId(id, "Itens.Produto").then(
      (res) => { 
        this.listaProdutos = res.data.itens;
       }
      )
  }

  ObterSaldo(produto){
    if(produto){
      this.saldo = produto.quantidade;
      this.item.produtoId = produto.produtoId;
    }
  }

  mounted() {
    new ProdutoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome,IdNome, precoCustoMedio', '').then(
    res => {
      this.listaProdutos = res.data.items;
    });

    new EtapaService().Listagem().then(
    (res) => (
      this.etapas = res.data
    ));

    new EmpreendimentoService().ListarTudo().then(
    (res) => {
      this.empreendimentos = res.data.items;
    });

    new AlmoxarifadoService().ListarTudo().then(
    (res) => {
      this.almoxarifados = res.data.items;
    }); 
    
    new TipoMovimentacaoService().ListarTudo().then(
    (res) => {
      this.tipos = res.data.items;
    });  
  }

  Salvar() {
    if (this.$refs.formMovimentacao.validate()) {
      let modelo = this.item;

      this.service.Salvar(modelo).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
        });
    }
  }

  Close() {
    this.dialog = false;
    this.saldo = 0;
  }
}
