
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ContaCorrenteService, FormaPagamentoService, ReceitaService } from "@/core/services/financeiro";
import { ReceitaParcela } from "@/core/models/financeiro";

@Component
export default class AjusteAmortizacao extends Vue { 
  @Prop() public item!: ReceitaParcela;
  @Prop() public value!: string;

  tipoOperacaoId = 1;
  formaPagamentoId = 1;
  contaCorrenteId = 1;

  formasPagamento = [];
  contasCorrente = [];

  receitaService = new ReceitaService();
  valid = true;
  dialog = false;

  tipos = [
    {id:1, nome:'Gerar baixas pela data da parcela'},
    {id:2, nome:'Gerar baixas pela data de hoje'}
 ]
  
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
  }; 

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    } 
  }
  
  Salvar() {
    if (this.$refs.form.validate()) {
    let modelo = this.item;

    this.$swal({
    title: "Atenção!",
    html:"Tem certeza que desejar realizar essa ação! <br> Esta ferramenta efetuará o pagamento de todas as parcelas retroativas a partir da parcela selecionada.",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.receitaService.BaixaRetroativa(modelo.id, this.tipoOperacaoId, this.formaPagamentoId, this.contaCorrenteId).then(
          (res) => {
            if (res.status == 200) {
              this.$emit("salvou");
              this.Close(); 
              return res;
            }
          },
         (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        });
      },
    // @ts-ignore: Unreachable code error
    allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal("Aviso", result.value.data,result.value.status == 200 ? "success" : "warning");
      }
    });
    }
  }

  Close(){
    this.dialog = false;
  }
 
  mounted(){
    new FormaPagamentoService().ListarTudo().then(res => {
      this.formasPagamento = res.data.items;
    })

    new ContaCorrenteService().ListarTudo().then(res =>{
      this.contasCorrente = res.data.items;
    })
  }
}
class Model{

}
