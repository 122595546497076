
import { Contrato, Unidade } from '@/core/models/cadastros';
import { ContratoVistoria, ContratoVistoriaFoto } from '@/core/models/clientes';
import { ContratoService } from '@/core/services/cadastros/ContratoService';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class EmpreendimentoContrato extends Vue {
  @Prop() public item!: Contrato;
  @Prop() public value!: string;

  public service = new ContratoService();
  public contratoVistoriaFoto = new ContratoVistoriaFoto() 
  public contratoVistoria = new ContratoVistoria();
  public unidade: Unidade = new Unidade();
  public dialogCarrosel: boolean = false;
  public dialog:boolean = false;
  public files = []
  public vistoriaIndex = -1;
  
  public tabAtiva: any = null;
  public tabInfraestruturaImovel: any = null;
  
  public headersVistorias: any[] = [
    { text: "", value:"actions", sortable: false },
    { text: "Descrição do Reparo", value: "descricaoReparo" }, 
    { text: "Data", value: "data" },
    { text: "Valor do Reparo", value: "valorReparo" },
    { text: "Exibir Carrosel", value: "exibirCarrosel" },
  ];

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  } 

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  } 

  Salvar() {
    let modelo = this.item
    this.service.Salvar(modelo).then(
      (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close();
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      });
  }

  Close() {
    this.dialog = false
  }

  async AdicionarVistoria(){
    if(this.vistoriaIndex == -1){
    this.item.vistorias.push(this.contratoVistoria)
      if(this.item.vistorias.length != 0){
        for(let i = 0; i < this.files.length; i++){
          let dados = await this.LerArquivo2(this.files[i]);
          this.contratoVistoriaFoto.dados = dados.replace(/^[^,]*,/, "");
          this.contratoVistoria.fotos.push(this.contratoVistoriaFoto)
          this.contratoVistoriaFoto = new ContratoVistoriaFoto();
        }
      }
    }
    this.contratoVistoria = new ContratoVistoria();
    this.vistoriaIndex = -1;
    this.files = [];
  }

  ExcluirVistoria(item:ContratoVistoria){
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja excluir o registro atual?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
  }).then((result) => {
    if (result.value) {
        const index = this.item.vistorias.indexOf(item);
        this.item.vistorias.splice(index, 1);
        this.$swal("Aviso", "Registro excluído com sucesso!", "success");
      }
    }); 
  }

  EditarVistoria(item:ContratoVistoria){
    this.vistoriaIndex  = this.item.vistorias.indexOf(item)
    this.contratoVistoria = item;
  }

  AbrirCarrosel(item:ContratoVistoria){
    this.vistoriaIndex  = this.item.vistorias.indexOf(item)
    this.contratoVistoria = item
    this.dialogCarrosel = true;
  }

  LerArquivo2(file: any): Promise<string> {
    return new Promise((resolve, reject) => {
      let fr = new FileReader();

      fr.onload = (file: any) => {
        resolve(file!.target!.result);
      };

      fr.readAsDataURL(file);
    });
  } 

  mounted() {
   
  }
}
