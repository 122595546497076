
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Contrato, Unidade} from "@/core/models/cadastros";
import { EmpreendimentoService, SituacaoUnidadeService, TipoIndiceService, TipoUnidadeService } from "@/core/services/cadastros";
import { CartorioService } from "@/core/services/imobiliaria";
import { PlanoPagamentoService, TipoAnoInicioReajusteService, TipoIntervaloParcelasService, TipoMesReajusteService, TipoPlanoPagamentoService } from "@/core/services/contratos";
import { IntervaloReajusteService, TipoAmortizacaoService } from "@/core/services/financeiro";
import { PlanoPagamentoModelo } from "@/core/models/contratos";
 
@Component
export default class CadastroMapaInterativo extends Vue {
  @Prop() public item!: Unidade;
  @Prop() public value!: string;
  @Prop() public excluir!:boolean;

  tabAtivaUnidade = 0;
  tabPrazos = 0;
 
  contrato = new Contrato(); 
  service = new EmpreendimentoService();

  listaSituacao = [];
  listaTipos = [];
  listaCartorio = [];
  planosPagamento = [];
  tiposIntervaloParcelas = [];
  inicioContagemReajustesAno = [];
  tiposPlanosPagamento = [];
  inicioContagemReajustesMes = [];
  listaIndices = [];
  tabelas = [];
  tiposIntervalos = [];
  
  dialogLadosAdicionais:boolean = false;
  dialogLadosAdicionaisConfrontantes:boolean = false;
  dialogCadastro:boolean = false;
  overlay:boolean = false; 
  valid:boolean = true;
  dialog:boolean = false;
  
  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("item.planoPagamento.tipoValorTotalId")
  @Watch("item.planoPagamento.valorUnidade")
  CalcularValorFixo(){
    if(this.item.planoPagamento != null && this.item.planoPagamento.tipoValorTotalId == 2){
      this.item.valorVenda = this.item.planoPagamento.valorUnidade;
    } 
  }

  @Watch("item.planoPagamento.tipoValorTotalId")
  @Watch("item.planoPagamento.valorMetroQuadrado")
  CalcularValorMetroQuadrado(){
    if(this.item.planoPagamento != null && this.item.planoPagamento.tipoValorTotalId == 1){
      this.item.valorTotalUnidade = Number(this.item.planoPagamento.valorMetroQuadrado * this.item.lote!.dimensao.areaTotal);
      this.item.valorVenda = this.item.valorTotalUnidade;
    }
  };

  Close() {
    this.dialog = false;
    this.tabAtivaUnidade = 0;
  } 

  GerarContrato(){
    this.contrato = new Contrato();
    this.contrato.unidadePrincipalId = this.item.id;
    this.contrato.tipoId = this.item.tipoId;
    this.contrato.grupoId = this.item.grupoId;
    this.contrato.empreendimentoId = this.item.grupo!.empreendimentoId;
    this.dialogCadastro = true;
  }

  CloseContrato(){
    this.$emit("salvou");
    this.dialog = false;
    this.dialogCadastro = false;
  }

  Salvar(){
    if(this.item.id > 0){
      let modelo = this.item;

      this.service.SalvarUnidade(this.item.id, modelo).then(
        (res) => {
          this.$swal("Aviso", "Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
        },  
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning");
          } else {
            this.$swal("Aviso",err.response.data,err.response.status == 400 ? "warning" : "error");
          }
        }); 
    }
    this.Close();
  }
 
  ExcluirUnidade(item){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      preConfirm:() => { 
       if(item.id > 0){
          this.service.ExcluirUnidade(item.id).then(
            (res) => {
              if (res.status == 200) {
                this.$emit("salvou");
              } 
            },
           (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning");
            } else {
              this.$swal("Aviso",err.response.data,err.response.status == 400 ? "warning" : "error");
            }
          }).finally(() => this.Close());
        }
      },
      //@ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }) 
  }    
 
  get situacoesFiltradas(){
    const situacoesPermitidas = this.listaSituacao.filter(
      (situacao:any) => 
        (this.item.situacaoId == 3 || situacao.id !== 3)
    )  
    return situacoesPermitidas;
  }

  ObterPlanoPagamento(){
    if(this.item.modeloPlanoPagamentoId > 0){
      new PlanoPagamentoService().ObterPorId(this.item.modeloPlanoPagamentoId).then(
        (res) => {
          if(this.item.planoPagamento) {
            const idOriginal = this.item.planoPagamento.id
            this.item.planoPagamento = {...new PlanoPagamentoModelo(res.data), id: idOriginal}; 
          } else {
            this.item.planoPagamento = {...new PlanoPagamentoModelo(res.data), id: 0}; 
          }
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning");
          } else {
            this.$swal("Aviso",err.response.data,err.response.status == 400 ? "warning" : "error");
          }
      }
    )
    }else{
      this.item.planoPagamento = null;
    }
  }

  mounted(){
    new TipoIntervaloParcelasService().ListarTudo().then(
      res => {this.tiposIntervaloParcelas = res.data.items})

    new PlanoPagamentoService().ListarTudo().then(
      res =>{this.planosPagamento = res.data.items}) 

    new SituacaoUnidadeService().ListarTudo().then(
      res => {this.listaSituacao = res.data.items});

    new CartorioService().ListarTudo().then(
      res => {this.listaCartorio = res.data.items});

    new TipoUnidadeService().ListarTudo().then(
      res => {this.listaTipos = res.data.items});

    new TipoAnoInicioReajusteService().ListarTudo().then(
      res => {this.inicioContagemReajustesAno = res.data.items})
 
    new TipoPlanoPagamentoService().ListarTudo().then(
      res => {this.tiposPlanosPagamento = res.data.items})
    
    new TipoMesReajusteService().ListarTudo().then(
      res => {this.inicioContagemReajustesMes = res.data.items})

    new TipoIndiceService().ListarTudo().then(
      res => {this.listaIndices = res.data.items})

    new TipoAmortizacaoService().ListarTudo().then(
      res => {this.tabelas = res.data.items})

    new IntervaloReajusteService().ListarTudo().then(
      res => {this.tiposIntervalos = res.data.items})
  }
}
