

export default class ExecucaoDocumento implements Shared.IEntity{

    public nome : string = '';
    public tamanho : number = 0.00;
    public tipo : string = '';
    public dados : string = '';
    public id : number = 0;
    public execucaoId : number = 0;
    public descricao : string = '';

}