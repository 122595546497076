
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {RequisicaoService,UnidadeMedidaService} from "@/core/services/almoxarifado";
import {Requisicao,RequisicaoItem} from "@/core/models/almoxarifado";
import { EmpreendimentoService } from "@/core/services/cadastros";
import { Empreendimento } from "@/core/models/cadastros";
import { UnidadeMedida } from "@/core/models/geral";

@Component
export default class AtenderRequisicao extends Vue {
  @Prop() public item!: Requisicao;
  @Prop() public value!: string;

  service = new RequisicaoService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  tabActive = null;

  $refs!: {
    formRequisicao: HTMLFormElement;
  };

  unidades = [] as UnidadeMedida[]; 

  headers: any[] = [
    { text: "Etapa", value: "etapa.nome" },
    { text: "Produto", value: "produto.codigoNome" },
    { text: "Unidade", value: "unidadeId" },
    { text: "Qtde", value: "quantidade" },
    { text: "Preço", value: "custoMedio" },
  ];

  requisicaoItem: RequisicaoItem = new RequisicaoItem();
  itemIndex: number = -1;

  empreendimentos = [] as Empreendimento[];

  @Watch("item")
  Item() {
    if (this.$refs.formRequisicao) {
      this.$refs.formRequisicao.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  mounted() {
    new UnidadeMedidaService().ListarTudo().then((res) => {
      this.unidades = res.data.items;
    });

    let empreendimentoService = new EmpreendimentoService();
    empreendimentoService.ListarTudo().then((res) => (this.empreendimentos = res.data.items));
  }

  Imprimir() {
    const conteudo = document.getElementById("section-to-print")!.innerHTML;
    var tela_impressao = window.open("about:blank");
    tela_impressao!.document.write(conteudo);
    tela_impressao!.window.print();
    tela_impressao!.window.close();
  }

  Salvar(executar: boolean = false) {        
    this.item.executada = executar;
    this.service.Salvar(this.item).then(
      (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Imprimir();
        this.Close();
      },
      (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
      });
  }

  Close() {
    this.dialog = false;
  }
}
