
import { Receita, ReceitaParcela } from "@/core/models/financeiro";
import { ReceitaService, SituacaoReceitaParcelaService, TipoServicoReceitaService } from "@/core/services/financeiro";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { SituacaoColor } from '@/assets/scripts/helper';
import { EmpresaService } from "@/core/services/compras/EmpresaService";
 
@Component
export default class EditarParcelaDespesa extends Vue {
  @Prop() public item!: Receita;
  @Prop() public value!: string;

  receita = new Receita();
  parcela = new ReceitaParcela();
  editarParcela = new ReceitaParcela();
  service = new ReceitaService();

  listaSituacaoReceitaParcela = [];
  tiposServicos = [];
  empresas = [];

  valorParcela = 0;
  parcelaIndex = -1; 
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  dialogParcela = false;
  validParcela = true;
  dialog = false;
  valid = true;
   
  headersParcelas = [
    { text: "", value: "actions", sortable: false },
    {text: "Nº", value: "parcela", align: "center", sortable: false },
    {text: "Valor", value: "valor", align: "center", sortable: false },
    {text: "Tipo de serviço", value: "tipoServicoId", align: "center", sortable: false },
    {text: "Situação", value: "situacaoId", align: "center", sortable: false },
    {text: "Vencimento", value: "dataVencimento", align: "center", sortable: false },
  ]

  @Watch("dialog")
    Dialog() {
      if (!this.dialog) { 
        this.$emit("fechou");
      }
  }

  $refs!: {
    form: HTMLFormElement,
    formParcela: HTMLFormElement;
  };

  @Watch("item")
  Item() {
  if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  } 

  @Watch("parcela")
  Parcela() {
  if (this.$refs.formParcela) {
      this.$refs.formParcela.resetValidation();
    }
  } 

  ObterTipoServico(id){
    if(id){
      new TipoServicoReceitaService().ObterPorId(id).then(
        res => {
          this.parcela.valor = res.data.valor;
        }
      )
    }
  } 

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  Validacoes(){
    if(!this.item.empresaId){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      return true;
    }

    if(this.item.parcelas.length == 0){
      this.$swal("Aviso", "É necessário gerar as parcelas de serviços", "warning");
      return true;
    };
  }

  Salvar(item) {
    if(item.id == 0){
      this.$refs.form.validate();
        item.gerarBoletoAutomatico = false;
        item.realizarCobrancaAutomatica = false;
      }

    if (!this.Validacoes()) { 

      item.valor =  item.parcelas.reduce((x,y) =>  this.sum(x,y.valor), 0);
      item.parcelas.forEach((parcela:any) => {
        parcela.situacao = null;
      });

      let modelo = item;
      this.service.Salvar(modelo).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning");
          } else {
            this.$swal("Aviso",err.response.data,err.response.status == 400 ? "warning" : "error");
          }
      });
    }
  }
 
  Close() {
    this.Reset();
    this.dialog = false;
  }
 
  GerarParcelas(){
    if (this.$refs.formParcela.validate()) { 

    for (let i = 1; i <= this.parcela.parcela; i++) {
      let parcela = new ReceitaParcela();
        let dataInicial = new Date(Date.parse(this.parcela.dataVencimento));
        let dataCalculada = dataInicial;
        dataCalculada = new Date(new Date(dataInicial).setMonth(dataInicial.getMonth() + (i - 1)));
         
        parcela.parcela = i; 
        parcela.valor = this.valorParcela;
        parcela.dataVencimento = dataCalculada.yyyymmdd();
        parcela.dataVencimentoOriginal = dataCalculada.yyyymmdd();
        parcela.tipoServicoId = this.parcela.tipoServicoId;
        parcela.tipoGatewayId = this.parcela.tipoGatewayId;
        parcela.contaCorrenteId = this.item.contaCorrenteId;
        parcela.tipoOperacaoId = this.item.tipoOperacaoId;
        parcela.situacaoId = 1;
        this.item.parcelas.push(parcela);
      }
      this.$refs.formParcela.resetValidation();
      this.parcela = new ReceitaParcela();
     }
  }

  EditarParcela(item){
    this.parcelaIndex = this.item.parcelas.indexOf(item);
    this.editarParcela = item;
    this.editarParcela.dataVencimento = new Date(item.dataVencimento).yyyymmdd();
    this.dialogParcela = true;
  } 

  ExcluirParcela(item){
   this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      preConfirm:() => {
         if(item.id > 0){
            this.service.ExcluirParcela(item.id).then(
              (res) => {
                if (res.status == 200) {
                  this.$swal("Aviso", "Registro excluído com sucesso!", "success");
                  this.Atualizar(item);
                }
              },
              (err) => {
                if (!err.response) {
                  this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } else if (err.response.status == 403) {
                  this.$swal("Aviso", err.response.data.message, "warning" );
                } else {
                  this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
                }
              }
            );
        }else{
          const index = this.item.parcelas!.indexOf(item);
          this.item.parcelas!.splice(index, 1);
          return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
      }
    })
  }

  Atualizar(item){
    this.service.ObterPorId(item.receitaId, "Parcelas").then(res=>{
      this.item = res.data;
    })
  }

  Reset(){
    this.receita = new Receita();
    this.parcela = new ReceitaParcela();
    this.valorParcela = 0;
  }
  
  SituacaoColor(item){
    return SituacaoColor(item);
  }

  sum = (a: any, b: any) => parseFloat(a) + parseFloat(b);

  mounted() {
    new TipoServicoReceitaService().ListarTudo().then(res => {
      this.tiposServicos = res.data.items;
    }) 

    new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(res =>{
      this.empresas = res.data.items;
    })

    new SituacaoReceitaParcelaService().ListarTudo().then((res: any) => {
      this.listaSituacaoReceitaParcela = res.data.items;
    }); 
   }
}
