
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class VerTodosVendas extends Vue {
  @Prop() lista?: any[];
  @Prop() private value!: string;
  @Prop() private tituloVerTodos!: string;
  @Prop() private total!: number;
  
  valid = true;
  dialog = false;
  immediate = true

  listaVerTodos?: any[] = []
  totalLista:any = 0;

  headers = [
    { text: 'Ano', value: 'formattedDate',align: 'center'},
    { text: 'Mês', value: 'mes',align: 'center'},
    { text: 'Quantidade', value: 'quantidade', align: 'center',},
  ]

  $refs!: {
    form: HTMLFormElement;
  };

  @Watch("lista", { immediate: true })
  onListaChange(newVal: any[]) {
    this.listaVerTodos = newVal;
  }

  @Watch("value", { immediate: true })
  onValueChange(newVal: string) {
    this.dialog = !!newVal;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  get formattedList() {
    return this.listaVerTodos?.map(item => ({
      ...item,
      formattedDate: this.formatDate(item.dataInteira),
    })).reverse();
  }


  formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    return `${year}`;
  }

  Close() {
    this.dialog = false;
  }

  AtualizarLista(novoValor: any) {
    this.$emit("update:lista", novoValor);
  }
  
  @Watch("lista")
  ObservadorLista(novoValor: any) {
   // this.listaLotes = Object.assign([], this.lista);
  }

  unidades = [];

  mounted(){
    this.listaVerTodos = this.lista;    
  }

}
