
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ReguaCobrancaEtapa, ReguaCobranca } from "@/core/models/regua";
import { ReguaCobrancaService } from "@/core/services/regua";
import * as jsonpatch from 'fast-json-patch';

@Component
export default class CadastroReguaCobranca extends Vue {
  @Prop() public item!: ReguaCobranca;
  @Prop() public value!: string;
  
  etapa:ReguaCobrancaEtapa = new ReguaCobrancaEtapa();

  lista = [] as [];
  service = new ReguaCobrancaService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  etapaindex = -1;

  headers = [
    { text: '',align: 'start',sortable: false,value: 'actions',},
    { text: 'Etapa', value: 'nome' },
    { text: 'Dia A', value: 'minimoDiasVencido' },
    { text: 'Dia B', value: 'maximoDiasVencido' },
  ]

  $refs!: {
    form: HTMLFormElement;
  };

  observer! : jsonpatch.Observer<ReguaCobranca>; 

  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
      
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  Adicionar(){
    if(this.etapaindex ==-1){
      this.item.etapas.push(this.etapa)
    }
    this.etapa = new ReguaCobrancaEtapa();
    this.etapaindex = -1;
  }

  Editar(item) {
    this.etapaindex = item;
    this.etapa = item;
  }

  Excluir(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        if(item.id > 0){
             this.service.ExcluirEtapa(item.id).then(
              (res) => {
                if (res.status == 200) {
                  this.$swal("Aviso", "Registro excluído com sucesso!", "success");
                  this.Atualizar();
                }
              },
            );
        }else{
           console.log("caiu 2")
            const index = this.item.etapas!.indexOf(item);
            this.item.etapas.splice(index, 1);
            this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        }
      },
     // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    })
  }

  @Watch("item", {deep:true})
  Item(){
    this.observer = jsonpatch.observe(this.item);
  };

  Salvar() {
    if (this.$refs.form.validate()) {

      this.observer = jsonpatch.observe(this.item);
      let pacthModel = jsonpatch.generate(this.observer);

      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");

          if(this.item.id > 0){
            this.Atualizar();
          }else{
            this.Close(); 
          }
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        });
    }
  }

  Close() {
    this.dialog = false;
  }
 
  Atualizar(){
    jsonpatch.unobserve(this.item, this.observer);

    this.service.ObterPorId(this.item.id, 'Etapas').then(res => {
      this.item = res.data;
    })
  }
}
