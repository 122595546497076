import { http } from '@/core/api';
import { Service } from '../Service';

export class AdiantamentoCarteiraService extends Service {
    constructor(){
        super('adiantamentocarteira');
    }


    ObterSimulacao(valor){
        return http.get(`${this.GetNomeControle()}/ListaSimulacaoAdiantamentoCarteira/${valor}`)
    }
}