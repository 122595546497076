
import { EmpreendimentoService } from '@/core/services/cadastros';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import * as jsonpatch from 'fast-json-patch';
import { DocumentoEmpreendimento, Empreendimento } from '@/core/models/cadastros';
import { ArquivoService } from '@/core/services/geral/ArquivoService';

@Component
export default class VCardDocumento extends Vue {
  @Prop() empreendimentoDocumento!:Empreendimento;
  @Prop() loading;
  

  service = new EmpreendimentoService();
  documento: DocumentoEmpreendimento = new DocumentoEmpreendimento();

  inputDocumento: any = null;
  dialog = false;

  headersDocumentos: any[] = [ 
    { text: "", value: "actions", sortable: false },
    { text: "Nome", value: "nome", align: "left",sortable: false },
    { text: "Documentos", value: "anexar", align: "left",sortable: false },
  ]; 

  @Watch("empreendimentoDocumento", {deep:true})
  ObservadorItem(){
    this.observer = jsonpatch.observe(this.empreendimentoDocumento);
  }; 
  
  options: any = {
    itemsPerPage: 5
  };

  observer! : jsonpatch.Observer<Empreendimento>;
  overlayDocumentos = false;
  loadingArquivo = false;
  selecionarArquivo:any = null;
  index = -1;

  $refs!: {
    uploader
  };

  Upload(item) {
    this.index =  this.empreendimentoDocumento.documentos!.indexOf(item);
    this.loadingArquivo = true
    window.addEventListener('focus', () => {
      this.loadingArquivo = false
    }, { once: true,passive: true });

    this.$refs.uploader.click();
  };

  async EnviarArquivo(e) {
    this.selecionarArquivo = e.target.files[0];
    let dados = await new ArquivoService().Ler(this.selecionarArquivo);
    this.empreendimentoDocumento.documentos[this.index].dados =  dados.replace(/^[^,]*,/, "");
    this.empreendimentoDocumento.documentos[this.index].tipo =  this.selecionarArquivo.type;
    this.index = -1;

    this.SalvarDocumento();
  }

  AdicionarArquivo() {
    if (this.index > -1) {
      Object.assign(this.empreendimentoDocumento.documentos[this.index], this.documento);
    } else {
      this.documento.nome = "Novo documento";
      this.documento.usuarioId = JSON.parse(localStorage.sessionApp.split(".")).dados.usuarioId;
      this.empreendimentoDocumento.documentos.push(this.documento);
      this.AtualizarLista(this.empreendimentoDocumento.documentos);
    }
    this.documento = new DocumentoEmpreendimento();
    this.inputDocumento = null;
    this.index = -1;
  }

  CompartilharArquivo(item){
    new ArquivoService().Compartilhar(item);
  }

  ExcluirDocumento(documento:any) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      preConfirm:() => {
        if(documento.id > 0){
         documento.excluido = true;
         const index = this.empreendimentoDocumento.documentos!.indexOf(documento);
         delete this.empreendimentoDocumento.documentos![index]
         this.dialog = false;
         this.dialog = true;
         return true; 
        }else{
          const index = this.empreendimentoDocumento.documentos!.indexOf(documento);
          this.empreendimentoDocumento.documentos!.splice(index, 1);
          return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
        this.SalvarDocumento();
      }
    })
  }

  SalvarDocumento(){
    if(this.empreendimentoDocumento.id > 0){

      this.observer = jsonpatch.observe(this.empreendimentoDocumento);
      let pacthModel = jsonpatch.generate(this.observer);

      this.service.Patch(pacthModel, this.empreendimentoDocumento.id).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");

          jsonpatch.unobserve(this.empreendimentoDocumento, this.observer);
          this.GetDocumentos();
      },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
      });
    }
  }

  GetDocumentos(){
    this.overlayDocumentos = true;

    this.service.ObterPorId(this.empreendimentoDocumento.id, "Documentos").then((res) => {
      this.empreendimentoDocumento = res.data;
      this.overlayDocumentos = false;
    }); 
  }

  BaixarArquivo(arquivo){
    new ArquivoService().Download(arquivo);
  }

  AtualizarLista(novoValor: any){ 
    this.$emit('update:lista', novoValor);
  }
  
  mounted(){
  }
}
