
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';
import { QualidadeAgua } from "@/core/models/agua";
import { QualidadeAguaService } from "@/core/services/agua";
import { ArquivoService } from "@/core/services/geral/ArquivoService";
import { Documento } from "@/core/models/geral";

@Component
export default class CadastroQualidadeAgua extends Vue {
  @Prop() public item!: QualidadeAgua;
  @Prop() public value!: string;

  service = new QualidadeAguaService();
  valid = true;
  dialog = false;
  arquivo:any = null;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
  }; 

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation(); 
    } 
  }

  observer! : jsonpatch.Observer<QualidadeAgua>; 

  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  } 
  
  Salvar() {
    if (this.$refs.form.validate()) {
      let pacthModel = jsonpatch.generate(this.observer);
    
      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close(); 
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        } 
      })
    }
  }

  Close() {
    this.dialog = false;
  }
 
  async AnexarArquivo(){
    if(!this.arquivo)
      return;
    
    let dados = await new ArquivoService().Ler(this.arquivo);
    //this.item.documento = new Documento();
    this.item.documento.usuarioId = JSON.parse(localStorage.sessionApp.split(".")).dados.usuarioId;
    this.item.documento.dados = dados.replace(/^[^,]*,/, "");
    this.item.documento.nome = this.arquivo.name;
    this.item.documento.tamanho = this.arquivo.size;
    this.item.documento.tipo = this.arquivo.type;    
  }
  
  RemoverArquivo(){
    this.arquivo = null;
    this.item.documento = new Documento();
  }

  mounted(){
 
  }
}
