
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Municipio, Estado } from "../../../core/models/geral";
import ExternalServices from "@/core/services/geral/ExternalServices";
import { AssinanteService } from "@/core/services/cadastros";
import { Assinante, AssinanteContato, AssinanteDominio } from "@/core/models/cadastros";

@Component
export default class DadosAssinante extends Vue {
  @Prop() public assinante!: Assinante;
  @Prop() public value!: string;

  service = new AssinanteService();
  contatoPessoa = new AssinanteContato();
  dominioPrincipal = new AssinanteDominio();
 
  contatoIndex: number = -1;
  dominioIndex: number = -1;
  readonly: boolean = true;
  validContato = true
  valid = true;
  dialog = false;
  tabActive = null;
  tab = null

  
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  headersDominio: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Nome", value: "nome" },
    { text: "Principal", value: "principal" },
  ];

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Nome", value: "nome" },
    { text: "Telefone", value: "telefone" },
    { text: "Celular", value: "celular" },
    { text: "Ramal", value: "ramal" },
    { text: "Email", value: "email" },
    { text: "Observaçao", value: "observacao" },
    { text: "Principal", value: "principal" },
  ];
  
 
  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  $refs!: {
    form: HTMLFormElement;
    formContato:HTMLFormElement
    formDominio:HTMLFormElement
  };

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("contatoPessoa")
  ContatoPessoa() {
    if (this.$refs.formContato) {
      this.$refs.formContato.resetValidation();
    }
  }

  @Watch("dominioPrincipal")
  DominioPrincipal() {
    if (this.$refs.formDominio) {
      this.$refs.formDominio.resetValidation();
    }
  }

  async PesquisaCep() {
    if (this.assinante.endereco.cep.length == 8) {
      let endereco: any = await new ExternalServices().PesquisaCep(
        this.assinante.endereco.cep
      );
      endereco
        ? (this.assinante.endereco = endereco)
        : this.$swal("Aviso", "CEP inválido!", "error");
      endereco ? (this.readonly = true) : (this.readonly = false);
    }
  }

  //DOMINIO PRINCIPAL
  AdicionarDominio(){
     if (this.$refs.formDominio.validate()) {
      if (this.dominioIndex == -1) {
        this.assinante.dominios.push(this.dominioPrincipal);
      }
      this.dominioPrincipal = new AssinanteDominio();
      this.dominioIndex = -1;
    }
  }

  EditarDominio(item: AssinanteDominio) {
    if (this.dominioIndex != -1) {
      this.$swal("Atenção!","Não é possível editar um registro sem finalizar uma edição já em andamento.","error");
      return;
    }
    this.dominioPrincipal = item;
    this.dominioIndex = this.assinante.dominios.indexOf(item);
    //this.item.contatos = this.item.contatos.filter(f => f.id != item.id);
  }

  ExcluirDominio(item: AssinanteDominio) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
    }).then((result) => {
      if (result.value) {
        const index = this.assinante.dominios.indexOf(item);
        this.assinante.dominios.splice(index, 1);
        this.$swal("Aviso", "Contato excluído com sucesso!", "success");
      }
    });
  }

//CONTATO PRINCIPAL
 AdicionarContato() {
    if (this.$refs.formContato.validate()) {
      if (this.contatoIndex == -1) {
        this.assinante.contatos.push(this.contatoPessoa);
      }
      this.contatoPessoa = new AssinanteContato();
      this.contatoIndex = -1;
    }
  }

  Editar(item: AssinanteContato) {
    if (this.contatoIndex != -1) {
      this.$swal("Atenção!","Não é possível editar um registro sem finalizar uma edição já em andamento.","error");
      return;
    }
    this.contatoPessoa = item;
    this.contatoIndex = this.assinante.contatos.indexOf(item);
  }

  Excluir(item: AssinanteContato) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
    }).then((result) => {
      if (result.value) {
        const index = this.assinante.contatos.indexOf(item);
        this.assinante.contatos.splice(index, 1);
        this.$swal("Aviso", "Contato excluído com sucesso!", "success");
      }
    });
  }

  Salvar() {
    if (this.$refs.form.validate()) {
      let modelo = this.assinante;

      this.service.Salvar(modelo).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
        });
    }
  }

  Close() {
    this.dialog = false;
  }
}
