
import { InsumoEstado } from "@/core/models/orcamentoObras";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class VCardValoresUF extends Vue {
  @Prop() item?:InsumoEstado;

  mounted() {
    
  }
}
