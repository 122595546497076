
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Pedido,PedidoItem,ReceberPedidoItemModel} from "@/core/models/compras";
import { PedidoService } from "@/core/services/compras/PedidoService";
import { DespesaService } from "@/core/services/financeiro";
import { AlmoxarifadoService, GrupoProdutoService,ProdutoService} from "@/core/services/almoxarifado";
import { SituacaoPedidoItemService } from "@/core/services/compras/SituacaoPedidoItemService";
import * as jsonpatch from 'fast-json-patch';

@Component
export default class ReceberPedido extends Vue {
  @Prop() public item!: Pedido;
  @Prop() public value!: string;

  service = new PedidoService();
  serviceDespesa = new DespesaService();

  itemPedido = new PedidoItem();
  modelo = new ReceberPedidoItemModel();
  pedidoIndex = -1;

  produtos = []; 
  situacoesPedido = [];
  selected:any = [];
  grupos = [];
  almoxarifados:any =[]

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: { formReceber: HTMLFormElement; formProdutos: HTMLFormElement };

  tabActive = null;
  valid = true;
  dialog = false;
  
  loading: boolean = false;

  options: any = {
    itemsPerPage: 15
  };  

  EstoqueMinimo: number = 0;
  EstoqueCritico: number = 0;
  ultimaCompraPreco: number = 0;
  Saldo: number = 0;
  valorTotal: number = 0;
  contador: number = 0;

  observer! : jsonpatch.Observer<Pedido>;
  quantidadeModel: number = 0;

  save(item: PedidoItem) {
    this.modelo.id = item.id;
    this.modelo.quantidade = this.quantidadeModel;
    this.modelo.almoxarifadoId = this.almoxarifados[0].id;

    this.service.ReceberPedido(this.modelo.id, this.modelo).then(
      (res) => {
        item.quantidadeAReceber = item.quantidadeAReceber - this.quantidadeModel;
        item.quantidade = item.quantidade;
        this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
         this.AtualizarPedido(this.item)
      },
      (err) => {
        if (err.response.status == 400) {
          this.$swal(
            "Aviso",
            "A quantidade informada é maior do que o saldo a receber!",
            "warning"
          );
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      });
  }

  /* cabeçalho das tabelas */
  headers: any[] = [
    { text: "Nome", value: "produtoId", sortable: false, align:'center' },
    { text: "Qtde Pedida", value: "quantidade",  align:'center' },
    { text: "Qtde Recebida", value: "quantidadeRecebida",  align:'center' },
    { text: "Qtde à Receber", value: "quantidadeAReceber",  align:'center' },
    { text: "Situação", value: "situacaoId",  align:'center' },
  ];

  headersPedido = [
    { text: "", value: "actions", sortable: false },
    { text: "Nome", value: "produtoId" },
    { text: "Qtde Pedida", value: "quantidade" },
    { text: "Preço Unitário", value: "valorUnitario" },
    { text: "Valor", value: "total" },
  ];

  @Watch("modelo")
  Modelo() {
    if (this.$refs.formReceber) {
      this.$refs.formReceber.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("itemPedido")
  ItemPedido() {
    if (this.$refs.formProdutos) {
      this.$refs.formProdutos.resetValidation();
    }
  }
 
  AtualizarPedido(item:Pedido){
    let servicePedido = new PedidoService;
      servicePedido.ObterPorId(this.item.id,'Orcamento,Classificacoes,Orcamento.Itens,Itens.Situacao, Orcamento.Classificacao,Fornecedor,Tipo, Empresa').then(
      res => this.item = res.data
    )}

   Salvar() {
    this.item.itens.forEach((x) => {
      x.produto = undefined;
      x.situacao = undefined;
    });

    let modelo = this.item;
    this.service.Salvar(modelo).then(
      (res) => {
        this.$swal("Aviso", "Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
        this.AtualizarPedido(this.item)
    },
     (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      });
  }

  ReceberTodos() {
    if (!this.selected[0]) {
      this.$swal("Aviso","É necessário que o usuário selecione um ou mais itens","warning");
    } else {
      if (this.selected.filter((x) => x.quantidadeAReceber === 0)[0]) {
        this.$swal("Aviso","Um dos itens selecionados já foi recebido","warning");
      } else if (this.selected.map((x) => x.quantidadeAReceber != 0)) {
        this.$swal({
          title: "Atenção",
          text: "Deseja realmente receber os itens selecionados?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Receber",
          cancelButtonText: "Cancelar",
          showCloseButton: false,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            for (this.contador = 0; this.contador < this.selected.length; this.contador++) {
              this.modelo = new ReceberPedidoItemModel();
              this.modelo.id = this.selected.filter((x) => x.id)[this.contador].id;
              this.modelo.almoxarifadoId = this.almoxarifados[0].id;
              this.modelo.quantidade = this.selected.filter((x) => x.quantidadeAReceber)[this.contador].quantidadeAReceber;

              this.service.ReceberPedido(this.modelo.id, this.modelo).then(
                (res) => {
                  this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
                  this.$emit("salvou");
                },
                (err) => {
                  if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                  } else if (err.response.status == 403) {
                    this.$swal("Aviso", err.response.data.message, "warning" );
                  } else {
                    this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
                  }
                });
              }
            this.Close();
           }
        });
     }}
  }

  Close() {
    this.modelo = new ReceberPedidoItemModel();
    this.selected = [];
    this.dialog = false;
  }

  AdicionarProdutos(){
     if (this.pedidoIndex > -1) {
      Object.assign(this.item.itens[this.pedidoIndex], this.itemPedido)
    } else {
      this.item.itens.push(this.itemPedido);
    }
    this.EstoqueMinimo = 0;
    this.EstoqueCritico = 0;
    this.ultimaCompraPreco = 0;
    this.Saldo = 0;

    this.itemPedido = new PedidoItem();
    this.pedidoIndex = -1
  }

  EditarProduto(item: PedidoItem) {
    this.pedidoIndex = this.item.itens.indexOf(item);
    this.itemPedido = item;
  }
 
  ExcluirProduto(item: PedidoItem) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
    }).then((result) => {
      if (result.value) {
        const index = this.item.itens.indexOf(item);
        this.item.itens.splice(index, 1);
        this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        let pacthModel = jsonpatch.generate(this.observer);
        if(this.item.id > 0)
        this.service.Patch(pacthModel, this.item.id).then(
        (res) => {
          this.$emit("salvou");
        })
      }
    });
  }

  situacaoColor(id: any) {
    switch (id) {
      case 1:
        return "blue lighten-3";
      case 2:
        return "yellow lighten-3";
      case 3:
        return "teal lighten-3";
      case 4:
        return "lime lighten-3";
      case 5:
        return "red lighten-3";
      default:
        return "";
    }
  }

  CarregarProduto() {
   new ProdutoService().ListarTudoFiltro(`grupoId eq ${this.itemPedido.grupoId}`).then(
      (res) => {
        this.produtos = res.data.items;
      },
    );
  }

  mounted() {
    new AlmoxarifadoService().ListarTudo().then(
      res => {this.almoxarifados = res.data.items}
    )
    new GrupoProdutoService().ListarTudo().then(
    (res) => {
      this.grupos = res.data.items
      },
    );

    new ProdutoService().ListarTudo().then(
      (res) => {
        this.produtos = res.data.items;
      },
    );

    new SituacaoPedidoItemService().ListarTudo().then(
      (res) => {
        this.situacoesPedido = res.data.items;
      },
    );
  }
}
