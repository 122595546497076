
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ReceitaParcela} from "../../../core/models/financeiro";
import { ReceitaService} from "../../../core/services/financeiro";

@Component
export default class ParcelaDetalheReceita extends Vue {
  @Prop() public item!: ReceitaParcela;
  @Prop() public value!: string;

  service = new ReceitaService();

  valid = true;
  dialog = false;
  loading = false;
  tabActive = null;
  tab = null;
 
  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }
   
  headers = [
    { text: "Data da Baixa", value: "dataPagamento" },
    { text: "Forma de pagamento", value: "formaPagamento.nome" },
    { text: "Valor", value: "valor" },
    { text: "Encargos", value: "juros" },
    { text: "Multa", value: "multa" },
    { text: "Desconto", value: "desconto" },
    { text: "Total", value: "total" },
    { text: "Automática", value: "automatica" },
    { text: "Cancelado", value: "cancelado" },
  ];
 
  headersClassificacao = [
    { text: "", value: "actions", sortable: false },
    { text: "Centro Custo", value: "centroCusto.nome" },
    { text: "Conta Gerencial", value: "contaGerencial.nome" },
    { text: "Valor", value: "valor" },
    { text: "Percentual", value: "percentual" },
  ];

  Close() {
    this.dialog = false;
  }
 
}
