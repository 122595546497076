import { Service } from '../Service';

export class SituacaoFreteService extends Service {
    constructor(){
        super('situacaofrete');   
    }
  }
  


    