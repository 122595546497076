
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Tinymce from '@tinymce/tinymce-vue';
import { Contrato, ContratoDigital, SignatarioContratoDigital } from "@/core/models/cadastros";
import { ContratoService, ModeloContratoService, PessoaService, TipoAssinaturaService, TipoContratoDigitalService } from "@/core/services/cadastros";

@Component({
  components: {
    Editor:Tinymce 
  }
})

export default class AnteciparContrato extends Vue {
  @Prop() public item!: Contrato;
  @Prop() public contratoDigital!: ContratoDigital;
  @Prop() public value!: string;
  
  service = new ContratoService();
  signatario = new SignatarioContratoDigital();

  modelos = []; 
  tipos = [];
  tiposAssinatura = [];
  listaPessoas = [];
  listaCombinada = [{id:0, nome:""}];

  headers = [
    { text: "", value: "actions", sortable: false },
    { text: 'Cliente', value: 'clienteId' },
    { text: 'E-mail', value: 'email' },
    { text: 'Tipo', value: 'tipoAssinaturaId' },
  ];

  overlay = false;

  modeloId = 0;
  emailIndex: number = -1;
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
    formEmail:HTMLFormElement;
  };

  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false; 
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }else{
      this.listaCombinada = [
        ...this.item.empreendimento!.proprietarios.map((x:any) => ({ id: x.proprietarioId, nome: x.proprietario.nome })),
        ...this.item.corretores.map((x:any) => ({ id: x.corretorId, nome: x.corretor.nome })),
        ...this.item.clientes.map((x:any) => ({ id: x.clienteId, nome: x.cliente.nome }))
      ]; 
    }
  }

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("contratoDigital")
  ContratoDigital() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  } 

  Close() {
    this.dialog = false;
    this.modeloId = 0;
  } 

  Validacoes(){
    if(!this.contratoDigital.nome){
      this.$swal("Aviso", "O campo nome é obrigatório.", "warning");
      return true; 
    }

    if(!this.contratoDigital.tipoId){
      this.$swal("Aviso", "O campo tipo de contrato é obrigatório.", "warning");
      return true; 
    }

    if(!this.contratoDigital.conteudoEditavel){
      this.$swal("Aviso", "É necessário preencher o conteúdo no editor.", "warning");
      return true; 
    }
  }
 
  Salvar() {
    this.$refs.form.validate();
      if (!this.Validacoes()) { 

      this.item.clientes.forEach(x => x.cliente = undefined);
      this.item.corretores.forEach(x=>x.corretor = undefined);
      this.item.empreendimento!.proprietarios.forEach((x:any) => x.proprietario = undefined);

      if(!this.contratoDigital.id){
        this.item.contratosDigitais.push(this.contratoDigital);
      };
        
      this.contratoDigital.signatarios.forEach((x:any) => x.situacao = undefined);
       (this.contratoDigital.id > 0 ? this.service.AlterarContratoDigital(this.contratoDigital.id, this.contratoDigital) : this.service.Salvar(this.item)).then(
        (res) => {        
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");        
          this.Close(); 
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        });
      }
  }

  ObterPessoa(id){
    new PessoaService().ObterPorId(id).then(
      res => {
        this.signatario.email = res.data.email;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
  }
  
  async ObterModeloPorId(id) {
    let documentoModel = { documento:""}

    if (id > 0) {
      const res = await new ModeloContratoService().ObterPorId(id);
      documentoModel.documento = res.data.conteudo;

      await new ContratoService().MontarDocumentoContratoDigital(this.item.id, documentoModel).then(
        (res) => {
          this.contratoDigital.conteudoEditavel = res.data;
        }, 
        (err) => {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      )
     }
  }

  AdicionarClienteEmail(){
    if (this.$refs.formEmail.validate()) {
      if (this.emailIndex > -1) {
        Object.assign(this.contratoDigital.signatarios[this.emailIndex], this.signatario);
      } else {
        this.contratoDigital.signatarios.push(this.signatario);
      }
      this.emailIndex = -1;
      this.signatario = new SignatarioContratoDigital();
      this.$refs.formEmail.resetValidation();
    }
  }

  EditarEmailCliente(item){
    if (this.emailIndex != -1) {
      this.$swal("Atenção!","Não é possível editar um registro sem finalizar uma edição já em andamento.","error");
      return;
    }
    this.signatario = Object.assign({}, item);
    this.emailIndex = this.contratoDigital.signatarios.indexOf(item);
  } 

  ExcluirEmailCliente(item){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true, 
    }).then((result) => {
      if(item.id > 0){
        this.service.ExcluirSignatario(item.id).then();
      } 
      const index = this.contratoDigital.signatarios!.indexOf(item);
      this.contratoDigital.signatarios.splice(index, 1);
      this.$swal("Aviso", "Registro excluído com sucesso!", "success");
    });
  }

  editorConfig = {
    allow_script_urls: true,
    selector: 'textarea',
    isTyping: false,
    plugins: 'advlist autolink charmap code codesample directionality emoticons fullscreen help hr image insertdatetime link lists media nonbreaking pagebreak paste preview print save searchreplace table template textpattern visualblocks visualchars wordcount',
    toolbar: 'fontselect fontsizeselect formatselect | bold italic underline strikethrough forecolor backcolor | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link table removeformat code',
    height: 800,
    menubar: true,
    language: 'pt_BR',
    image_advtab: true,
    branding: false,
  };

 //resetEditor() {
    // Função para redefinir o conteúdo do editor
   // this.contratoDigital.conteudoEditavel = ''; // Define o valor do v-model como vazio
 // }
    
 //beforeDestroy() {
    // Remove o listener do evento beforeunload antes do componente ser destruído
   // window.removeEventListener('beforeunload', this.resetEditor);
 // }
 

  mounted(){
    //window.addEventListener('beforeunload', this.resetEditor);
    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,cnpjCpf, nomeCnpjCpf, Email', '').then(res => {
      this.listaPessoas = res.data.items;
    })

    new TipoAssinaturaService().ListarTudo().then(res => {
      this.tiposAssinatura = res.data.items;
    })
    
    new TipoContratoDigitalService().ListarTudo().then(res => {
      this.tipos = res.data.items;
    })

    new ModeloContratoService().ListarTudo().then(res => {
      this.modelos = res.data.items;
    })
  }
}
