
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Chart, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, BarController } from 'chart.js';
import { nextTick } from 'vue';

Chart.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, BarController);

@Component
export default class GraficoBarraComponent extends Vue {
  @Prop({ required: true }) chartData!: any;
  @Prop({ required: false }) options!: any;
  @Prop({ required: false, default: false }) isMobile!: boolean;
  @Prop({ required: true }) formatType!: string;
  @Prop({ required: false }) width!: string;
  @Prop({ required: false }) height!: string;
  private isChartReady: boolean = false;

  private chartInstance: any = null;

  mounted() {
    nextTick(() => {
      this.isChartReady = true;  // Now the canvas is ready
      this.renderChart();
    });
    }
   
    beforeDestroy() {
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }
    }

  @Watch('chartData', { immediate: true, deep: true })
  @Watch('options', { immediate: true, deep: true })
  @Watch('formatType')
  onDataChange() {
    if (this.isChartReady) {
      this.renderChart();
    }
  }

  private formatValue(value: any): string {
  // Verifique se o valor é um número
  const numValue = Array.isArray(value) ? value[0] : value; // Caso o valor seja um array, pegue o primeiro item
  if (typeof numValue === 'number') {
    if (this.formatType === 'currency') {
      return 'R$ ' + numValue.toFixed(2); // Formato moeda
    } else {
      return numValue.toString(); // Formato número inteiro
    }
  }
  return value.toString(); // Caso não seja um número, retorna como string
}

  private renderChart() {
    if (this.chartInstance) {
      this.chartInstance.destroy();
    }

    const canvas = this.$refs.barChart as HTMLCanvasElement | undefined;

    if (canvas && canvas.getContext) {
      const ctx:any = canvas.getContext('2d');
    
    this.chartInstance = new Chart(ctx, {
      type: 'bar',
      data: this.chartData,
      options: {
        ...this.options,
        responsive: true,
        indexAxis: this.isMobile ? 'y' : 'x',
        scales:this.isMobile ? {
          x: {
            beginAtZero: true,
            ticks: {
              callback: (value) => {
                return this.formatValue(value as number);
              }
            }
          }
        } : {
          y: {
            beginAtZero: true,
            ticks: {
              callback: (value) => {
                return this.formatValue(value as number);
              }
            }
          }
        },
        plugins: {
          legend: {
            position: this.isMobile ? 'top' : 'left',
            labels: {
              usePointStyle: true,
              boxWidth: 50,
              padding: 15,
              font: {
                    size: 13
                  }
            },
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                let label = tooltipItem.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                const value = Array.isArray(tooltipItem.raw) ? tooltipItem.raw[0] : tooltipItem.raw;
                label += this.formatValue(value);
                return label;
              }
            }
          }
        }
      },
    });
   }

  }
}
