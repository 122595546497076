
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';
import { ContratoService, EmpreendimentoService, TipoProcessoJudicialService } from "@/core/services/cadastros";
import { Contrato, DocumentoContrato, EnumTipoOperacaoFinanceira, TipoProcessoJudicial, Unidade } from "@/core/models/cadastros";
import { Receita, ReceitaParcela,EnumTipoReceita, AnteciparDocumento} from "@/core/models/financeiro";
import { ReceitaService, SituacaoReceitaParcelaService } from "@/core/services/financeiro";
import { SituacaoColor, FormatarNumeroTelefone, TratarErroApi, ConstruirQueryParams } from '@/assets/scripts/helper';
import { Documento } from "@/core/models/geral";
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ArquivoService } from "@/core/services/geral/ArquivoService";
 
@Component
export default class CadastroContrato extends Vue {
  @Prop() public item!: Contrato;
  @Prop() public clienteNome?:string;
  @Prop() public value!: string;

  comprovante = new Documento();
  receitaAgruparAntecipar = new Receita();
  receitaConfig = new Receita();
  simulacao = new Receita();
  gerenciador = new Contrato();
  contratoDocumento = new Contrato();
  service = new ContratoService();
  receitaService = new ReceitaService();
  serviceTipoProcesso = new TipoProcessoJudicialService();
  informacaoUnidade = new Unidade()
  inputDocumento:any = null;
  documento = new DocumentoContrato(); 
  contrato = new Contrato();

  items = [
    {icone:"mdi-cash-multiple", titulo:"Parcelas de Serviços", click:this.GerarServico},
    {icone:"mdi-clipboard-text-outline", titulo:"Informação do Contrato", click:this.ObterContrato},
    {icone:"mdi-clipboard-text-search-outline", titulo:"Histórico do Contrato", click:this.ObterHistorico},
    {icone:"mdi-account-circle", titulo:"Informação do Cliente", click:this.ObterPessoa},
    {icone:"mdi-grid", titulo:"Informação do Lote", click:this.ObterUnidade},
  ];

  parcela = new ReceitaParcela();
  tipoProcesso = new TipoProcessoJudicial();
  receitaGerenciada = new Receita();
  encargosEDescontos = new Receita();
  receitaServico:any = new Receita();
  contratoQuitacao = new Receita();

  parcelaBaixaRetroativa = new ReceitaParcela();
  antecipacao = new AnteciparDocumento();
  detalharPessoa = new Contrato(); 
  unidadeItem = new Contrato(); 
  detalhar = new Contrato();
  
  listaTipoProcessos: [] = [];
  listaSituacao = [];

  dialogHistorico:boolean = false;
  contratoHistorico = new Contrato();
 
  overlayDocumentos = false;
  ativarEditorUnidades = false; 
  dialogDetalhar = false;
  dialogParcela = false;
  dialogAjusteAmortizacao = false;
  dialogEncargosDescontos = false;
  dialogDespesaContratual = false;
  dialogDetalharPessoa = false;
  dialogAgruparAntecipar = false;
  dialogProcesso = false;
  dialogConfigurarReceita = false;
  dialogSimular = false;
  loading = false; 
  dialogBaixasRetroativa = false;
  dialogBaixar = false;
  dialogQuitarContrato = false;
  dialogCadastroSignatarios = false;
  dialogUnidadeInformacao = false;
  dialogMultiLotes = false;

  dialogDataSequencia = false;
  dialogGerencial = false;
  dialog = false; 
  overlay = false; 
  overlay2 = false;

  tabActive: any = null;
  subTabActive = null;
  page = 1; 
  itemsPerPage = 4;

  snack = false;
  snackColor = "";
  snackText = "";
  
  options: any = {
    itemsPerPage: -1
  };

  optionsDocumentos = {
    itemsPerPage: 5
  };
 
  observer! : jsonpatch.Observer<Contrato>; 

  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false; 
      if (this.dialog){
      this.observer = jsonpatch.observe(this.gerenciador);
    }
  }
 
  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {  
      this.$emit("fechou");
    }else{
      this.gerenciador = this.item;
      this.gerenciador.receitas = this.item.receitas.sort((x, y) => x.tipoId - y.tipoId );
    } 
  }   

  @Watch("gerenciador", {deep:true})
  ObservadorItem(){
    this.observer = jsonpatch.observe(this.gerenciador);
  }; 

  @Watch("contratoDocumento", {deep:true})
  ContratoDocumento(){
    this.observer = jsonpatch.observe(this.contratoDocumento);
  }; 
  
  headers: any[] = [
    {text: "", value: "actions",  sortable: false },
    {text: "Nº", value: "parcela"},
    {text: "Valor da parcela", value: "valor", align: "center",sortable: false},
    {text: "Taxa do boleto", value: "valoresAdicionais.taxaBoleto", align: "center", sortable: false},
    {text: "Valor pago", value: "valorPago", align: "center",sortable: false},
    {text: "Situação", value: "situacao.nome", align: "center",sortable: false},
    {text: "Venc. original", value: "dataVencimentoOriginal", align: "center",sortable: false},
    {text: "Vencimento", value: "dataVencimento", align: "center"},
    {text: "Boleto", value: "urlBoleto", align: "center",sortable: false},
    {text: "Gerado por", value: "ultimaTransacao", align: "center",sortable: false},
    {text: "Data pgt", value: "ultimaDataPagamento", align: "center",sortable: false},
    {text: "Forma pgt", value: "ultimaFormaPagamento", align: "center",sortable: false}
  ];
 
  headersDocumentos: any[] = [ 
    { text: "", value: "actions", sortable: false },
    { text: "Nome", value: "nome", align: "center",sortable: false },
    { text: "Arquivo", value: "dados", align: "center",sortable: false },
    { text: "Data", value: "dataEmissao", align: "center",sortable: false },
    { text: "Usuário", value: "usuario.login", align: "center",sortable: false },
  ]; 
    
  SimularAmortizacao(item){    
    this.receitaService.ObterPorId(item.id, 'Parcelas').then((res) => {
      this.simulacao = res.data;
      this.dialogSimular = true;
    },  
    (err) => {
      TratarErroApi(err)
      }
    )
  }

  ConfigurarReceita(item){
    this.receitaService.ObterPorId(item.id).then(res =>{
      this.receitaConfig = res.data;
      this.dialogConfigurarReceita = true;
    },
    (err) => {
      TratarErroApi(err)
      }
    )
  }
  
  AtualizarContrato(){
    this.loading = true;
      
      this.service.ObterPorId(this.gerenciador.id, "ContratosDigitais.Signatarios.Cliente, ContratosDigitais.Signatarios.Situacao,ContratosDigitais.Tipo,ContratosDigitais.Situacao, TipoAmortizacao, Receitas.Parcelas.AntecipacaoComprovante, Observacoes, Receitas.Parcelas.Situacao, Receitas.Parcelas.Transacoes.UsuarioBoletoGerado, Receitas.Parcelas.Baixas.FormaPagamento,Receitas.Tipo, Receitas.Parcelas.Baixas.ReceitaBaixaComprovante,TipoIndice").then((res) => {
        this.gerenciador = res.data;
        this.gerenciador.receitas = res.data.receitas.sort((x, y) => x.tipoId - y.tipoId )
        this.loading = false;
      },
      (err) => {
        TratarErroApi(err),
        this.loading = false
      })
  } 
   
  Salvar() {
    this.overlay = !this.overlay;
    this.observer = jsonpatch.observe(this.gerenciador);
    let pacthModel = jsonpatch.generate(this.observer);

    this.service.Patch(pacthModel, this.gerenciador.id).then((res) => {
      this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
      this.$emit("salvou");

      jsonpatch.unobserve(this.gerenciador, this.observer);
      this.AtualizarContrato();
      this.overlay = false;
     },
      (err) => {
        TratarErroApi(err);
        this.overlay = false;
     })
  } 

  Close() {
    this.subTabActive = null;
    this.tabActive = 0;
    this.contratoDocumento = new Contrato();
    this.overlay = false;
    this.dialog = false;
    this.Reset();
  } 
 
  ObterContrato(){
    this.service.ObterPorId(this.gerenciador.id, 'Receitas, Receitas.Parcelas, Corretores.Corretor, TipoAmortizacao,Empreendimento').then(   
      (res) => {
        this.detalhar = res.data;
        this.dialogDetalhar = true;
      },
      (err) => {
        TratarErroApi(err)
      }
   ); 
  } 

  ObterPessoa(){
    this.service.ObterPorId(this.gerenciador.id, 'Clientes.Cliente.Contatos, Clientes.Cliente.Endereco, Clientes.Cliente.Endereco.Municipio, Clientes.Cliente.Endereco.Estado').then(
      (res) => {
        this.detalharPessoa = res.data;
        this.dialogDetalharPessoa = true;
      },
      (err) => {
        TratarErroApi(err)
      }
    ); 
  } 

  async ObterUnidade(){
    try {
      if (this.item.unidadesAdicionais.length > 0) {
        let res = await new ContratoService().ObterPorId(this.item.id, 'Empreendimento, UnidadePrincipal.Grupo, UnidadesAdicionais.Unidade.Grupo.Empreendimento')
        this.contrato = res.data;
        this.dialogMultiLotes = true;
      } else {
        let res = await new EmpreendimentoService().ObterUnidade(this.gerenciador.unidadePrincipalId, 'Imovel, Jazigo, Lote, Lote.Infraestrutura');
        this.dialogUnidadeInformacao = true;
        this.informacaoUnidade = res.data;
      }
    } catch (err) {
      return TratarErroApi(err);
    }
  }

  async GerarServico(){
    let servico = this.gerenciador.receitas.find(x => x.tipoId == EnumTipoReceita.Outros);
    try{
      if (servico) {
        const res = await new ReceitaService().ObterPorId(servico.id, 'Parcelas');
        this.receitaServico = res.data;
      } else {
        const res = await this.service.ObterPorId(this.item.id, "Clientes.Cliente");
        this.receitaServico = new Receita();

        const primeiroCliente = res.data.clientes[0];
        this.receitaServico.clienteId = primeiroCliente.clienteId;
        this.receitaServico.tipoId = 9;
        this.receitaServico.contratoId = this.item.id;
        this.receitaServico.tipoGatewayId = this.item.tipoGatewayId;
        this.receitaServico.tipoDocumentoId = 1;
        this.receitaServico.empreendimentoId = this.item.empreendimentoId;
        this.receitaServico.tipoGatewayId = this.item.tipoGatewayId;
        this.receitaServico.tipoOperacaoId = this.item.tipoOperacaoId;
        this.receitaServico.contaCorrenteId = this.item.contaCorrenteId;
      }
    }catch(err){
       return TratarErroApi(err);
    }

    this.dialogDespesaContratual = true;
  }
  
  EncargosEDescontos(item){
    this.encargosEDescontos = item;
    this.encargosEDescontos.encargoFinanceiro = item.parcelas.find(x => x.encargoFinanceiro).encargoFinanceiro;
    this.dialogEncargosDescontos = true
  }
  
  AjustarParcela(item){
    this.receitaService.ObterParcelaPorId(item.id, 'Receita.Contrato').then(
      (res) => {
        this.parcela = res.data;
        this.parcela.dataVencimento = new Date(res.data.dataVencimento).yyyymmdd();
        this.dialogAjusteAmortizacao = true;
      },  
      (err) => {
        TratarErroApi(err)
      }
    )
  }

  EditarParcela(item){
    this.receitaService.ObterParcelaPorId(item.id, 'Receita.Contrato, Transacoes').then(
      (res) => {
        this.parcela = res.data;
        this.parcela.dataVencimento = new Date(res.data.dataVencimento).yyyymmdd();
        this.dialogParcela = true;
      },
      (err) => { 
        TratarErroApi(err)
      }
    )
  } 
 
  BaixarParcela(item){  
    this.parcela = item;
    this.dialogBaixar = true;  
  }  
 
  GerarBoleto(item){
    if(item.encargoFinanceiro.descontoVencimento > 0){
      let calc =  new Date(item.dataVencimento).getTime() - new Date().getTime()
      let diasAtrasos = (calc / (1000*3600*24));

      if(Math.ceil(diasAtrasos) < item.encargoFinanceiro.diasDescontoVencimento)
        return this.$swal("Aviso", "Não é possivel gerar o boleto pois a data de limite de desconto foi excedida. É necessário realizar uma das seguintes ações: Extender a data de vencimento, zerar o desconto ou zerar os dias.", "warning");
    } 

    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja gerar o boleto?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.receitaService.GerarBoleto(item.id).then(
          (res) => {
            if (res.status == 200) {
              this.AtualizarContrato(); 
              return res;
            }
          },
          (err) => TratarErroApi(err)
        )},
        // @ts-ignore: Unreachable code error
        allowOutsideClick: () => !this.$swal.isLoading(),
      }).then((result: any) => {
        if (result.value) {
          this.$swal("Aviso",result.value.data, result.value.status == 200 ? "success" : "warning");
        }
      });
  } 
  
  VisualizarBoleto(item){ 
    this.receitaService.ObterParcelaPorId(item.id, 'Transacoes').then(
      (res) => {
        let ultimaTransacao = res.data.ultimaTransacao;
        if (item.tipoOperacaoId === EnumTipoOperacaoFinanceira.Boleto) 
        {
          this.abrirUrlNoNovaJanela("financeiroBoleto", { transacaoId: ultimaTransacao.id });
        } 
        else if (item.tipoOperacaoId === EnumTipoOperacaoFinanceira.Gateway){
          if (item.tipoGatewayId === 3) {
            this.abrirUrlNoNovaJanela("RelBoletoSafra", { codigoZoop: item.codigoZoop });
          }
          else{
            window.open(item.urlBoleto, "_blank");
          }
        }
        else if (item.tipoOperacaoId === EnumTipoOperacaoFinanceira.Banco) {
          this.DownloadDocumento(item.id);
        }
      },
      (err) => {
        TratarErroApi(err);
      }
    );
  } 

  abrirUrlNoNovaJanela(nomeRota, queryParams) {
    let routeLink = this.$router.resolve({
      name: nomeRota,
      query: ConstruirQueryParams(queryParams),
    });
    window.open(routeLink.href, "_blank");
  }

  CancelarBoleto(item){ 
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja cancelar o Boleto?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.receitaService.CancelarBoleto(item.id).then(
          (res) => {
            if (res.status == 200) {
              this.AtualizarContrato(); 
              return res;
            }
          },
          (err) => {
            TratarErroApi(err)
          }
        )},
    // @ts-ignore: Unreachable code error
    allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal("Aviso",result.value.data,result.value.status == 200 ? "success" : "warning");
      }
    }); 
  }
  
  CancelarParcela(item){
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja cancelar a parcela?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.receitaService.CancelarParcela(item.id).then(
          (res) => {
            if (res.status == 200) {
              this.AtualizarContrato();
              return res;
            }
          },
          (err) => TratarErroApi(err)
        )},
      // @ts-ignore: Unreachable code error
    allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal("Aviso",result.value.data,result.value.status == 200 ? "success" : "warning");
      }
    }); 
  } 

  EnviarWhatsapp(item){
    this.service.ObterPorId(this.item.id, 'Clientes.Cliente').then(res => {
    let contrato = res.data;
    var telefone = FormatarNumeroTelefone(contrato.clientes[0].cliente!.telefone);

    window.open(`https://api.whatsapp.com/send?phone=55${telefone}&text=Olá, Segue a baixo o link do seu boleto: ${item.urlBoleto} Ou se preferir, para maior comodidade segue o código do boleto:  ${item.linhaDigitavelBoleto}`) })
  }

  DataSequencia(item){
    this.parcela = item;
    this.dialogDataSequencia = true;
  } 
 
  Quitar(item){
    new ReceitaService().ObterPorId(item.id, 'Parcelas, Contrato').then(
      (res) => {
        this.contratoQuitacao = res.data;
        this.dialogQuitarContrato = true;
      },
      (err) => {
        TratarErroApi(err)
      }
    )
  }

  GerenciarReceita(item){
     let aux = 0;
      for(let i = 0; i < item.parcelas.length; i++){
        if(item.parcelas[i].situacaoId != 1){
          this.$swal("Aviso", "Não é possível gerenciar a receita com parcela paga ou cancelada.", "warning");
          aux ++;
        break; 

        }else if(item.parcelas[i].urlBoleto){
          this.$swal("Aviso", "Não é possível gerenciar a receita com parcela com o boleto gerado.", "warning")
          aux ++;
        break;

        }else if(item.parcelas[i].agrupadorId	> 0){
          this.$swal("Aviso", "Não é possível gerenciar a receita com parcela agrupada.", "warning")
          aux ++;
          break
        }
      }

    if(aux == 0){
      this.receitaGerenciada = item;       
      this.dialogGerencial = true;
    } 
  }

  Reset(){
    this.gerenciador = new Contrato();
    this.antecipacao = new AnteciparDocumento();
  }

  AgruparAntecipar(item){
    new ReceitaService().ObterPorId(item.id, 'Contrato, Cliente, Parcelas.Baixas').then(
      (res) => {
        this.receitaAgruparAntecipar = res.data;
        this.receitaAgruparAntecipar.parcelas = res.data.parcelas.filter(x => x.agrupadorId == null && x.situacaoId != 3 && x.situacaoId != 2 && x.isAgrupador == false); 
        this.dialogAgruparAntecipar = true;
      },
      (err) => {
        TratarErroApi(err)
      }
    )
  }
 
  SituacaoColor(item){
    return SituacaoColor(item)
  }

  NovoProcesso(){
    this.tipoProcesso = new TipoProcessoJudicial();
    this.dialogProcesso = true;
  } 

  Atualizar(){
    this.serviceTipoProcesso.ListarTudo().then(
      (res) => {
        this.listaTipoProcessos = res.data.items;
      },
      (err) => {
        TratarErroApi(err)
      }
    );
  }

  getDocumentos(atualizar = false){
    if(this.contratoDocumento.documentos.length == 0 || atualizar){
      this.overlayDocumentos = true;

      this.service.ObterPorId(this.gerenciador.id, "Documentos.Usuario").then(
        (res) => {
          this.contratoDocumento = res.data;
          this.overlayDocumentos = false;
        },
        (err) =>  {
          TratarErroApi(err)
        }
      );  
    }
  }
 
  SalvarDoc(){
    this.observer = jsonpatch.observe(this.contratoDocumento);
    let pacthModel = jsonpatch.generate(this.observer);

    this.service.Patch(pacthModel, this.contratoDocumento.id).then(
      (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        jsonpatch.unobserve(this.contratoDocumento, this.observer);
        this.getDocumentos(true);
      },
      (err) => {
        TratarErroApi(err)
     });
  } 
 
  async AdicionarArquivo() {
    if (!this.inputDocumento) {
      this.$swal("Aviso", "É necessário selecionar um arquivo.", "warning");
      return;
    } 
    let dados = await new ArquivoService().Ler(this.inputDocumento);
    this.documento.usuarioId = JSON.parse(localStorage.sessionApp.split(".")).dados.usuarioId;
    this.documento.dataEmissao = new Date().yyyymmdd();
    this.documento.dados = dados.replace(/^[^,]*,/, "");
    this.documento.nome = this.documento.nome != "" ? this.documento.nome : this.inputDocumento.name;
    this.documento.tamanho = this.inputDocumento.size;
    this.documento.tipo = this.inputDocumento.type;
    this.contratoDocumento.documentos.push(this.documento);   
    this.documento = new DocumentoContrato();
    this.inputDocumento = null;
    this.SalvarDoc();
  }
 
  ExcluirDocumento(documento:any) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      preConfirm:() => {
        if(documento.id > 0){
         documento.excluido = true;
         const index = this.contratoDocumento.documentos!.indexOf(documento);
         delete this.contratoDocumento.documentos![index]
         this.dialog = false;
         this.dialog = true;
         return true; 
        }else{
        const index = this.contratoDocumento.documentos!.indexOf(documento);
        this.contratoDocumento.documentos!.splice(index, 1);
        return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
        this.SalvarDoc();
      }
    })
  }

  BaixarArquivo(item){
    new ArquivoService().Download(item);
  }

  CompartilharArquivo(item){
    new ArquivoService().Compartilhar(item);
  }

  GerarComprovante(item){
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que desejar gerar o Comprovante?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.receitaService.GerarDocumentoAntecipacao(item.id).then(
          (res) => {
            if (res.status == 200) {
              this.antecipacao = res.data;            
                setTimeout(() => {
                  this.BaixarComprovante(item);
                }, 1000)

              this.AtualizarContrato(); 
              return res;
            }
          },
         (err) => {TratarErroApi(err)}
        );
      },
    // @ts-ignore: Unreachable code error
    allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal("Aviso", result.value.data,result.value.status == 200 ? "success" : "warning");
      }
    }); 
  }

  BaixaRetroativa(item){
    new ReceitaService().ObterParcelaPorId(item.id).then(
      (res) => {
        this.parcelaBaixaRetroativa = res.data;
        this.dialogBaixasRetroativa = true;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }
    )
  }
  
  BaixarComprovante(item){
    const doc = new jsPDF.default();
    var y = 20;  
    doc.setLineWidth(2);  
    (doc as any).text(60, y = y + 15, "COMPROVANTE DE QUITAÇÃO").autoTable({  
        html: '#simple_table4',  
        startY: 50,  
        theme: 'grid',  
      }).autoTable({  
          html: '#simple_table',  
          theme: 'grid',  
          columnStyles: {  
            0: { cellWidth: 15},  
            1: { cellWidth: 25},  
            2: { cellWidth: 25},
            3: { cellWidth: 25},
            4: { cellWidth: 25},
            5: { cellWidth: 25},
            6: { cellWidth: 25},
            7: { cellWidth: 25} 
          },   
      }).autoTable({  
          html: '#simple_table2',  
          theme: 'grid',  
        styles: {  
            fillColor: false,
            textColor:'#191919'
          },
      }).autoTable({  
        html: '#simple_table3',  
        theme: 'grid',  
        styles: {  
        fillColor: false,
        textColor:'#000000'
        },
    });   

     doc.save('comprovante.pdf'); 
      var file = doc.output('datauristring');
      var url = file.replace('data:application/pdf;filename=generated.pdf;base64,', '')
      doc.output('dataurlnewwindow');
      
      this.comprovante.dados = url;
      this.receitaService.SalvarDocumento(item.antecipacaoComprovante.id, this.comprovante).then(res =>{
        this.$swal("Aviso", "Operação realizada com sucesso!", "success")
      },

      (err) => TratarErroApi(err)
    )
  }

  VisualizarComprovante(item) {
    this.receitaService.VisualizarDocumentoAntecipacao(item.antecipacaoComprovante.id).then(res =>{
      var url = 'data:application/pdf;base64,' + res.data;
      var iframe = "<iframe width='100%' height='100%' src='" + url + "'></iframe>"
        var x:any = window.open();
          x.document.open();
          x.document.write(iframe);
          x.document.close();
          document.location.href = url;
      },
      (err) => {TratarErroApi(err)}
     )  
  }

  mesReajuste = [
    { id: 1, nome: "Janeiro" },
    { id: 2, nome: "Fevereiro" },
    { id: 3, nome: "Março" },
    { id: 4, nome: "Abril" },
    { id: 5, nome: "Maio" },
    { id: 6, nome: "Junho" },
    { id: 7, nome: "Julho" },
    { id: 8, nome: "Agosto" },
    { id: 9, nome: "Setembro" },
    { id: 10, nome: "Outubro" },
    { id: 11, nome: "Novembro" },
    { id: 12, nome: "Dezembro" },
  ]; 

  DownloadDocumento(id: number){
    this.loading = true;
    this.receitaService.DownloadDocumento(id).then(
        res => 
        {
          let file = new Blob([res.data], {type: res.headers["content-type"]});
          let fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        },
          (err) => TratarErroApi(err)
    ).finally(() => this.loading = false);
  }  

  ObterHistorico(){
    this.service.ObterPorId(this.item.id, "HistoricoSituacoes.Situacao, HistoricoSituacoes.Usuario").then(
      (res) => {
        this.contratoHistorico = new Contrato(res.data);   
        this.dialogHistorico = true;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }) 
  }
 
  obterLoginUsuario(item) {
     if (item.ultimaTransacao && (item.urlBoleto || item.linhaDigitavelBoleto)) {
         if (item.ultimaTransacao.usuarioBoletoGerado) {
            return item.ultimaTransacao.usuarioBoletoGerado.login;
        } else {
            return '-';
        }
    }
    return '-';
  }

  mounted(){
    new SituacaoReceitaParcelaService().ListarTudo().then(
      res => {this.listaSituacao = res.data.items}
    )
    
    new TipoProcessoJudicialService().ListarTudo().then(
      res => {this.listaTipoProcessos = res.data.items}
    )
  }
}  
