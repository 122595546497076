
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class VDataPicker extends Vue {
    @Prop({ required: true }) private label!: string;
    @Prop() private data!: string | null;
    @Prop() private formattedDate!: string | null;
    @Prop({ required: true }) private callApi!: (date: string) => void;
    @Prop() private dataType!: string;

    menuInicial:any= false;
    menuFinal: any = false;
    localDate: string = this.data || "";
    localFormattedDate: string = this.formattedDate || "";
    isProcessing: boolean = false;

    valid = true;
    dialog = false;
    immediate = true

    formatDate(date: string, format: string) {
    if (!date) return '';
    const [year, month, day] = date.split('-');
    if (format === 'yyyy-MM-dd') {
      return `${year}-${month}-${day}`;
    }
    return '';
  }
    async onEnter() {
    await this.formatDateText();
    this.saveDate()
    this.menuInicial = false;
  }

  async onDateComplete() {
    await this.formatDateText();
    this.saveDate()
    this.menuInicial = false;
}

  async formatDateText() {
    if (!this.localFormattedDate) return;

    const parts = this.localFormattedDate.split('-');
    if (parts.length === 3) {
      const ano = parseInt(parts[0], 10);
      const mes = parseInt(parts[1], 10) - 1;
      const dia = parseInt(parts[2], 10);

      if (ano < 1000 || ano > 9999 || mes < 0 || mes > 11) {
        return;
      }

      const dataInicial = new Date(ano, mes, dia);

      if (
        dataInicial.getMonth() !== mes ||
        dataInicial.getDate() !== dia ||
        dataInicial.getFullYear() !== ano
      ) {
        return;
      }

      if (!isNaN(dataInicial.getTime())) {
        this.localDate = `${ano}-${(mes + 1)
          .toString()
          .padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;
      }
    }
  }
    
  saveDate() {
    if (this.localDate) {
      this.localFormattedDate = this.formatDate(this.localDate, 'yyyy-MM-dd');
      this.$emit('date-selected', { date: this.localDate, type: this.dataType });
    } 
    
  }

    mounted() {
      this.localFormattedDate = this.formattedDate || "";
    }

}

