
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { EmpresaService } from "../../../core/services/compras/EmpresaService";
import { Empreendimento,Empresa} from "@/core/models/cadastros";
import { EmpreendimentoService } from "@/core/services/cadastros";
import { EtapaService } from "@/core/services/gestao";
import Pessoa from "@/core/models/cadastros/Pessoa";
import { PessoaService } from "@/core/services/cadastros/PessoaService";
import {ContratoFornecedor, TipoContratoFornecedor} from "@/core/models/projeto";
import {ContratoFornecedorItem} from "@/core/models/projeto";
import {ContratoFornecedorObservacao} from "@/core/models/projeto";
import {ContratoFornecedorService, TipoContratoFornecedorService} from "@/core/services/projeto"
import { CentroCustoService, ContaGerencialService } from "@/core/services/financeiro";
import * as jsonpatch from 'fast-json-patch';

@Component
export default class CadastroContratoFornecedor extends Vue {
  @Prop() public item!: ContratoFornecedor;
  @Prop() public value!: string;

  tab = null;

  service = new ContratoFornecedorService()
  observacao = new ContratoFornecedorObservacao();
  objetoEtapa = new ContratoFornecedorItem();
  fornecedor = new Pessoa();
  Pessoa = new Pessoa();
  empreendimento:any = new Empreendimento();

  validContrato: boolean = true;
  observacaoIndex = -1;
  etapaIndex = -1;
  stepVertical: number = 1;
  stepHorizontal: number = 1;
  dialogCadastroPessoa: boolean = false;

  centroCustosOrdenada = [];
  contaGerenciaisOrdenada = [];
  listaEmpreendimentos: Empreendimento[] = [];
  listaEmpresaContratante: Empresa[] = [];
  etapas = [] as ContratoFornecedorItem[];
  tipo = [] as TipoContratoFornecedor [];
  listaFornecedor: Pessoa[] = [];
 
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
    formEtapa: HTMLFormElement;
  };

  observer! : jsonpatch.Observer<ContratoFornecedor>;

  headersEtapa: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Etapa", value: "etapaId", align: "center" },
    { text: "Unidades", value: "quantidade", align: "center" },
    { text: "Valor/Casa", value: "valorUnitario", align: "center" },
    { text: "Valor Total", value: "valorTotal", align: "center" },
    { text: "Caução", value: "caucao", align: "center" },
    { text: "Data de Início", value: "dataInicio", align: "center" },    
    { text: "Data de Conclusão", value: "dataFim", align: "center" },
  ];
 
 // @Watch("item.fornecedorId")
  Fornecedor() {
   new PessoaService().ObterPorId(this.item.fornecedorId, "Endereco.Estado, Endereco.Municipio").then((res) => {
      this.fornecedor = new Pessoa(res.data);
    });
  }
   
  Empreendimento() {
    new EmpreendimentoService().ObterPorId(this.item.empreendimentoId, "Grupos, Grupos.Unidades" ).then(
      res => {
        this.empreendimento = res.data;
      })
  }

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }
   
  AbrirCadastroPessoa() {
    this.Pessoa = new Pessoa();
    this.dialogCadastroPessoa = true;
  }

  AtualizarPessoa(){
    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome, nomeCnpjCpf', '').then(
      res => this.listaFornecedor = res.data.items
    )
  }  

  @Watch("objetoEtapa.valorUnitario")
  ValorTotal() {
    this.objetoEtapa.valorTotal = this.objetoEtapa.quantidade * this.objetoEtapa.valorUnitario
  };

  Salvar(){
    if (this.item.empresaId == null || this.item.empresaId == 0 ){
      this.$swal("Erro!", "É necessário selecionar a EMPRESA CONTRATANTE" , "error")
      return;
    }

    if (this.item.fornecedorId == null || this.item.fornecedorId == 0 ){
      this.$swal("Erro!", "É necessário selecionar um PRESTADOR DE SERVIÇO" , "error")
      return;  
    }

    if(this.item.empreendimentoId == null || this.item.empreendimentoId == 0){
      this.$swal("Erro!", "É necessário selecionar o EMPREENDIMENTO", "error")
      return;
    }

     if(this.item.itens.length == 0 ){
      this.$swal("Erro!", "É necessário adicionar pelo menos uma ETAPA", "error")
      return;
    }

    if(this.item.centroCustoId == null || this.item.centroCustoId == 0){
      this.$swal("Erro!", "É necessário definir o CENTRO DE CUSTO", "error")
      return;
    }

    if(this.item.contaGerencialId == null || this.item.contaGerencialId == 0){
      this.$swal("Erro!", "É necessário definir a CONTA GERENCIAL", "error")
      return;
    }

     if(this.item.prazoInicio == "" || this.item.prazoInicio == undefined){
      this.$swal("Erro!", "É necessário definir a DATA DE INÍCIO", "error")
      return;
    }

    if(this.item.prazoFim == "" || this.item.prazoFim == undefined){
      this.$swal("Erro!", "É necessário definir a DATA DE CONCLUSÃO ", "error")
      return;
    }

    if(this.item.prazoGarantiaInicio == "" || this.item.prazoGarantiaInicio == undefined){
      this.$swal("Erro!", "É necessário definir a data de INÍCIO DA GARANTIA ", "error")
      return;
    }

     if(this.item.prazoGarantiaFim == "" || this.item.prazoGarantiaFim == undefined){
      this.$swal("Erro!", "É necessário definir o PRAZO DE GARANTIA ", "error")
      return;
    }
 
    if (this.$refs.form.validate()) {
      let modelo = this.item;

      let pacthModel = jsonpatch.generate(this.observer);
     (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(modelo)).then(
      (res) => {
        this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close();
      }, 
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      });
    }
  }

  Close() {
    this.dialog = false;
  }
  
  AdcionarEtapa() {
    if (this.objetoEtapa.etapaId == 0) 
      return this.$swal("Erro!", "É necessário selecionar uma etapa", "error");

    if( this.objetoEtapa.quantidade == 0 || this.objetoEtapa.quantidade == null)
      return this.$swal("Erro!", "É necessário definir a quantidade de unidades" , "error")
      
    if(this.objetoEtapa.quantidade > this.empreendimento.quantidadeUnidade)
      return this.$swal("Erro!", "A quantidade não pode ser maior que o número de unidades do Empreendimento" , "error")
    
    if(this.objetoEtapa.quantidade == 0 || this.objetoEtapa.quantidade == null)
      return this.$swal("Erro!", "É necessário definir a quantidade de unidades" , "error")
      
    if( this.objetoEtapa.valorUnitario == 0 || this.objetoEtapa.valorUnitario == null)
      return this.$swal("Erro!", "É necessário definir o valor" , "error")
      
    if(this.objetoEtapa.caucao == null || this.objetoEtapa.caucao == undefined)
     return this.$swal("Erro!", "É necessário definir o caução" , "error")
    
    if( this.objetoEtapa.dataInicio == '' || this.objetoEtapa.dataInicio == null)
     return this.$swal("Erro!", "É necessário definir a data de início" , "error")
      
    if( this.objetoEtapa.dataFim == '' || this.objetoEtapa.dataFim == null)
      return this.$swal("Erro!", "É necessário definir a data de conclusão" , "error")

  
    if (this.etapaIndex > -1) {
      Object.assign(this.item.itens[this.etapaIndex], this.objetoEtapa);
    } else {
      this.item.itens.push(this.objetoEtapa);
    }
    this.objetoEtapa = new ContratoFornecedorItem();
    this.etapaIndex = -1;
  }

  editarEtapa(item) {
    this.objetoEtapa = Object.assign({}, new ContratoFornecedorItem(item));
    this.etapaIndex = this.item.itens.indexOf(item)
  }

  deleteEtapa(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      preConfirm:() => {
        if(item.id > 0){
         item.excluido = true;
         const index = this.item.itens.indexOf(item);
         delete this.item.itens[index]
         this.dialog = false;
         this.dialog = true;
         return true; 
        }else{
        const index = this.item.itens.indexOf(item);
        this.item.itens.splice(index, 1);
        return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
      }
    })
  }

  mounted() {
    this.AtualizarPessoa();

    new CentroCustoService().Listagem().then(
      res => this.centroCustosOrdenada = res.data
    )

    new ContaGerencialService().Listagem().then(
      res => this.contaGerenciaisOrdenada = res.data
    )

    new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome', '').then(
      res => this.listaEmpresaContratante = res.data.items
    )

    new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome', '').then(
      res => this.listaEmpreendimentos = res.data.items);

    new EtapaService().Listagem().then(
      res => this.etapas = res.data
    )
 
    new TipoContratoFornecedorService().ListarTudo().then (
      res => this.tipo = res.data.items
    )
  }
}
