
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ReceitaService } from "@/core/services/financeiro";
import { Receita, ReceitaParcela, ValoresAdicionais } from "@/core/models/financeiro";

@Component
export default class valoresAdicionais extends Vue {
  @Prop() public item!: ReceitaParcela;
  @Prop() public value!: string;


  valoresAdicionais = new ValoresAdicionais();
  receitaService = new ReceitaService();
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];


  fixo = [
    {id:1, nome:'Sim', fixo:true},
    {id:2, nome:'Não', fixo:false}
   ]

  $refs!: {
    form: HTMLFormElement;
  };

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  Close() {
    this.dialog = false;
    this.valoresAdicionais = new ValoresAdicionais();
  }
 
   Salvar(item){ 
     if (this.$refs.form.validate()) {

      let isDFIFixo = this.valoresAdicionais.isDFIFixo != null? this.valoresAdicionais.isDFIFixo.toString() : "";
      let gestao = this.valoresAdicionais.gestao ? this.valoresAdicionais.gestao.toString() : "";
      let percentualMPI = this.valoresAdicionais.percentualMPI ? this.valoresAdicionais.percentualMPI.toString() : "";
      let valorDFI = this.valoresAdicionais.valorDFI ? this.valoresAdicionais.valorDFI.toString() : "";
      let percentualDFI = this.valoresAdicionais.percentualDFI ? this.valoresAdicionais.percentualDFI.toString() : "";

      this.receitaService.SalvarValoresAdicionais(item.receitaId, gestao, percentualMPI, percentualDFI, valorDFI, isDFIFixo).then(
        (res) => {
          this.$swal("Aviso", "Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        })
     }
   }
}

