
import { TratarErroApi } from "@/assets/scripts/helper";
import { Empresa } from "@/core/models/cadastros";
import { PageBase } from "@/core/models/shared";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ReceitaBaixa,ReceitaParcela} from "../../../core/models/financeiro";
import { ReceitaService,FormaPagamentoService, ContaCorrenteService} from "../../../core/services/financeiro";

@Component
export default class ParcelaBaixaReceita extends PageBase {
  @Prop() public item!: ReceitaParcela;
  @Prop() public value!: string;
   
  service = new ReceitaService();
  baixa = new ReceitaBaixa();

  parcela = new ReceitaParcela();
  comprovante = new ComprovanteBaixa();
   
  listaFormaPagamento = [];
  contaCorrentes = [];
     
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: { form: HTMLFormElement };

  tabActive = null;
  valid:boolean = true;
  dialog:boolean = false;
  loading:boolean = false;
  acrescimosDescontos:boolean = false;
   overlay:boolean = false;

  private $htmlToPaper:any;
  encargo = {juros:0,percentual:0,desconto:0,percentualdesconto:0} 

  headers = [
    { text: "Ação", value: "actions", sortable: false, align: 'center' },
    { text: "Data", value: "dataPagamento", sortable: false, align: 'center' },
    { text: "Forma de pgt", value: "formaPagamento.nome", sortable: false, align: 'center' },
    { text: "Conta C.", value: "contaCorrente.nome", sortable: false, align: 'center' },
    { text: "Valor", value: "valor", sortable: false, align: 'center'},
    { text: "Encargos", value: "juros", sortable: false, align: 'center' },
    { text: "Multa", value: "multa", sortable: false, align: 'center' },
    { text: "Desconto", value: "desconto", sortable: false , align: 'center'},
    { text: "Total", value: "total", sortable: false, align: 'center' },
    { text: "Usuário", value: "usuario", sortable: false , align: 'center'},
    { text: "Status", value: "cancelado", sortable: false, align: 'center' },
  ];
 
  @Watch("baixa")
  Baixa() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");   
    } else {
      this.Atualizar();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  } 
 
  Salvar(fechar:boolean = false) {
    if (this.$refs.form.validate()) {
      if (this.parcela.saldo === 0) {
        this.$swal("Aviso", "Atenção: Parece que esta parcela já foi devidamente paga!", "warning");
        return;
      }
        this.baixa.usuarioId =  this.app.usuarioId;
        this.overlay = true;

        let modelo = this.baixa;
        this.service.BaixarParcela(this.item.id, modelo).then(
          (res) => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");

          if (fechar) {
            this.Close();
          } else {
            this.Atualizar();
          }

          this.encargo = {juros: 0, percentual: 0, desconto: 0, percentualdesconto: 0};
          this.baixa = new ReceitaBaixa();
          this.comprovante = new ComprovanteBaixa();
      },
      (err) => TratarErroApi(err)
      ).finally(() => this.overlay = false);
    }
  }   

  Atualizar(){ 
    this.loading = true;

    this.service.ObterParcelaPorId(this.item.id, 'Baixas.ReceitaBaixaComprovante, Baixas.Usuario, Baixas.FormaPagamento, Baixas.ContaCorrente').then(
      res => {
        this.parcela = res.data;
        this.baixa.valor = this.parcela.saldo;
        this.baixa.total = this.parcela.saldo;
        this.AtualizarValor();
        this.loading = false;
      }) 
  }

  Imprimir(){
    const localOptions = {
      styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css'
      ]
    }; 
    this.$htmlToPaper('printMe', localOptions);
  }

  VisualizarComprovante(item){
    this.service.VisualizarComprovante(item.id).then(
      (res) => {
        if (res.status == 200) {
          this.comprovante = res.data;
            setTimeout(() => { 
              this.Imprimir()
            },
            1500)
         }},
      (err) => TratarErroApi(err)
    );
  }

  GerarComprovante(item){
     this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja gerar o comprovante?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.GerarComprovante(item.id).then(
          (res) => {
            if (res.status == 200) {
            this.Atualizar();
            this.VisualizarComprovante(item);
            return res;
            }
          },
          (err) => TratarErroApi(err)
        );
      },
       // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
      }
    });
  }

  CancelarBaixa(item){
      this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja cancelar a baixa?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.CancelarBaixa(item.id).then(
          (res) => {
            if (res.status == 200) {
              this.Atualizar();
              return res;
            }
          },
          (err) => TratarErroApi(err)
        );
      },
       // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
      }
    });
  }

  calcularDiasAtraso(dataPagamento, dataVencimento) {
    return Math.ceil((dataPagamento - dataVencimento) / (1000 * 3600 * 24));
  }

  calcularJuros(diasAtraso, taxaJurosDia, valor, acresc) {
    return (diasAtraso * taxaJurosDia * valor / 100) + acresc;
  }

  calcularMulta(taxaMulta, valor) {
    return taxaMulta * valor / 100;
  }

  calcularDesconto(taxaDesconto, valor, desc) {
    return (taxaDesconto * valor / 100) + desc;
  }


  @Watch("baixa.valor")
  AtualizarValor() {
    const dataPagamento = new Date(this.baixa.dataPagamento);
    const dataVencimento = new Date(this.item.dataVencimento);
    const diasAtraso = this.calcularDiasAtraso(dataPagamento, dataVencimento);
    this.baixa.multa = 0; this.baixa.juros = 0; this.baixa.desconto = 0;

      this.baixa.desconto += this.encargo.desconto;
      this.baixa.juros += this.encargo.juros;
    if (diasAtraso >= 1) {
      this.baixa.juros = parseFloat(this.calcularJuros(diasAtraso, this.item.encargoFinanceiro.jurosDia, this.baixa.valor, this.encargo.juros).toFixed(2));
      this.baixa.multa = parseFloat(this.calcularMulta(this.item.encargoFinanceiro.multa, this.baixa.valor).toFixed(2));
    } else if (diasAtraso <= -this.item.encargoFinanceiro.diasDescontoVencimento) {
      this.baixa.desconto = parseFloat(this.calcularDesconto(this.item.encargoFinanceiro.descontoVencimento, this.baixa.valor, this.encargo.desconto).toFixed(2));
    }

    this.baixa.total = parseFloat((this.baixa.valor + this.baixa.juros + this.baixa.multa - this.baixa.desconto).toFixed(2));
  }

  BaixarParcela(tipo:string, elemento:any){
     if(tipo.toLocaleLowerCase() === "valor"){ 
      var valor = elemento
      valor = valor + '';
      valor = parseInt(valor.replace(/[\D]+/g,''));
      valor = valor + '';
      valor = valor.replace(/([0-9]{2})$/g, ".$1");
       
      this.baixa.valor = valor
      this.AtualizarValor();
     }   
  }

  @Watch("encargo.juros")
  BaixaJuros(){
    if (isNaN(this.encargo.juros) || isNaN(this.baixa.valor)) {
      return;
    }

    const percentual = ((this.encargo.juros * 100) / this.baixa.valor).toFixed(2);
    this.encargo.percentual = Number(percentual);
    this.AtualizarValor();
  }

  BaixaPercentual(tipo:string, elemento:any){
    if(tipo.toLocaleLowerCase() === "valor"){ 
      var percentual = elemento;

      let juros = Number((this.baixa.valor * percentual) / 100).toFixed(2);
      this.encargo.juros = Number(juros);
      this.AtualizarValor();
    }
  }

  @Watch("encargo.desconto")
  DescontoValor(){
    if (isNaN(this.encargo.desconto) || isNaN(this.baixa.valor)) {
      return;
    }

    const percentual = Number((this.encargo.desconto * 100) / this.baixa.valor).toFixed(2);
    this.encargo.percentualdesconto = Number(percentual);
    
    this.AtualizarValor();
  }

  DescontoPercentual(tipo:string, elemento:any){
    if(tipo.toLocaleLowerCase() === "valor"){ 
      var valor = elemento;
      let desconto = Number((this.baixa.valor * valor) / 100).toFixed(2);
      this.encargo.desconto = Number(desconto);

      this.AtualizarValor();
    }
  } 
 
  Close() {
    this.dialog = false;
    this.Reset();
  } 

  Reset(){
    this.parcela = new ReceitaParcela();
    this.encargo = {juros:0,percentual:0, desconto:0, percentualdesconto:0, }
    this.baixa = new ReceitaBaixa();
    this.comprovante = new ComprovanteBaixa();
    this.acrescimosDescontos = false;
  }

  mounted() {   
    new ContaCorrenteService().ListarTudo().then(res => {
      this.contaCorrentes = res.data.items;
    })

    new FormaPagamentoService().ListarTudo().then(
    (res) => {
      this.listaFormaPagamento = res.data.items;
    });
  } 
}
  class ComprovanteBaixa {
    public clientesNomes = [];
    public codigoRodape:string = "";  
    public contratoNumeroESequencia:string ="";
    public correcao:number = 0;
    public dataPagamentoBaixa:number = 0;
    public dataVencimentoParcela:number = 0;
    public descontos:number =0;
    public empreendimentoNome:string ="";
    public empresa = new Empresa();
    public empresaLogo:any = "";
    public grupoNome:string ="";
    public juros:number = 0;
    public multa:number = 0;
    public numeroParcela:number = 0 
    public receitaBaixaComprovante = new ReceitaBaixaComprovante();
    public saldo:number = 0;
    public totalParcela:number = 0; 
    public unidadeNome:string ="";
    public usuarioNome:string ="";
    public valorPagoHoje:number = 0;
    public valorParcela:number = 0;
  }

  class ReceitaBaixaComprovante{
    public dataEmissao:string ="";
    public id:number = 0;
    public receitaBaixaId:number =0
  }
