
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { TipoIndiceService } from '@/core/services/cadastros';
import Indice from '@/core/models/cadastros/Indice';
import { IndiceService } from '@/core/services/financeiro';
import * as jsonpatch from 'fast-json-patch';

@Component
export default class CadastroIndice extends Vue {
  @Prop() public item!: Indice;
  @Prop() public value!: string;

  service = new IndiceService();
  valid = true;
  dialog = false;
  
  //total:number=0;
  dias:any = [];  

  meses = [
    { id: 1, nome: "Janeiro" },
    { id: 2, nome: "Fevereiro" },
    { id: 3, nome: "Março" },
    { id: 4, nome: "Abril" },
    { id: 5, nome: "Maio" },
    { id: 6, nome: "Junho" },
    { id: 7, nome: "Julho" },
    { id: 8, nome: "Agosto" },
    { id: 9, nome: "Setembro" },
    { id: 10, nome: "Outubro" },
    { id: 11, nome: "Novembro" },
    { id: 12, nome: "Dezembro" },
  ];

  anos:any = [];

  fieldRules: any[] = [
    (v: any) => !!v || 'Campo obrigatório',
  ]

  indices = [];
 
  $refs!: {
    form: HTMLFormElement
  }

  @Watch('item') 
  Item(){
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  observer! : jsonpatch.Observer<Indice>;

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }
 
  @Watch('dialog')
  Dialog() {
    if (!this.dialog) {
      this.$emit('fechou');
    }
  } 

  get totalMensal(){
    return Number(this.sum(this.item.mensal, this.item.avulsoMensal)) || 0;
  }

  get total(){
    return Number(this.sum(this.item.acumulado, this.item.percentual)) || 0;
  }

  Salvar() {
    if (this.$refs.form.validate()) {
    let modelo = this.item;
    let pacthModel = jsonpatch.generate(this.observer);

    (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(modelo)).then(
     res => {
        this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close()
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
    }
  }

  Close() {
    this.dialog = false;
   // this.total = 0;
  }

  GetAnos(){
    var max = new Date().getFullYear() + 1;
    var min = max - 50

    for (let i = max; i >= min; i--) {
      this.anos.push(i)
    }
    return this.anos 
  }

  GetDias(){
    for(let i = 1; i <= 30; i++){
      this.dias.push(i);
    }    
  }

  sum = (x: any, y: any) => parseFloat(x) + parseFloat(y);

  mounted() {
    this.GetDias();
    this.GetAnos();
     
    new TipoIndiceService().ListarTudo().then(
      res => {
        this.indices = res.data.items;
      });
  }

}
