
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { PedidoService } from "@/core/services/compras/PedidoService";
import { Pedido} from "@/core/models/compras";
import { CentroCustoService,ContaGerencialService,DespesaService,SituacaoDespesaParcelaService,TipoDespesaService,TipoDocumentoService} from "@/core/services/financeiro";
import { Despesa,DespesaClassificacao,DespesaParcela} from "@/core/models/financeiro";
import { EmpreendimentoService, PessoaService} from "@/core/services/cadastros";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { SituacaoFreteService } from "@/core/services/compras/SituacaoFreteService";
import { EnumOrigemDespesa } from "@/core/models/financeiro/OrigemDespesa";
import { SituacaoColor } from '@/assets/scripts/helper';

@Component
export default class Financeiro extends Vue {
  @Prop() public item!: Pedido;
  @Prop() public value!: string;
  @Prop() public despesa!:Despesa;

  service = new PedidoService();
  
  centrocustos = [];
  contagerenciais = [];
  situacaosFrete = [];
  empreendimentos = [];
  empresas = [];
  tiposDespesas = [];
  situacoes = [];
  tipoDocumentos = [];
  listaPessoa = [];

  totalDespesa:number = 0;
 
  parcela = new DespesaParcela();
  parcelaFrete = new DespesaParcela();
  despesaFrete = new Despesa();
  despesaClassificacao = new DespesaClassificacao();

  qtdeParcelas: number = 0;
  valorParcela: number = 0;
  valorTotal: number = 0;
  qtdeParcelasFrete: number = 0;

  validParcela = true;
  tabActive = null;
  loading = false;
  loading2 = false;

  valid = true;
  dialog = false;
  dialogParcela = false;

  pedidoIndex = -1;
  classificacaoIndex = -1;
  parcelaIndex = -1;
  parcelasIndexFrete = -1;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    formCadastroPedido: HTMLFormElement;
    formParcelas: HTMLFormElement;
    formClassificacao: HTMLFormElement;
    formDespesa: HTMLFormElement;
    formDespesaFrete: HTMLFormElement;
  };

  headersPedido = [
    { text: "Nome", value: "produto.nome" },
    { text: "Qtde Pedida", value: "quantidade" },
    { text: "Qtde Recebida", value: "quantidadeRecebida" },
    { text: "Preço Unitário", value: "valorUnitario" },
    { text: "Situação", value: "situacao.nome" },
    { text: "Valor", value: "total" },
  ];

  headersParcela = [
    { text: "", value: "actions", sortable: false },
    { text: " Parcela", value: "parcela" },
    { text: "Valor", value: "valor" },
    { text: "Linha digitavél", value: "linhaDigitavelBoleto" },
    { text: "Vencimento", value: "dataVencimento" },
    { text: "Situacao", value: "situacaoId" },
  ];

  headersDistribuicao = [
    { text: "", value: "actions", sortable: false },
    { text: "Centro Custo", value: "centroCustoId" },
    { text: "Conta Gerencial", value: "contaGerencialId" },
    { text: "Valor", value: "valor" },
    { text: "Percentual", value: "percentual" },
  ];

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
    this.despesa.fornecedorId = this.item.fornecedorId;
  }


  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }else{
      let total = this.item.itens.reduce((x:any, y:any) => x + y.total, 0)
      this.valorTotal = Number(total.toFixed(2)) 
      
      this.despesa.valor = this.valorTotal + this.item.frete
      this.totalDespesa = this.despesa.valor
      this.ObterUsuarioLogado();
    } 
  }

  @Watch("despesaClassificacao")
  DespesaClassificacao() {
    if (this.$refs.formClassificacao) {
      this.$refs.formClassificacao.resetValidation();
    }
  }

  @Watch("despesa")
  Despesa() {
    if (this.$refs.formDespesa) {
      this.$refs.formDespesa.resetValidation();
    }
  }
 
  @Watch("despesaFrete")
  DespesaFrete() {
    if (this.$refs.formDespesaFrete) {
      this.$refs.formDespesaFrete.resetValidation();
    }
  }

  @Watch("item")
  Item() {
    if (this.$refs.formCadastroPedido) {
      this.$refs.formCadastroPedido.resetValidation();
    }
  }
  
  @Watch("parcela")
  Parcela() {
    if (this.$refs.formParcelas) {
      this.$refs.formParcelas.resetValidation();
    }
  }
  
  ObterUsuarioLogado(){
    this.item.conferente = JSON.parse(localStorage.sessionApp.split(".")).dados.usuarioNome; 
  }

  overlay:boolean = false;

  Salvar(val:any) {
    if (this.$refs.formDespesa.validate()) {
      if (!this.despesa.parcelas[0]) {
        this.$swal("Aviso", "Atenção, É necessário que o usuário gere as parcelas","warning");
      } else if (!this.despesa.classificacoes[0]) {
        this.$swal("Aviso", "Atenção, É necessário que o usuário adicione as classificações.","warning");
      } else {
      this.item.itens.forEach((x) => { x.produto!.tipo = undefined });
      this.item.itens.forEach((x) => { x.situacao = undefined;});
      this.item.itens.forEach((x) => { x.produto = undefined;});
      this.item.tipo = undefined;
      this.item.situacaoFrete = undefined;
      this.item.empresa = undefined;
      this.item.fornecedor = undefined;
    
      this.overlay = !this.overlay;
      val && setTimeout(() => {
        
        let modelo = this.item;
        this.service.Salvar(modelo).then(
          (res) => {
            this.SalvarDespesa();
            this.$emit("salvou");
          },
          (err) => {
            if (err.response.status == 400) {
              this.$swal("Aviso", err.response.data.title, "warning");
              this.overlay = false;
            } else {
              this.$swal("Aviso", err.response.data, "error");
              this.overlay = false;
            }
          });
        }, 1000);
      }
    }
  }

  SalvarDespesa() {
    this.$swal({ 
      title: "Atenção!",
      text: "Deseja gerar as Despesas ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
       // this.item.id = 0;
       this.despesa.pedidos.push(this.item);

        //this.despesa.pedidoId = this.item.id;
        //this.despesa.valor = this.totalDespesa;
        this.despesa.origemId = EnumOrigemDespesa.PedidoCompra;

      console.log(this.despesa)
        let modelo = this.despesa;
         new DespesaService().Salvar(modelo).then(
          (res) => {
            this.$swal("Aviso", "Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
            this.$emit("salvou");
          },
          (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        });
          this.Close();
          this.$refs.formDespesa.resetValidation();
      },
      
    });
    this.overlay = false;
  }

  SalvarDespesaFrete() {
    if (this.$refs.formDespesaFrete.validate()) {
     this.despesaFrete.pedidoId = this.item.id;
     this.despesaFrete.origemId = EnumOrigemDespesa.PedidoCompra;

      let modelo = this.despesaFrete;
      new DespesaService().Salvar(modelo).then(
        (res) => {
          this.$swal("Aviso","Frete salvo com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.qtdeParcelasFrete = 0;
         // this.valorParcelaFrete = 0;
          this.despesaFrete = new Despesa();
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        });
    }
  }

  Close() {
    this.despesaFrete = new Despesa();
    this.despesa = new Despesa();
    this.totalDespesa = 0.00;
    this.dialog = false;
    this.overlay = false;
  }   

  GerarParcelasFrete() {
    if (this.$refs.formDespesaFrete.validate()) {
      if (this.parcelasIndexFrete == -1) {
        for (let i = 1; i <= this.qtdeParcelasFrete; i++) {
          let parcela = new DespesaParcela();
          let vencimento = new Date(Date.parse(`${this.parcela.dataVencimento}`));
          vencimento.setMonth(vencimento.getMonth() + i - 1);
          parcela.parcela = this.despesaFrete.parcelas.length + 1;
          parcela.valor = Number(this.despesaFrete.valor / this.qtdeParcelasFrete);
          parcela.linhaDigitavelBoleto = this.parcelaFrete.linhaDigitavelBoleto;
          parcela.situacaoId = this.parcelaFrete.situacaoId;
          parcela.dataVencimento = new Date(vencimento).yyyymmdd();
          this.despesaFrete.parcelas.push(parcela);
          this.parcelaFrete.valor = 0;
          this.parcelaFrete.linhaDigitavelBoleto ='';
        }
        this.parcelasIndexFrete = -1;
      }
      this.SalvarDespesaFrete();
    }
  }

  valorInicialParcelas: number = 0;
  numeroParcelas: number = 0;
  dataInicialParcelas: string = new Date().yyyymmdd();

  GerarParcelasAtualizadas(numeroParcelas: number = 0,dataInicialParcelas: string = "",valorInicialParcelas: number = 0) {
    this.despesa!.parcelas.length = 0;

    for (let i = 1; i <= numeroParcelas; i++) {
      let parcela = new DespesaParcela();

      let dataInicial = new Date(Date.parse(`${dataInicialParcelas} 00:00:00`));
      let dataCalculada = dataInicial;
      dataCalculada = new Date(new Date(dataInicial).setMonth(dataInicial.getMonth() + (i - 1)));
 
      this.valorInicialParcelas = 0;
      this.numeroParcelas = 0;
      parcela.parcela = i;
      parcela.valor = valorInicialParcelas;
      parcela.dataVencimento = dataCalculada.yyyymmdd();
      parcela.situacaoId = 1;
      this.despesa!.parcelas.push(parcela);
    }
  }

  EditarParcela(item) {
    this.dialogParcela = true;
    this.parcelaIndex = this.despesa.parcelas.indexOf(item);
    this.parcela = item;
  }

  ExcluirParcela(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
    }).then((result) => {
      if (result.value) {
        const index = this.despesa.parcelas.indexOf(item);
        this.despesa.parcelas.splice(index, 1);
        this.$swal("Aviso", "Registro excluído com sucesso!", "success");
      }
    });
  }

  FecharDialogParcela() {
    this.parcela = new DespesaParcela();
    this.dialogParcela = false;
  }

  AdicionarClassificacao() {
    if (this.$refs.formClassificacao.validate()) {
      if (this.classificacaoIndex > -1) {
        Object.assign(this.despesa.classificacoes[this.classificacaoIndex], this.despesaClassificacao)
      }else{

        if (!this.despesaClassificacao.centroCustoId || !this.despesaClassificacao.contaGerencialId || !this.despesaClassificacao.valor || !this.despesaClassificacao.percentual ) {
          this.$swal("Aviso","Campos obrigatórios não preenchidos.","warning"); 
          return;
        } 
        this.despesa.classificacoes.push(this.despesaClassificacao);
      }
        this.despesaClassificacao = new DespesaClassificacao();
        this.classificacaoIndex = -1;
      }
  }
 
  deleteClassificacao(item: DespesaClassificacao) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
    }).then((result) => {
      if (result.value) {
        const index = this.despesa.classificacoes.indexOf(item);
        this.despesa.classificacoes.splice(index, 1);
        this.$swal("Aviso", "Contato excluído com sucesso !", "success");
      }
    });
  }

  editClassificacao(item: DespesaClassificacao) {
    this.classificacaoIndex = this.despesa.classificacoes.indexOf(item);
    this.despesaClassificacao = Object.assign({}, item);
  }

  SituacaoColor(item){
    return SituacaoColor(item)
  }

  mounted() {
    new CentroCustoService().Listagem().then(
      (res) => {
        this.centrocustos = res.data;
      },
    );

    new ContaGerencialService().Listagem().then(
      (res) => {
        this.contagerenciais = res.data;
      },
    );
 
    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome', '').then(
      (res) => {
        this.listaPessoa = res.data.items;
      },
    );

    new SituacaoFreteService().ListarTudo().then(
      (res) => {
        this.situacaosFrete = res.data.items;
      },
    );

    new EmpresaService().Listar(-1, -1, ['nomeFantasia'],[],'',[], '', '' , 'Id,nome,nomeFantasia', '').then(
      (res) => {
        this.empresas = res.data.items;
      },
    );

    new TipoDespesaService().ListarTudo().then(
      (res) => {
        this.tiposDespesas = res.data.items;
      },
    );

    new TipoDocumentoService().ListarTudo().then(
      (res) => {
        this.tipoDocumentos = res.data.items;
      },
    );
   
    new SituacaoDespesaParcelaService().ListarTudo().then(
      (res) => {
        this.situacoes = res.data.items;
      },
    );

    new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome', '').then(
      (res) => {
        this.empreendimentos = res.data.items;
      },
    ); 
  }
}
