
import { PageBase } from '@/core/models/shared';
import { EmpresaService } from '@/core/services/compras/EmpresaService';
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class RelatorioPage extends PageBase {
    @Prop() private headers!: {};
    @Prop() private lista!: {};

    @Prop() private titulo!: string;
    @Prop() private cabecalhoCompleto?: boolean;
    @Prop() private marcaDagua?: string;
    @Prop() private paisagem?: boolean;
    @Prop() private exportarExcel?: boolean;
    @Prop() private empresaId?:any;

    data: any = Date.now;
    logo:string= "";

     @Watch("empresaId")
     async ObterLogoEmpresa() {
        if (!this.empresaId) {
            return;
        }

        const [primeiroNumero] = this.empresaId.split(",");
        if (this.empresaId.split(",").length === 1) {
            try {
            const res = await new EmpresaService().CarregarFoto(parseInt(primeiroNumero));
            this.logo = res.data;

            } catch (error) {
                this.$swal("Aviso", "Não foi possível carregar a logo.", "warning");
            }
        } else {
            this.logo = "";
        }
    }

    mounted() {
        let root = document.documentElement;
        if(this.paisagem){
            root.style.setProperty("--widthPage", 29.7 + 'cm'); //45 //29.7
            root.style.setProperty("--min-heightPage", 19 + 'cm');
            this.setPageSize('landscape')
        }       
        else{
            root.style.setProperty("--widthPage", 21 + 'cm');
            root.style.setProperty("--min-heightPage", 10 + 'cm');
            this.setPageSize('portrait')
        }        
    }

    setPageSize(size: string){
        const style = document.createElement('style');
        style.innerHTML = `@page {size: ${size}}`;
        document.head.appendChild(style);
    }

    Imprimir(){
        window.print();
    }

    Fechar(){
        window.close();
    }
}
