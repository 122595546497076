
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ContaCorrenteService, ReceitaService, RemessaService, TipoRemessaService} from "@/core/services/financeiro";
import { Remessa } from "@/core/models/financeiro";
import * as jsonpatch from 'fast-json-patch';

@Component
export default class CadastroRemessa extends Vue {
  @Prop() public item!: Remessa;
  @Prop() public value!: string;
  
  service = new RemessaService();
  receitaService = new ReceitaService();

  filter = new Model();
  totalLista:number = 0;
  contaCorrente:any = null;
  loading:boolean = false;
  valid:boolean = true;
  dialog:boolean = false;
  errorAlert:boolean = true;
 
  lista = [];
  selectParcelas:any = [];
  listaContasCorrente = [];
  tiposRemessa = [];
  listaCnab = [{id:0, nome:"CNAB240"}, {id:1, nome:"CNAB400"},{id:2, nome:"CNAB150"}]

  options: any = {
    itemsPerPage: 50,
  };

  @Watch('contaCorrente')
  ContaCorrente(codigo){
    if(codigo == null)
     return;

    this.filter.contaCorrenteId = this.contaCorrente.id;
    this.item.contaCorrenteId = this.contaCorrente.id;
    this.item.numeroSequencia = this.contaCorrente.remessaSequencia;
  }

  observer! : jsonpatch.Observer<Remessa>; 
  fieldRules: any[] = [(v: any) => (!!v) || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
  };

  headers: any[] = [ 
    { text: "Parcela", value: "parcela.parcela"},
    { text: "Vencimento", value: "vencimento"},
    { text: "Valor", value: "valor"},
    { text: "Saldo", value: "parcela.saldo"},
    { text: "Valor Pago", value: "parcela.valorPago"},
    { text: "Situação", value: "situacao.nome"},
    { text: "Desconto", value: "desconto", sortable: false},
    { text: "Juros", value: "juros", sortable: false},
    { text: "Multa", value: "multa", sortable: false}
  ];  

  @Watch("item")
  Item() {
    if (this.$refs.form)
      this.$refs.form.resetValidation();
  } 

  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }else {
      jsonpatch.unobserve(this.item, this.observer);
    } 
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  } 

  Consultar(){
    if(!this.filter.dataVencimentoInicial || !this.filter.dataVencimentoFinal || !this.filter.contaCorrenteId){
      this.$swal({title: 'Aviso', html: `<div style="text-align: left;"> Para prosseguir, é obrigatório informar nos filtros: ${this.filter.dataVencimentoInicial == '' ? "<br> - Vencimento inicial" : ''} ${this.filter.dataVencimentoFinal == '' ? "<br> - Vencimento final" : ''}  ${this.filter.contaCorrenteId == 0 ? "<br> - Conta Corrente" : ''} </div>`, icon: "warning"}); 
      return;
    }

    if(this.item.tipoId == 2){
      this.filter.situacaoId = 2;
    }
    
    const { page, itemsPerPage, sortBy, sortDesc } = this.options;
    this.loading = true;    
    this.receitaService.ListarTransacoes(page, itemsPerPage, ['vencimento'], sortDesc, '', this.headers, this.filter, '' , '', 'Parcela.Baixas, Situacao').then(
      res => {
        this.lista = res.data.items
        this.totalLista = res.data.count;
      },
      (err) => {  
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
    .finally(() => (this.loading = false)); 
  } 

  Processar(){
    this.service.Processar(this.item, this.item.id).then(
      (res) => {
        this.$swal("Aviso", "Operação realizada com sucesso!", "success");
        this.$emit("salvou");
        this.Close();
        this.DownloadArquivo(res.data);
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }
    )
  }
 
  SalvarProcessar(){
    this.$refs.form.validate(); 

      if(this.item.nome == "" || this.item.tipoId == 0 || this.item.contaCorrenteId == 0){
        this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
        return;
      }
  
      this.item.transacoes = this.selectParcelas
      let modelo = this.item;   
      this.service.SalvarProcessar(modelo).then(
        (res) => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
          this.DownloadArquivo(res.data);
          this.AtualizarContaCorrente();
        },  
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        }
      )
  }

  Salvar() {
    this.$refs.form.validate();

      if(this.item.nome == "" || this.item.tipoId == 0 || this.item.contaCorrenteId == 0){
        this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
        return;
      }

      this.item.transacoes = this.selectParcelas;
      let pacthModel = jsonpatch.generate(this.observer);

      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close(); 
        this.AtualizarContaCorrente();
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }
    )
  }

  Close() {
    this.dialog = false;
    this.contaCorrente = null;
    this.filter = new Model();
    this.selectParcelas = [];
    this.lista = [];
  } 
 
  DownloadArquivo(arquivo){
    const link = document.createElement("a");
    link.href = `data:${arquivo.tipo};base64,${arquivo.dados}`;
    link.type = arquivo.tipo;
    link.download = arquivo.nome;
    link.click();
    link.remove();
  };

  AtualizarContaCorrente(){
    const contaCorrenteService = new ContaCorrenteService();
      contaCorrenteService.Listar(-1, -1, ['nome'],[],'',[], '', '' , '', 'Banco').then(
        (res) => {
          this.listaContasCorrente = res.data.items;
        } 
      )
  }

  mounted(){
    this.AtualizarContaCorrente();

    const tiposRemessa = new TipoRemessaService();
      tiposRemessa.ListarTudo().then(
        (res) => {
          this.tiposRemessa = res.data.items;
        }
      )
    }
}

class Model {
  situacaoId = 1;
  contaCorrenteId:number = 0;
  dataVencimentoInicial:string = "";
  dataVencimentoFinal:string = "";
  boletoGerado:boolean = true;
}
