
import { Orcamento, OrcamentoFornecedor, OrcamentoItem } from '@/core/models/compras';
import { ProdutoService } from '@/core/services/almoxarifado';
import { PessoaService } from '@/core/services/cadastros';
import { OrcamentoService } from '@/core/services/compras/OrcamentoService';
import { FormaPagamentoService } from '@/core/services/financeiro';
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
 
@Component
export default class DetalharOrcamento extends Vue {
  @Prop() public item!: Orcamento;
  @Prop() public value!: string;

  service = new OrcamentoService();
  itemOrcamento: OrcamentoItem = new OrcamentoItem();

  fornecedores = [];
  produtosx:any = [];
  formaPagamentos = [];
  expanded = []; 
  produtos = [];
    
  singleExpand:boolean = false;
  expand: boolean = false;
  valid = true;
  dialog = false;

  headersFornecedoresCotacao: any[] = [
    {text: "Fornecedor", value: "fornecedorId",},
    { text: "Forma Pag.", value: "formaPagamentoId" },
    { text: "Observação", value: "observacao" },
    { text: "Prazo de Entrega ", value: "prazoEntregaDias" },
    { text: "Validade da Proposta", value: "validadeDias" },
    { text: "Frete", value: "frete" },
    { text: "Total", value: "total" },
    { text: "Aprovar", value: "aprovado" }
  ];
 
  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
    this.UsuarioLogado();
    this.produtosx = this.item.itens;
  }

   
  UsuarioLogado(){
    this.item.aprovacao!.usuarioId = JSON.parse(localStorage.sessionApp.split(".")).dados.usuarioId;
  }

  /* Salvar e fechar um Orçamento */
  Aprovar() {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja  Aprovar o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.service.AprovarOrcamento(this.item.id).then(
          (res) => { 
            this.$swal("Aviso","Operação realizada com sucesso!",
              res.status == 201 || res.status == 200 ? "success" : "warning"
            );
            this.$emit("salvou");
          },
            (err) => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }),
       this.Close()
      },
    }) 
  }

  AprovarFornecedor(item:OrcamentoFornecedor){
    if(item.aprovado == true){
      item.aprovado = false;
    }else if(item.aprovado == false){ 
      for(let i = 0; i < this.item.fornecedores.length; i++){
       this.item.fornecedores[i].aprovado = false
      }
      item.aprovado = true
    }  
  } 
   
  Salvar() {
    this.item.classificacao = undefined;
    this.item.fornecedores.forEach((x) => ( x.fornecedor = undefined));
    this.item.fornecedores.forEach((x) => { x.formaPagamento = undefined});
    this.item.itens.forEach((itemOrcamento) => {itemOrcamento.itemFornecedores.forEach((x) => {x.fornecedor = undefined})});
    this.item.itens.forEach((x) => {x.produto  = undefined}); 

    this.service.Salvar(this.item).then( 
      (res) => {
        this.service.AprovarOrcamento(this.item.id).then(
          (res) => { 
            this.$emit("salvou");
            this.Close()
          },  
         (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        }
      )},
    );
  }

  Close() {
    this.expanded = [];
    this.dialog = false;
  }

  mounted() {
    new ProdutoService().ListarTudo().then().then(
      res =>{
        this.produtos = res.data.items
      }
    )
    new FormaPagamentoService().ListarTudo().then(
    (res) => {
        this.formaPagamentos = res.data.items;
    });

    new PessoaService().ListarTudo().then(
      (res) => {
        this.fornecedores = res.data.items;
    });

  } 
}
