
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';
import { TabelaValorConsumoAgua, TabelaValorConsumoAguaItem } from "@/core/models/agua";
import { TabelaValorConsumoAguaService } from "@/core/services/agua";

@Component
export default class CadastroConsumo extends Vue {
  @Prop() public item!: TabelaValorConsumoAgua;
  @Prop() public value!: string;

  service = new TabelaValorConsumoAguaService();
  valid = true;
  dialog = false;

  tabelaValorConsumoItem = new TabelaValorConsumoAguaItem();
  itemIndex = -1; 

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
    formItem:HTMLFormElement;
  }; 
 
  headers = [
    { text: '', align: 'start', sortable: false, value: 'actions'},
    { text: 'Nome', value:'nome'},
    { text: 'Consumo Inicial', value: 'faixaConsumoInicial' },
    { text: 'Consumo Final', value: 'faixaConsumoFinal' },
    { text: 'Valor', value: 'valor' },
    { text: 'Valor Fixo', value: 'isValorFixo' },
  ]

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation(); 
    } 
  }

  @Watch("tabelaValorConsumoItem")
  TabelaValorConsumoItem() {
    if (this.$refs.formItem) {
      this.$refs.formItem.resetValidation(); 
    } 
  }

  observer! : jsonpatch.Observer<TabelaValorConsumoAgua>; 

  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }


  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  } 
   
  Salvar() {
    if (this.$refs.form.validate()) {
      if(this.item.itens.length == 0){
        return this.$swal("Aviso", "É necessário adicionar um ou mais itens na lista!", "warning");
      }

      let pacthModel = jsonpatch.generate(this.observer);
      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close(); 
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
    }
  }

  AdicionarItem(){
    if (this.$refs.formItem.validate()) {
      if (this.itemIndex > -1) {
        Object.assign(this.item.itens[this.itemIndex], this.tabelaValorConsumoItem)
      }else{
        this.item.itens.push(this.tabelaValorConsumoItem);
      }
      this.tabelaValorConsumoItem = new TabelaValorConsumoAguaItem();
      this.itemIndex = -1;
    }
  }

  ExcluirItem(item){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
    }).then((result) => {
      if (result.value) {
        const index = this.item.itens.indexOf(item);
        this.item.itens.splice(index, 1);
        this.$swal("Aviso", "Registro excluído com sucesso !", "success");
      }
    });
  }

  EditarItem(item){
    this.itemIndex = this.item.itens.indexOf(item);
    this.tabelaValorConsumoItem = Object.assign({}, item);
  }

  Close() {
    this.dialog = false;
  }

  mounted(){
  
  }
}
