
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { EnumSituacaoReceitaParcela, EnumTipoReceita, Receita,ReceitaParcela, ValoresAdicionais,} from "@/core/models/financeiro";
import { SituacaoReceitaParcelaService, ReceitaService } from "@/core/services/financeiro";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { CalcularDataParcela, ObterMelhorDia, SituacaoColor, TratarErroApi } from '@/assets/scripts/helper';
 
@Component
export default class GerenciamentoReceita extends Vue {
  @Prop() public item!: Receita;
  @Prop() public receitaEntrada!:Receita;
  @Prop() public receitaIntermediaria!:Receita;
  @Prop() public value!: string;
     
  service = new ReceitaService(); 
  parcela = new ReceitaParcela();
  receita = new Receita();
  valoresParcela = new ReceitaParcela();

  situacaoParcelas = [];
  listaEmpresa = [];
  
  dialogValoresAdicionais:boolean = false; 
  dialogParcela:boolean = false; 
  dialog:boolean = false;
  isLoading: boolean = false;


  numeroParcelas:number = 0;
  parcelaIndex:number = -1;
   
  headerParcelas: any[] = [
    { text: "", value: "actions", sortable: false},
    { text: "Nº Parcela", value: "parcela", sortable: false},
    { text: "Valor", value: "valor", sortable: false},
    { text: "Vencimento", value: "dataVencimento", sortable: false},
    { text: "Situação", value: "situacaoId", sortable: false}
  ];

  @Watch("dialog")
  Dialog() { 
    if (!this.dialog) {
      this.$emit("fechou");  
    }else{
      this.ObterMelhorDia(this.item.dataInicialParcelas);
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 
  }

  @Watch("item.gerarFinanciamentoPosEntrada")
  FinanciamentoPosEntrada(){
    if(this.item.gerarFinanciamentoPosEntrada == true){
      const ultimaParcela = this.receitaEntrada.parcelas[this.receitaEntrada.parcelas.length - 1];
      
      if(ultimaParcela){
        const dataVencimentoParcela = new Date(ultimaParcela.dataVencimento);
        dataVencimentoParcela.setMonth(dataVencimentoParcela.getMonth() + 1);
        this.item.dataInicialParcelas = new Date(dataVencimentoParcela).yyyymmdd();
      }else{
        this.item.dataInicialParcelas = new Date().yyyymmdd();
      } 
    }
  }

  @Watch("item.dataInicialParcelas")
  ObterMelhorDia(value){
    const dataVencimentoParcela = new Date(value);
    dataVencimentoParcela.setDate(dataVencimentoParcela.getDate() + 1);
    this.item.melhorDia = dataVencimentoParcela.getDate();
  }
  
  Salvar(){
    if(this.item.empresaId == 0)
      return this.$swal("Aviso","É necessário selecionar a Empresa.", "warning");
    
    if(this.item!.parcelas.length == 0)
      return this.$swal("Aviso","É necessário gerar as parcelas.", "warning");
      
    this.Close();
  }  

  Reset(){
    this.item.parcelas.forEach((x:any) => { x.id = 0; x.receitaId = 0; x.contratoId = 0; x.situacao = null; x.tipoServicoId = null; x.ultimaDataPagamento = null; x.ultimaFormaPagamento = null}) 
    this.numeroParcelas = 0;
    this.item.dataInicialParcelas = new Date().yyyymmdd();
    this.item.valoresAdicionaisParcela = new ValoresAdicionais();
  }
 
  Close() {
    this.Reset();
    this.dialog = false;
  }
 
  ObterPrice(numeroParcelas: number = 0, dataInicialParcelas: string = ""){ 
        this.isLoading = true;

    //if(numeroParcelas == 0){
     // this.isLoading = false
     // return this.$swal("Aviso", "É necessário colocar a quantidade de números de parcelas", "warning" );
   // }
  
      this.item.parcelas!.length = 0;
    
     this.service.ParcelamentoPrice(this.item.valor,this.item.jurosAmortizacao,numeroParcelas, this.item.valoresAdicionaisParcela.isDFIFixo, this.item.valoresAdicionaisParcela.percentualMPI,this.item.valoresAdicionaisParcela.percentualDFI,this.item.valoresAdicionaisParcela.gestao).then(
      (res) => {
        this.GerarParcelas(res.data, dataInicialParcelas);
        
      },
      (err) => { 
        TratarErroApi(err);
       
      }
    ).finally(() => this.isLoading = false)
  }

  GerarParcelas(resultado, dataInicialParcelas){
    for(let i = 0; i < resultado.length; i++){
      let parcela = new ReceitaParcela();

      let dataInicial = new Date(Date.parse(`${dataInicialParcelas} 00:00:00`));      
      const dia = ObterMelhorDia(dataInicial, this.item.melhorDia, i+1);

        parcela.parcela = resultado[i].parcela;
        parcela.dataVencimento = CalcularDataParcela(dataInicial, 0, i+1, dia, 0,).yyyymmdd();;
        parcela.dataVencimentoOriginal =  parcela.dataVencimento;
        parcela.valor = resultado[i].valorParcela.toFixed(2);
        parcela.saldoAmortizacaoPeriodoOriginal = resultado[i].saldoInicialPeriodo;
        parcela.saldoAmortizacaoInicioPeriodoCorrigido = resultado[i].saldoInicialPeriodo;
        parcela.saldoAmortizacaoFimPeriodoCorrigido = resultado[i].saldoFinalPeriodo;
        parcela.situacaoId = EnumSituacaoReceitaParcela.Aberto;
        parcela.juros = resultado[i].juros;
        parcela.amortizacao = resultado[i].amortizacao;
        parcela.valoresAdicionais = resultado[i].valoresAdicionais;
        parcela.valoresAdicionais.taxaBoleto = this.item.valoresAdicionaisParcela.taxaBoleto;

         if (this.item.tipoId == EnumTipoReceita.Titulo) {
            const intermediariaParcelas = this.receitaIntermediaria.parcelas;
            let vencimentoCalculadoAux = new Date(parcela.dataVencimento);

            if (this.item.parcelas.length > 0) {
              vencimentoCalculadoAux = new Date(this.item.parcelas[this.item.parcelas.length - 1].dataVencimento);
              vencimentoCalculadoAux.setMonth(vencimentoCalculadoAux.getMonth() + 1);
            }

            if (intermediariaParcelas && this.item.gerarFinanciamentoComIntermediaria) {
                intermediariaParcelas.forEach(x => {
                  const dataIntermediaria = new Date(x.dataVencimento);
                    if (dataIntermediaria.getMonth() === vencimentoCalculadoAux.getMonth() && dataIntermediaria.getFullYear() === vencimentoCalculadoAux.getFullYear()) {
                      vencimentoCalculadoAux.setMonth(vencimentoCalculadoAux.getMonth() + 1);
                    }
                });
            }

            parcela.dataVencimento = vencimentoCalculadoAux.yyyymmdd();
            parcela.dataVencimentoOriginal = parcela.dataVencimento;
        }

      /* if(this.item.tipoId == EnumTipoReceita.Titulo){
          const intermediariaParcelas = this.receitaIntermediaria.parcelas;
          let vencimentoCalculadoAux;

          if(this.item.parcelas.length > 0){
            vencimentoCalculadoAux = new Date(this.item.parcelas[this.item.parcelas.length -1].dataVencimento);
            vencimentoCalculadoAux = new Date((vencimentoCalculadoAux).setMonth(new Date(vencimentoCalculadoAux).getMonth() + 1));
            vencimentoCalculadoAux = new Date((vencimentoCalculadoAux).setDate(dia -1))
          }
          else
          {
           vencimentoCalculadoAux = new Date(parcela.dataVencimento);
          }

        if(intermediariaParcelas && this.item.gerarFinanciamentoComIntermediaria == true){
          if(intermediariaParcelas.some(x=>new Date(x.dataVencimento).getMonth() == new Date(vencimentoCalculadoAux).getMonth() && new Date(x.dataVencimento).getFullYear() == new Date(vencimentoCalculadoAux).getFullYear()))
            {
                intermediariaParcelas.forEach(x => {
                  if(new Date(vencimentoCalculadoAux).getMonth() ==  new Date(x.dataVencimento).getMonth() && new Date(vencimentoCalculadoAux).getFullYear() ==  new Date(x.dataVencimento).getFullYear()){
                    vencimentoCalculadoAux = new Date((vencimentoCalculadoAux).setMonth(new Date((vencimentoCalculadoAux).getMonth() +1)))
                  }      
                })
            }
        } 

        parcela.dataVencimento = new Date(vencimentoCalculadoAux).yyyymmdd();
        parcela.dataVencimentoOriginal = parcela.dataVencimento;
      };*/

      this.item!.parcelas.push(parcela);
    }

    this.ResetParcela();
  } 

  ExcluirParcela(item) {
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja excluir o registro atual?",
    icon: "question",
    showCancelButton: true, 
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm:true,
      preConfirm:() => {
        const index = this.item.parcelas!.indexOf(item);
        this.item.parcelas!.splice(index, 1);
        return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
      }
    })
  } 
 
  EditarParcela(item) {
    this.parcela = item;
    this.parcela.dataVencimento = new Date(item.dataVencimento).yyyymmdd();
    this.dialogParcela = true;
  }

  Confirmar(){
   // Obtenha o índice da parcela editada na lista item.parcelas
    let indiceParcelaEditada = this.item.parcelas.indexOf(this.parcela);
    let valorParcelaEditada = this.parcela.valor;

    // Calcule o valor total das parcelas anteriores à parcela editada
    let valorParcelasAnteriores = 0;
    for (let i = 0; i < indiceParcelaEditada; i++) {
      valorParcelasAnteriores += this.item.parcelas[i].valor;
    }

    // Calcule o saldo restante após a edição da parcela
    let saldoRestante = this.item.valor - valorParcelasAnteriores - valorParcelaEditada;
    
    // Atualize as parcelas posteriores à parcela editada
    for (let i = indiceParcelaEditada + 1; i < this.item.parcelas.length; i++) {
      let parcela = this.item.parcelas[i];
      let saldo =  (saldoRestante > 0 ? saldoRestante : 0) / (this.item.parcelas.length - (indiceParcelaEditada + 1));
      parcela.valor = parseFloat(Number(saldo).toFixed(2)); 
    }

    this.item.valor = Number(this.item.parcelas.reduce((total, parcela) => total + parcela.valor, 0).toFixed(2)); 
    this.parcela = new ReceitaParcela();
    this.dialogParcela = false;
  }
  
  AbrirDialogValorAdicional(item){
    this.valoresParcela = item;
    this.receita = this.item;
    this.dialogValoresAdicionais = true;
  }

  SituacaoColor(item:ReceitaParcela){
    return SituacaoColor(item);
  }

  ResetParcela(){
    this.item.gerarFinanciamentoComIntermediaria = false;
    this.item.gerarFinanciamentoPosEntrada = false;
    this.numeroParcelas  = 0;
    this.item.valoresAdicionaisParcela = new ValoresAdicionais();
  }

  mounted() {
    new SituacaoReceitaParcelaService().ListarTudo().then(
      res => {
        this.situacaoParcelas = res.data.items;
     });

    new EmpresaService().Listar(-1, -1, ['nomeFantasia'],[],'',[], '', '' , 'Id, nomeFantasia', '').then(
      res => {
        this.listaEmpresa = res.data.items;
    });
  } 
}
