
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ProdutoService, UnidadeMedidaService,TipoProdutoService,GrupoProdutoService} from "@/core/services/almoxarifado";
import { Produto } from "@/core/models/almoxarifado";

@Component
export default class CadastroProduto extends Vue {
  @Prop() public item!: Produto;
  @Prop() public value!: string;

  service = new ProdutoService();
  tipos: any[] = [];
  unidadeMedidas: any[] = [];
  grupos: any[] = [];

  valid = true;
  dialog = false;
  clearable= false

  urlImgDoc: string = "";
  lerArquivo: any;
  arquivo = null;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: { form: HTMLFormElement };

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    } 
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  } 

  /* fazer o upload de um Arquivo */
  async AdicionarArquivo() {
    this.lerArquivo = this.arquivo;
    let src: string = <string>await this.lerImagem(this.lerArquivo);
    this.urlImgDoc = src;

    this.item.foto = this.item.foto != "" ? this.item.foto : this.lerArquivo.name;
    this.item.foto = this.urlImgDoc.replace(/^[^,]*,/, "");
  }

  lerImagem(imagem: any) {
    return new Promise((resolve, reject) => {
      let fr = new FileReader();
      fr.onload = (file: any) => {
        let retorno = file!.target!.result;
        resolve(retorno);
      };

      fr.readAsDataURL(imagem);
    });
  }

  Salvar() {
    if (this.$refs.form.validate()) {
      this.item.almoxarifadoItens.forEach((x) => {
        x.almoxarifado = undefined;
      });
      this.item.produto = undefined;
      this.item.grupo = undefined;
      this.item.unidadeMedida = undefined;
      this.item.tipo = undefined;
     
      let modelo = this.item;

      this.service.Salvar(modelo).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }); 
      this.arquivo = null;
       
    }
  }

  Close() {
    this.dialog = false;
    this.arquivo = null
  }
  excluirAvatar(item){
    this.item.foto = "";
    this.arquivo = null
  }

  mounted() {
    let tipoService = new TipoProdutoService();
    tipoService.ListarTudo().then(
      (res) => {
        this.tipos = res.data.items;
      },
    );

    let grupoService = new GrupoProdutoService();
    grupoService.ListarTudo().then(
      (res) => {
        this.grupos = res.data.items;
      },
    );

    let unidadeService = new UnidadeMedidaService();
    unidadeService.ListarTudo().then(
      (res) => {
        this.unidadeMedidas = res.data.items;
      },
    );
  }
}
