
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import { RequisicaoService,AlmoxarifadoService,ProdutoService,UnidadeMedidaService } from "@/core/services/almoxarifado";
import { Requisicao,RequisicaoItem } from "@/core/models/almoxarifado";
import { EmpreendimentoService } from "@/core/services/cadastros";
import { Etapa } from "@/core/models/gestao";
import { EtapaService } from "@/core/services/gestao";
import * as jsonpatch from "fast-json-patch";

@Component
export default class CadastroRequisicao extends Vue {
  @Prop() public item!: Requisicao;
  @Prop() public value!: string;

  requisicaoItem = new RequisicaoItem();
  service = new RequisicaoService();
  produtoService = new ProdutoService();

  observer!: jsonpatch.Observer<Requisicao>;

  itemIndex: number = -1;

  empreendimentos = [];
  unidades = [];
  listaProdutos = [];
  almoxarifados = [];
  etapas = [];

  saldo = 0;

  ObterSaldo(produto){
    if(produto){
      this.saldo = produto.quantidade;
      this.requisicaoItem.produtoId = produto.produtoId;
      this.requisicaoItem.unidadeMedidaId = produto.produto.unidadeMedidaId;
      this.requisicaoItem.custoMedio = produto.produto.precoCustoMedio;
    }
  }
   
 

  valid = true;
  dialog = false;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  
  $refs!: {
    formRequisicao: HTMLFormElement;
    formClassificacao: HTMLFormElement;
  };

  itemExcluido(item:any){
    if(item.excluido)
    return 'itemExcluido'
    else
    return ''
  }

  @Watch("item")
  Item() {
    if (this.$refs.formRequisicao) {
      this.$refs.formRequisicao.resetValidation();
    } 
  }

  @Watch("requisicaoItem")
  RequisicaoItem() {
    if (this.$refs.formClassificacao) {
      this.$refs.formClassificacao.resetValidation();
    } 
  }

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Etapa", value: "etapaId" },
    { text: "Produto", value: "produtoId" },
    { text: "Unidade", value: "unidadeMedidaId" },
    { text: "Quantidade", value: "quantidade" },
    { text: "Preço", value: "custoMedio" },
  ];

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 
    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    } else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
    this.ObterUsuario();
  } 

  ObterUsuario(){
    this.item.solicitante = JSON.parse(localStorage.sessionApp.split(".")).dados.usuarioNome;
  }


  CarregarProdutos(id){
    new AlmoxarifadoService().ObterPorId(id, "Itens.Produto").then(
      (res) => { 
        this.listaProdutos = res.data.itens;
       }
    )
  }
  
  Adicionar() {
    if (this.$refs.formClassificacao.validate()) {
      if(this.item.itens.some(x => x.produtoId == this.requisicaoItem.produtoId && (x.etapaId == this.requisicaoItem.etapaId))){
        this.$swal("Aviso","Esse produto já foi incluído na lista!","warning"); 
        return;
      }

      if (this.itemIndex > -1) {
        Object.assign(this.item.itens[this.itemIndex], this.requisicaoItem);
      } else {

        var objetoClone:any = Object.assign({}, this.requisicaoItem);

        const etapa:any = this.etapas.find((x:any) => x.key == this.requisicaoItem.etapaId);
         objetoClone.etapa = new Etapa();
         objetoClone.etapa.id = etapa.key;
         objetoClone.etapa.nome = etapa!.value;

        this.item.itens.push(objetoClone);
        
      }
      this.itemIndex = -1;

      this.requisicaoItem.id = 0;
      this.requisicaoItem.produtoId = 0;
      this.requisicaoItem.unidadeMedidaId = 0;
      this.requisicaoItem.custoMedio  = 0;
      this.requisicaoItem.quantidade = 0;
      this.saldo = 0;
      this.$refs.formClassificacao.resetValidation();
     }
  } 

  save() {
    if (this.itemIndex > -1) {
      Object.assign(this.item.itens[this.itemIndex], this.requisicaoItem);
    } else {
      this.item.itens.push(this.requisicaoItem);
    }
    this.CloseItem();
    this.$refs.formClassificacao.resetValidation();
  }

  CloseItem() {
    this.requisicaoItem = new RequisicaoItem();
    this.itemIndex = -1;
  } 

  editItem(item: RequisicaoItem) {
    this.itemIndex = this.item.itens.indexOf(item);
    this.requisicaoItem = Object.assign({}, item);
  }
  
  Editar(item: RequisicaoItem) {
    this.itemIndex = this.item.itens.indexOf(item);
    this.requisicaoItem = item;
  }

  Excluir(item: RequisicaoItem) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,  
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      preConfirm:() => {
        if(item.id > 0){
         item.excluido = true;
         const index = this.item.itens.indexOf(item);
         delete this.item.itens[index]
         this.dialog = false;
         this.dialog = true;
         return true;
        }else{
        const index = this.item.itens.indexOf(item);
        this.item.itens.splice(index, 1);
        return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
      }
    })
  }

  Salvar(executar: boolean = false) {    
    if (this.$refs.formRequisicao.validate()) {

    this.item.executada = executar;
    let pacthModel = jsonpatch.generate(this.observer);
    (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
      (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");            
        this.$emit("salvou"); 
        this.Close();
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
    });
    }
  }

  Close() {
    this.requisicaoItem = new RequisicaoItem();
    this.dialog = false;
    this.itemIndex = -1;
  }

  novoItem = "novoItem";
  editarItem = "editarItem";

  get formTitle() {
    return this.itemIndex === -1 ? this.novoItem : this.editarItem;
  }
  produtos = [];
  mounted() {   
     new ProdutoService().Listar(-1, -1, ['Nome'],[],'',[], '', '' , 'Id, Nome, IdNome', '').then(
      res => { this.produtos = res.data.items},
    ); 

    new UnidadeMedidaService().ListarTudo().then(
      res => {this.unidades = res.data.items}
    )

    new AlmoxarifadoService().ListarTudo().then(
      res => {this.almoxarifados = res.data.items}
    )

   new EtapaService().Listagem().then(
        res => { this.etapas = res.data
      }  
    ) 

     new EmpreendimentoService().ListarTudo().then(
      (res) => (this.empreendimentos = res.data.items)
    );
  }
}
