
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';
import { EmpreendimentoService, PessoaService } from "@/core/services/cadastros";
import { Hidrometro } from "@/core/models/agua";
import { Unidade } from "@/core/models/cadastros";
import { HidrometroService } from "@/core/services/agua";

@Component
export default class CadastroHidrometro extends Vue {
  @Prop() public item!: Hidrometro;
  @Prop() public value!: string;

  service = new HidrometroService();
  valid = true;
  dialog = false;
  dialogUnidades = false

  empreendimentos = [];
  grupos = []; 
  unidades = [];
  pessoas = [];
  listarTodasUnidades = [];
 
  empreendimentoId:number = 0;
  grupoId:number = 0;
  unidade = new Unidade();
  editedIndex = -1;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
  }; 

  headers = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Empreendimento', value: 'empreendimento'},
    { text: 'Quadra', value: 'quadra'},
    { text: 'Lote', value: 'nome'},
  ]

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation(); 
    } 
  }

  observer! : jsonpatch.Observer<Hidrometro>; 

  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    } 
  } 

  AdicionarUnidades(){
    if(!this.unidade){
      return this.$swal("Aviso", "É necessário selecionar a unidade!", "warning");
    }
 
    if (this.editedIndex > -1) {
      Object.assign(this.item.unidades[this.editedIndex], this.unidade);
      } else {
        let hidrometroUnidade = new HidrometroUnidade();
        hidrometroUnidade.unidadeId = this.unidade.id;
        this.item.unidades.push(hidrometroUnidade);
        this.ObterUnidades();
        this.empreendimentoId = 0;
        this.grupoId = 0;
      }
      
    this.unidade = new Unidade();
    this.editedIndex = -1; 
  }
 
  ObterGrupos(){
    new EmpreendimentoService().ListarGrupo(this.empreendimentoId).then(
      res => {
        this.grupos = res.data;
      }) 
  }
  
  ObterUnidades(){
    new EmpreendimentoService().ListarUnidade(this.grupoId).then(
      (res) => {
        this.unidades = res.data;
      })
  }

  ExcluirItem(item){
    this.$swal({
    title: "Atenção!",
    text: "Tem certeza que deseja excluir o registro atual?",
    icon: "question",
    showCancelButton: true,  
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
    showCloseButton: true,
    showLoaderOnConfirm:true,
    preConfirm:() => {
      const index = this.item.unidades.indexOf(item);
      this.item.unidades.splice(index, 1);
      this.ObterUnidades();
      return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
    },
    // @ts-ignore: Unreachable code error
    allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){

      }
    })
  }

  Salvar() { 
    if (this.$refs.form.validate()) {
      if(this.item.unidades.length == 0){
        return this.$swal("Aviso", "É necessário adicionar uma ou mais unidades na lista!", "warning");
      }

      let pacthModel = jsonpatch.generate(this.observer);
      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close(); 
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
    }
  }

  Close() {
    this.dialog = false;
    this.empreendimentoId = 0;
    this.grupoId = 0;
  }

  mounted(){
    const unidadeService = new EmpreendimentoService();
      unidadeService.ListarTodasUnidades2(-1, -1, ['nome'],[],'',[], '', '' , '',  'Grupo.Empreendimento').then(
        (res) => {this.listarTodasUnidades = res.data.items}
      )

    const empreendimentoService = new EmpreendimentoService();
      empreendimentoService.Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        (res) => {this.empreendimentos = res.data.items}
      )

    const pessoaService = new PessoaService();
      pessoaService.Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome, nomeCnpjCpf', '').then(
        (res) => {this.pessoas = res.data.items}
      )
  }
}

class HidrometroUnidade {
  public id:number = 0;
  public hidrometroId:number = 0;
  public unidadeId:number = 0;
}
