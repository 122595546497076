
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { MedicaoService} from "@/core/services/projeto";
import { Execucao, Medicao,} from "@/core/models/projeto";
import * as jsonpatch from "fast-json-patch"; 
import { EtapaService } from "@/core/services/gestao";
import { UsuarioService } from "@/core/services/cadastros";

@Component
export default class Execucoes extends Vue {
  @Prop() public item!: any;
  @Prop() public value!: string;

  observer!: jsonpatch.Observer<Medicao>;

  service: MedicaoService = new MedicaoService();

  documentoExecucao = new Execucao();
  editarExecucao = new Execucao();
    
  dialog: boolean = false;
  dialogCarrosel: boolean = false;
  dialogEditar:boolean = false;
  usuarios: any = [];
 
  valid = true;
  documentoIndex = -1;
 
  $refs!: {
    form: HTMLFormElement;
   };

  itemExcluido(item: any) {
    if (item.excluido) return "itemExcluido";
    else return "";
  }

  headersExecucao: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Etapa", value: "etapaId", sortable: false },
    { text: "Responsável", value: "responsavelId", sortable: false },
    { text: "Data", value: "data", sortable: false },
    { text: "Unidade", value: "quantidade", sortable: false },
    { text: "Valor p/unidade", value: "valorUnitario", sortable: false },
    { text: "Valor total", value: "total", sortable: false },
    { text: "Caução", value: "caucao", sortable: false },
    { text: "Observação", value: "observacao", sortable: false },
    { text: "Foto", value: "exibirCarrosel", sortable: false },
  ];

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
    if (this.dialog) {
      this.observer = jsonpatch.observe(this.item);
    } else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }
 
  Salvar(){
    let pacthModel = jsonpatch.generate(this.observer);
    
    (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
    (res) => {
      this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
      this.$emit("salvou");
     },

     (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      });
  }

  ExcluirExecucao(item){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        if (item.id > 0) {
          item.excluido = true;
          const index = this.item.execucoes.indexOf(item);
          delete this.item.execucoes[index];
          this.dialog = false;
          this.dialog = true;
          return true;
        } else {
          const index = this.item.execucoes.indexOf(item);
          this.item.execucoes.splice(index, 1);
          return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if (result.value) {
      }
    });
  }

  EditarExecucao(item){
    this.editarExecucao = item;
   // this.editarExecucao.data  = new Date(this.editarExecucao.data).yyyymmdd()
    this.dialogEditar = true;
  }

  AbrirCarrosel(item:Execucao){
    this.documentoExecucao = item
    this.dialogCarrosel = true;
  } 

  etapas = [];

  mounted() {
    new EtapaService().Listagem().then(
      (res) => this.etapas = res.data
    );

        new UsuarioService().ListarTudo().then((res) => {
      this.usuarios = res.data.items;
    });
  }  
}
