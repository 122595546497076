
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class VcardGraficoCircular extends Vue {
  @Prop() listaGraphs?: any[];
  @Prop() private value!: number;
  @Prop() private titulo!: string;
  @Prop() private tituloVerTodos!: string;
  @Prop() public color!: string;
  @Prop() public text!: string;
  @Prop() public index!: number;
  @Prop() public total!: number;
  @Prop() public progressClass!: string;
  @Prop({ default: () => [] }) items!: { text: string, value: number }[];
   
  valid = true;
  dialog = false;

  contratosQuitadosGraphs: any[] = [{
    1: {text: 'JAN', value: 20},
    2: {text: 'FEV', value: 66},
    3: {text: 'MAR', value: 99},
  }]

  $refs!: {
    form: HTMLFormElement;
  };

  computedOverlayClass(value) {
      const intensity = Math.min(1, value / 100); 
      const opacity = intensity * 10;
      const roundedOpacity = Math.round(opacity);
      const r = 0, g = 150, b = 136;
      return {
        'overlay-color': true,
        [`overlay-opacity-${roundedOpacity * 10}`]: true
      };
    }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("unidade")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  Close() {
    this.dialog = false;
  }

  openDialog() {
    this.dialog = true;
  }

  AtualizarLista(novoValor: any) {
    this.$emit("update:lista", novoValor);
  }
  
  @Watch("lista")
  ObservadorLista(novoValor: any) {
   // this.listaLotes = Object.assign([], this.lista);
  }

  unidades = [];

  mounted(){
   
  }
}
