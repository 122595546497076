
import { Usuario } from "@/core/models/cadastros";
import { Contato } from "@/core/models/geral";
import { UsuarioService } from "@/core/services/cadastros";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as jsonpatch from 'fast-json-patch';
import { ArquivoService } from "@/core/services/geral/ArquivoService";

@Component
export default class CadastroIndice extends Vue {
  @Prop() public item!: Usuario; 
  @Prop() public value!: string;

  service = new UsuarioService();
  complementar = new Contato();
  alterarSenha = new  AlterarSenha(); 
  dialogAlteraSenha:boolean = false;

  observer! : jsonpatch.Observer<Usuario>; 
  
  tabActive = null;
  arquivo = null;
  valid = true;
  dialog = false;
  show1 = false;
  show2 = false;
  show = false;
 
  password:string = "";  
  password2:string = "";
  novaSenha:string='';
  urlImgDoc: string = "";
  lerArquivo: any;
   
  Caracters = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
  };

  rules = {
    required: (value) => !!value || "Campo obrigatório.",
    min: (v) => (v.length >= 8 && !!this.Caracters.test(v)) || "Mínimo 8 caracteres incluindo 1 caracter especial  Ex: ! @ # $ % & * ( ) _  ",
    valid: (value) => value == this.alterarSenha.senhaNova || "As senhas não são iguais.",
    caracter: (value) => !!this.Caracters.test(value) || "Pelo menos 1 caracter especial",
  };

  RemoveImage(){
    this.item.foto = null; 
    this.arquivo = null;
  }

  async LoadImage(){
    if (!this.arquivo)
        return;

    const arquivoService = new ArquivoService();
    const dados = await arquivoService.Ler(this.arquivo);
    this.item.foto = dados.replace(/^[^,]*,/, "");
  } 
 
  excluirAvatar() { 
    this.item.foto = "";
    this.arquivo = null;
  }

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

 @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  Salvar() {
    let pacthModel = jsonpatch.generate(this.observer);
    (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
      (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close();
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      });
  }

  TrocarSenha(){
    if(this.$refs.form.validate()){
    this.alterarSenha.usuarioId = this.item.id
    let modelo = this.alterarSenha
    
    this.service.AlterarSenha(modelo).then(
      (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
      },
       (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        });
    }
  }

  Close() {
    this.dialog = false;
  }
}

class AlterarSenha {
  public usuarioId:number = 0;
  public senhaAntiga:string = "";
  public senhaNova:string = "";
}
