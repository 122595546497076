
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Empreendimento, Grupo, Unidade } from "@/core/models/cadastros";
import { EmpreendimentoService } from "@/core/services/cadastros";
import { Loader } from "@googlemaps/js-api-loader";
import toGeoJSON from "togeojson";
import { TratarErroApi } from "@/assets/scripts/helper";


@Component
export default class VisualizarMapa extends Vue {
  
  @Prop() public item!: Empreendimento;
  @Prop() public value!: string;


 


  unidade = new Unidade();
  service = new EmpreendimentoService();
  coordinatesLots: any = [];

  overlay = false;
  valid = true;
  dialog = false;
  dialogUnidade = false;
  unidadeKml: any = {};

  markers:any = [];
  overlayPositions:any = [];

  mapa:any;

  configMap = {
        center: { lat: -14.235, lng: -51.925 },
        zoom: 4,
        mapTypeId: google.maps.MapTypeId.SATELLITE,
        disableDefaultUI: false,
        zoomControl: true
      }

  loader = new Loader({
    apiKey: "AIzaSyCevPtst65sq0y7iDu1eW0chOPZmq2sSCc",
    version: "weekly",
  });

  regex = /^(https?:\/\/[^\/]+)/;
  url: any = window.location.href;

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    } else {
      this.ObterKml();
    }
  }

  Close() {
    this.dialog = false;
  //  this.FeatureType
  }

  async ObterUnidadeKML(unidadeKmlld: string) {
    if (unidadeKmlld) {
      new EmpreendimentoService().ObterUnidadeKml(unidadeKmlld, 'Imovel, Jazigo, Lote, Lote.Infraestrutura, Lote.LadosAdicionais').then(
        (res) => {
          this.unidade = res.data;
          this.unidade.empreendimentoId = this.item.id;
          this.dialogUnidade = true;
        },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
    }
  }

  InitMap()
  {
    this.mapa = new google.maps.Map(document.getElementById("map") as HTMLElement,this.configMap);
  }
 
  async ObterKml() {
    this.markers = [];
    
    this.loader.load().then(async () => {
      
      var origem = this.url.match(this.regex)[0].replace("http://", "");

      this.InitMap();

      const kmlUrl = origem == "development.scae.adm.br" || origem == "localhost:9091"  || origem == "demo.scae.adm.br" ? `http://api-development.scae.adm.br/api/empreendimento/${this.item.id}/kml?origem=${origem}` : `http://api.scae.adm.br/api/empreendimento/${this.item.id}/kml?origem=${origem}`;
      //const kmlUrl = origem == "development.scar.adm.br" || origem == "localhost:9091" || origem == "demo.scae.adm.br" ? `https://e7e3-186-231-125-12.ngrok-free.app/api/empreendimento/${this.item.id}/kml?origem=${origem}` : `http://api.scae.adm.br/api/empreendimento/${this.item.id}/kml?origem=${origem}`;
      let ctaLayer = new google.maps.KmlLayer({url: kmlUrl,map: this.mapa});  
      //console.log(ctaLayer);
      //console.log(await ctaLayer.get("map").get("features"));
      //console.log("layer");
      //console.log(ctaLayer.getMetadata());
      ctaLayer.addListener("click", (event) =>{
        //console.log("clickevent");
        //console.log(event.featureData);
        event.featureData.strokeColor = 'red';
        event.featureData.fillColor ='yellow';
        // this.ObterUnidadeKML(event.featureData.id);
        // console.log(event.featureData);
      });
      //return ctaLayer;
 
       fetch(kmlUrl).then((response) => response.text()).then((kmlText) => {
        const kmlParser = new DOMParser();
        const kmlDoc = kmlParser.parseFromString(kmlText, "application/xml");
        //console.log("NODE TYPE");
        //console.log(kmlDoc.nodeType);
        //console.log(Node.DOCUMENT_NODE);
        //const kmljson = this.converter(kmlDoc);
        //console.log(kmlDoc);
        //console.log(kmljson);
        const geojson = toGeoJSON.kml(kmlDoc);
        //console.log(geojson);
        let tag:string = "name-overlay";
        let situacao:number = 0;
        // console.log("features");
          if (geojson.features && geojson.features.length > 0) {
            for (const feature of geojson.features) {
              //console.log("features 2");
              //console.log(feature.properties.fill);
              feature.properties.fill = "#0000ff";
              //console.log("features after change");
              //console.log(feature.properties.fill);
              feature.properties.stroke = "#0000ff";
              if (feature.geometry && feature.geometry.type === "Polygon") {
                var polygon = feature.geometry.coordinates;
                
                const name = feature.properties.name;
                const position = this.GetPolygonCenter(polygon);
                //var marker = new google.maps.Marker({
                //  position: position,
                //  map: this.mapa,
                //  title: "Hello"
                //});
                //this.markers.push(marker);

                //console.log(position.toString());
                //feature.properties.fill = "#f6631e";
                // console.log(feature);

                for(let i = 0; i < this.item.grupos.length; i++)
                {
                  for(let j = 0; j < this.item.grupos[i].unidades.length; j++)
                  {
                    if(feature.id == this.item.grupos[i].unidades[j].kmlId)
                    {
                      //console.log("estou em situacao");
                      situacao = this.item.grupos[i].unidades[j].situacaoId;
                    }
                  }
                }
                if(situacao == 1)
                {
                  tag = "disponivel";
                }
                else
                {
                  tag = "indisponivel";
                }

                this.overlayPositions.push(position);

                const nameOverlay = new NameOverlay(position, name, this.mapa, tag);
                //console.log(`id: ${feature.properties}`);
                //Adicionar o número do lote ou quadra ao overlay
                // if (feature.properties.id && feature.properties.name) {
                //   console.log("chegou aqui");
                //   nameOverlay.overlayName += ' - ' + "nome";//feature.properties.name;
                //   nameOverlay.draw();
                // }
              }
            }
          }
        })
        .catch(
          (err) => TratarErroApi(err)
        );
    }).then(ctaLayer => {
      //console.log("layer");
      //console.log(ctaLayer);
      //console.log(ctaLayer.getMap()!.getDatasetFeatureLayer("kml:cu5QqSDtj6q4PwWSSwQ2Kz79SSpmr9RYGW9aHgAuLfxicFqpgcfkj59L6ySDluQQ"));
      })
    // let configMap = {
    //     center: { lat: -14.235, lng: -51.925 },
    //     zoom: 4,
    //     mapTypeId: google.maps.MapTypeId.SATELLITE,
    //   };

    // this.mapa = new google.maps.Map(document.getElementById("map") as HTMLElement,configMap);
    
    // this.overlayPositions.forEach(element => {
    //   const nameOverlay = new NameOverlay(element, "nome", this.mapa, "disponível")
    // })

    // this.markers.array.forEach(element => {
    //   google.maps.event.trigger(element, 'click');
    // });
  }

  converter(dom) {
    if (dom.nodeType === Node.TEXT_NODE) {
        return dom.nodeValue;
    }
    if (dom.nodeType === Node.DOCUMENT_NODE) {
        dom = dom.documentElement;
    }
    const obj:any = {};
    obj.nodeType = dom.nodeType;
    if (dom.nodeType === Node.ELEMENT_NODE) {
        obj.tagName = dom.tagName;
        obj.attributes = []; // Array.from(obj.attributes) gives us a lot of things we don't want
        for (let i = 0, len = dom.attributes.length; i < len; ++i) {
            const attr = dom.attributes[i];
            obj.attributes.push({name: attr.name, value: attr.value});
        }
        obj.children = [];
        if(dom.firstChild.nodeValue.toString().match("\n"))
        {
          //console.log(dom.firstChild.nodeValue.toString().match("\n"));
        }
        
        for (let child = dom.firstChild; child; child = child.nextSibling) {
            obj.children.push(this.converter(child));
        }
    } else {
        obj.nodeValue = dom.nodeValue;
    }
    return obj;
}

  GetPolygonCenter(polygon: any[]) {
    let minX = Infinity;
    let maxX = -Infinity;
    let minY = Infinity;
    let maxY = -Infinity;

    for (const ring of polygon) {
      for (const point of ring) {
        minX = Math.min(minX, point[0]);
        maxX = Math.max(maxX, point[0]);
        minY = Math.min(minY, point[1]);
        maxY = Math.max(maxY, point[1]);
      }
    }

    const centerX = (minX + maxX) / 2;
    const centerY = (minY + maxY) / 2;
    return new google.maps.LatLng(centerY, centerX);
  }
}

class NameOverlay extends google.maps.OverlayView {
  private position!: google.maps.LatLng;
  public overlayName!: string; // Renomeada para evitar conflito de nomes
  public classSituacaoUnidade!: string;
  private map!: google.maps.Map;
  private div!: HTMLDivElement | null;

 
  constructor(position: google.maps.LatLng, overlayName: string, map: google.maps.Map, tag: string) {
    super();
    this.position = position;
    this.overlayName = overlayName; // Atualizada para o novo nome da propriedade
    this.map = map;
    this.div = null;
    this.setMap(map);
    this.classSituacaoUnidade = tag;
  }
  
  onAdd() {
   
    const div = document.createElement("div");
    div.className = this.classSituacaoUnidade;
    div.innerHTML = this.overlayName;  
    this.div = div;

    const panes: any = this.getPanes();
    panes.overlayLayer.appendChild(div);
  }

  draw() {
    if (!this.div) return;

    const overlayProjection = this.getProjection();
    // console.log("projection");
    // console.log(overlayProjection);
    const position: any = overlayProjection.fromLatLngToDivPixel(this.position);

    this.div.style.left = position.x + "px";
    this.div.style.top = position.y + "px";

    //console.log("event");
    //google.maps.event.trigger(this, 'click');
  }

  onRemove() {
    if (this.div) {
      this.div.parentNode!.removeChild(this.div);
      this.div = null;
    } 
  }
}
