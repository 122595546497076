
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Pedido } from "@/core/models/compras";
import { PedidoService } from "@/core/services/compras/PedidoService";
import * as jsonpatch from 'fast-json-patch';
import { ArquivoService } from "@/core/services/geral/ArquivoService";
import { Documento } from "@/core/models/geral";

@Component
export default class XmlPedido extends Vue {
  @Prop() public item!: Pedido;
  @Prop() public value!: string;

  service = new PedidoService();   
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  
  arquivo:any = null;
  tabActive = 0;
  valid = true;
  dialog = false;
  
  loading: boolean = false;
  dialogItemAssociado:boolean = false;
  selectAll:boolean = false;

  observer! : jsonpatch.Observer<Pedido>;
  extrato:any = [];
  selectedItems:any = [];

  options: any = {
    itemsPerPage: -1
  };    

  updateSelectAll() {
    this.selectAll = this.selectedItems.length === this.extrato.length;
  }

  @Watch("selectedItems")
  SelectedItems() {
    this.updateSelectAll();
  }

  
  toggleSelectAll() {
    if (this.selectAll) {
      this.selectedItems = this.extrato.map(item => item);
    } else {
      this.selectedItems = [];
    }
  }
 
  headersPedido = [
    { text: "Código", value: "produto.codigo" },
    { text: "Nome", value: "produto.nome" },
    { text: "Qtde Pedida", value: "quantidade" },
    { text: "Qtde á Receber", value: "quantidadeAReceber" },
    { text: "Valor Unitário", value: "valorUnitario" },
    { text: "Total", value: "total" },
  ]; 

  ValidarFornecedorArquivo(fornecedorCNPJ) {
    if (this.item.fornecedor!.cpfCnpjLimpo != fornecedorCNPJ) {
      this.$swal("Aviso", "Fornecedor do Arquivo XML é diferente do fornecedor do pedido.", "error");
      return false;   
    }
    return true;   
  }

  CarregarDadosArquivo() { 
    if (!this.arquivo) 
      return;

    this.loading = true;

    this.service.LerArquivoXml(this.arquivo).then(
      (res) => {
        if (!this.ValidarFornecedorArquivo(res.data.fornecedorCNPJ)) 
         return this.$swal("Aviso", "O fornecedor especificado no XML difere do fornecedor no pedido.", "error");
        
        let conciliacoes = res.data.itens.map(x => ({
          codigo: x.codigo,
          nome: x.nome,
          produtoId: x.produtoId,
          valor: x.valor,
          quantidade: x.quantidade,
          produtoSugestao: x.produtoSugestao,
          codigoJaRegistrado: x.codigoJaRegistrado
        }));

        this.extrato = conciliacoes;
        this.loading = false;
      });
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  AtualizarPedido(){
    this.service.ObterPorId(this.item.id,'Empresa, Orcamento, Tipo, Fornecedor,SituacaoFrete, Itens.Produto,Itens.Situacao, Itens.Produto.Fornecedores').then(
      res => this.item = res.data
    )
  }
 
  Close() {
    this.tabActive = 0;
    this.arquivo = null;
    this.itemProduto = {};
    this.extrato = [];
    this.dialog = false;
  }

  itemProduto:any = {};

  async SalvarCodigosFornecedor() {
    if (this.selectedItems.length === 0) {
      this.$swal("Aviso", "É necessário selecionar um ou mais itens para associar", "warning");
      return;
    }

    for (const item of this.selectedItems) {
      if (!item.produtoSugestao) {
        this.$swal("Aviso", "É necessário associar todos os produtos!", "warning");
        return;
      }
      item.produtoId = item.produtoSugestao.id;
    }

    try {
      const arquivoXml = new Documento();
      const dados = await new ArquivoService().Ler(this.arquivo);
      arquivoXml.dados = dados.replace(/^[^,]*,/, "");
      arquivoXml.usuarioId = JSON.parse(localStorage.sessionApp.split(".")).dados.usuarioId;
      arquivoXml.nome = this.arquivo.name;
      arquivoXml.tamanho = this.arquivo.size;
      arquivoXml.tipo = this.arquivo.type;

      const modelo = {fornecedorCNPJ: this.item.fornecedor!.cpfCnpjLimpo,itens: this.selectedItems,arquivoXml};
      
      const res = await this.service.AssociarEReceberProdutos(this.item.id, this.item.fornecedorId, modelo);
      this.$swal("Aviso", "Operação realizada com sucesso!", res.status === 201 || res.status === 200 ? "success" : "warning");
      this.CarregarDadosArquivo();
      this.AtualizarPedido();

    } catch (err) {    
      this.$swal("Aviso", "Não foi possível acessar a API", "warning");
    }
  }

  DialogAssociarItem(item){
    this.itemProduto = item;
    this.dialogItemAssociado = true;
  }
}
