
import { Vue, Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { RelatorioService } from '@/core/services/financeiro';
import { EmpreendimentoService } from '@/core/services/cadastros';
import { EmpresaService } from '@/core/services/compras/EmpresaService';
import ListPage from "@/components/shared/ListPage";
import { TratarErroApi } from '@/assets/scripts/helper';
import GraficoPizzaComponent from '@/components/graficos/GraficoPizzaComponent.vue';
import { formatDate } from '@/assets/scripts/helper';

@Component
export default class Kpi extends mixins(Vue, ListPage) {
    item: any = new Model();
    isMobile: any = false;
    currentYear = new Date().getFullYear();
    currentMonth = new Date().getMonth();
    firstDayOfNextMonth = new Date(this.currentYear, this.currentMonth + 1, 1);
    lastDayOfCurrentMonth2 = new Date(this.firstDayOfNextMonth.getTime() - 1);
    lastDayOfCurrentMonth = new Date(this.firstDayOfNextMonth.getTime() - 1).getDate();
    dateInicial: string | null = null;
    dateFinal: string | null = "";
    formattedDateInicial: string | null = formatDate(new Date(this.currentYear, this.currentMonth, 1), 'yyyy-MM-dd');
    formattedDateFinal: string | null = formatDate(this.lastDayOfCurrentMonth2, 'yyyy-MM-dd')
    menuInicial: any = false;
    menuFinal: any = false;
    isProcessing: boolean = false;
    isLoading: boolean = true;

    NomeDataInicial = "Data Inicial";
    NomeDataFinal = "Data Final";

    empresas: any = [];
    empreendimentos: any = [];

    service = new RelatorioService()

    PieChartDataDisponibilidadesLotes: any = ['Tipos', 'Quantidade'];
    PieChartDataAditamento: any = ['Tipos', 'Quantidade']

    /*-------------------------------Início Gráficos-----------------------*/

    pizzaChartDataDisponibilidade = {}
    pizzaChartDataAditamento = {}
    barChartDataRecebiveis = {};
    barChartDataContrato = {};

/*-------------------------------Fim Gráficos-----------------------*/
    handleDateSelected({ date, type }: { date: string; type: string }) {

        if (type === 'initial') {
            this.filter.dataInicial = date;
        } else if (type === 'final') {
            this.filter.dataFinal = date;
        }
        this.ObterRelatorio();
    }

    formatDateDash(date, format) {
        if (!date) return '';
        const [day, month, year] = date.split('/');
        if (format == 'dd/MM/YYYY') {
            return `${year}-${month}-${day}`;
        }
        return '';
    }

    async ObterRelatorio() {
        let empreendimentosIds = this.filter.empreendimentosIds ? this.filter.empreendimentosIds.toString() : '';
        let empresasIds = this.filter.empresasIds ? this.filter.empresasIds.toString() : '';
        await new RelatorioService().ObterRelatorioKPIUniversal(empreendimentosIds, empresasIds, this.filter.dataInicial, this.filter.dataFinal).then(
            (res) => {
                    if (!res || !res.data) {
                    this.isLoading = false;
                    return;
                    }
                this.item = res.data;
                const disponibilidadeLabels = ['Disponível', 'Vendido', 'Indisponível', 'Reservado', 'Invadido', 'Penhorado'];
                const disponibilidadeQuantidades = [
                    this.item.kPIDisponibilidadeLoteModel.quantidadeDisponiveis,
                    this.item.kPIDisponibilidadeLoteModel.quantidadeVendidos,
                    this.item.kPIDisponibilidadeLoteModel.quantidadeIndisponiveis,
                    this.item.kPIDisponibilidadeLoteModel.quantidadeReservados,
                    this.item.kPIDisponibilidadeLoteModel.quantidadeInvadidos,
                    this.item.kPIDisponibilidadeLoteModel.quantidadePenhorados
                ];
                this.pizzaChartDataDisponibilidade = {
                    labels: disponibilidadeLabels.filter((_, index) => disponibilidadeQuantidades[index] > 0),
                    datasets: [{
                        label: 'Disponibilidade', 
                        backgroundColor: [
                        'rgba(225, 189, 102, 0.9)',
                        'rgba(41, 151, 138, 0.7)',  
                        'rgba(37, 68, 80, 0.7)',    
                        'rgba(223, 107, 78, 0.7)',  
                        'rgba(119, 42, 184, 0.7)',  
                        'rgba(32, 152, 181, 0.7)'   
                        ],
                        borderColor: [
                        'rgba(225, 189, 102, 1)',
                        'rgba(41, 151, 138, 1)',
                        'rgba(37, 68, 80, 1)',
                        'rgba(223, 107, 78, 1)',
                        'rgba(119, 42, 184, 1)',
                        'rgba(32, 152, 181, 1)'
                        ],
                        data: disponibilidadeQuantidades.filter(q => q > 0),
                    }],
                }
                this.pizzaChartDataAditamento = {
                    labels: this.item.kPIAditamentosModels.filter(item => item.quantidade > 0)
                    .map((item) => item.nome),
                    datasets: 
                    [{
                        label: 'Valor',
                        backgroundColor: [
                        'rgba(225, 189, 102, 0.9)',
                        'rgba(41, 151, 138, 0.7)', 
                        'rgba(37, 68, 80, 0.7)',    
                        'rgba(223, 107, 78, 0.7)', 
                        'rgba(119, 42, 184, 0.7)',  
                        'rgba(32, 152, 181, 0.7)',  
                        'rgba(122, 154, 91, 0.7)', 
                        'rgba(231, 159, 140, 0.7)'  
                        ],
                        borderColor: [
                        'rgba(225, 189, 102, 1)', 
                        'rgba(41, 151, 138, 1)',  
                        'rgba(37, 68, 80, 1)',   
                        'rgba(223, 107, 78, 1)', 
                        'rgba(119, 42, 184, 1)',  
                        'rgba(32, 152, 181, 1)',  
                        'rgba(122, 154, 91, 1)',  
                        'rgba(231, 159, 140, 1)' 
                        ],
                         data: this.item.kPIAditamentosModels.filter(item => item.quantidade > 0)
                        .map((item) => item.quantidade),
                     }],
                }
                this.barChartDataRecebiveis = {
                    labels: [''],
                    datasets: [    
                    {
                    label: 'Total', 
                    backgroundColor: 'rgba(33, 150, 243, 0.5)',
                    borderColor: [
                        'rgba(33, 150, 243, 1)',
                    ],
                    borderWidth: 2,
                    borderRadius: 10,
                    data: [this.item.kPITotalRecebiveisModel.valorTotal],
                    barPercentage: 0.7
                    },
                    {
                    label: 'Recebido',
                    backgroundColor: 'rgba(76, 175, 80, 0.5)',
                    borderColor: [
                        'rgba(76, 175, 80, 1)',
                    ],
                    borderWidth: 2,
                    borderRadius: 10,
                    data: [this.item.kPITotalRecebiveisModel.valorRecebido],
                    barPercentage: 0.7
                    },
                    {
                    label: 'A Receber',
                    backgroundColor: 'rgba(246, 99, 30, 0.5)',
                    borderColor: [
                        'rgba(246, 99, 30, 1)',
                    ],
                    borderWidth: 2,
                    borderRadius: 10,
                    data: [this.item.kPITotalRecebiveisModel.valorReceber],
                    barPercentage: 0.7,      
                    },]   
                }
                this.barChartDataContrato = {
                    labels: [' '],
                    datasets: [    
                    {
                    label: 'Cancelados', 
                    backgroundColor: [
                    'rgba(255, 111, 111, 0.5)'
                    ],
                    borderColor: [
                    'rgba(255, 111, 111, 1)'
                    ],
                    borderWidth: 2,
                    borderRadius: 10,
                    data: [this.item.kPIVendasContratoModel.contratosCancelados],
                    barPercentage: 0.6
                    },
                    {
                    label: 'Em andamento',
                    backgroundColor: 'rgba(246, 99, 30, 0.5)',
                    borderColor: [
                        'rgba(246, 99, 30, 1)',
                    ],
                    borderWidth: 2,
                    borderRadius: 10,
                    data: [this.item.kPIVendasContratoModel.contratosEmAndamento],
                    barPercentage: 0.6
                    },
                    {
                    label: 'Quitados',
                    backgroundColor: 'rgba(76, 175, 80, 0.5)',
                    borderColor: [
                        'rgba(76, 175, 80, 1)',
                    ],
                    borderWidth: 2,
                    borderRadius: 10,
                    data: [this.item.kPIVendasContratoModel.contratosQuitados],
                    barPercentage: 0.6,      
                    },]
                }
                this.isLoading = false
            }, (err) => {
        this.isLoading = false;
        TratarErroApi(err)
      }
            
        );
    }
    filter: {
        empresasIds: any;
        empreendimentosIds: any;
        dataInicial: string | null;
        dataFinal: string | null;
    } = {
            empresasIds: 0,
            empreendimentosIds: 0,
            dataInicial: String(`${this.currentYear}-${this.currentMonth + 1}-01`),
            dataFinal: String(`${this.currentYear}-${this.currentMonth + 1}-${this.lastDayOfCurrentMonth}`)
        };


    headerstiposAditamento: any = [
        { text: 'TIPO DE ADITAMENTO', value: 'nome', sortable: false, align: 'left' },
        { text: 'QUANTIDADE', value: 'quantidade', sortable: true, align: 'center' },
    ]

    headersCorretor: any = [
        { text: 'NOME', value: 'nome', sortable: true },
        { text: 'VENDAS REALIZADAS', value: 'quantidadeVendido', align: 'center' },
        { text: 'VENDAS CANCELADAS', value: 'vendasCanceladas', align: 'center', sortable: true },
    ]

    titulo: string = 'Painel de receita';
    subTitulo: string = 'Receitas';

    options: any = {
        itemsPerPage: 15
    };

    headers: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: 'Nome', value: 'nome' }
    ];

    combinationChartData: any = [
        ['Dias', 'Recebido', 'A Receber', 'Total'],
        ['Receita', 100, 150, 50],
    ]

    ChartDataContrato: any = [
        ['Dias', 'Recebido', 'A Receber', 'testre'],
        ['CONTRATO', 0, 0, 0],
    ]

    chartSettings: any = {
        packages: ['bar']
    }

    desktopPieChartOptionsAditamento: any = {
         slices: [
        { color: '#E1BD66' },
        { color: '#29978A' },
        { color: '#254450' },
        { color: '#DF6B4E' }, 
        { color: '#772AB8' },
        { color: '#2098B5' },
    ],
        pieSliceBorderColor: 'none',
        legend: {
            position: "left",
            alignment: 'center',
            textStyle: { color: 'gray', fontSize: 14 },

        },
        pieSliceTextStyle: {
            fontSize: 15,
            color: 'black'
        },
        chartArea: {
            height: '100%',
            width: '80%'
        },

        backgroundColor: "transparent",

    };

    charOptionsContrato: any = {
        vAxis: { format: ' ###,###,###' },
        hAxis: {
            textStyle: {
                color: 'gray'
            }
        },
        seriesType: 'bars',
        bars: 'horizontal',
        series: {
            0: { type: 'bars', color: '#2196F3' },
            1: { type: 'bars', color: '#f6631e' },
            2: { type: 'bars', color: '#4CAF50' },
        },
        legend: {
            position: 'top',
            alignment: 'center',
            textStyle: {
                color: 'gray',
            },
        },
        bar: {
            groupWidth: '40%',
        },
        chartArea: {
            left: 100,
            width: '80%',  // Reduza a largura do chartArea para aumentar o espaçamento
            height: '80%'  // Ajuste a altura conforme necessário
        },
        theme: 'material',
        backgroundColor: "transparent",
    };

    charOptionsContratoRecebivel: any = {
        vAxis: { format: 'R$ ###,###,###.00' },
        hAxis: {
            textStyle: {
                color: 'gray'
            }
        },
        seriesType: 'bars',
        bars: 'horizontal',
        series: {
            0: { type: 'bars', color: '#2196F3' },
            1: { type: 'bars', color: '#f6631e' },
            2: { type: 'bars', color: '#4CAF50' },
        },
        legend: {
            position: 'top',
            alignment: 'center',
            textStyle: {
                color: 'gray',
            },
        },
        bar: {
            groupWidth: '40%',  // Ajuste este valor para aumentar o espaçamento
        },
        chartArea: {
            left: 100,
            width: '80%',  // Reduza a largura do chartArea para aumentar o espaçamento
            height: '80%'  // Ajuste a altura conforme necessário
        },
        theme: 'material',
        backgroundColor: "transparent",
    };

    get selecionarTodosEmpreendimentos() {
        return this.filter.empreendimentosIds.length === this.empreendimentos.length;
    }

    get SelecionarAlgunsEmpreendimentos() {
        return this.filter.empreendimentosIds.length > 0 && !this.selecionarTodosEmpreendimentos;
    }

    get iconEmpreendimento() {
        if (this.selecionarTodosEmpreendimentos) return 'mdi-close-box'
        if (this.SelecionarAlgunsEmpreendimentos) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
    }

    get textEmpreendimento() {
        if (this.selecionarTodosEmpreendimentos) return 'Desmarcar'
        if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
        return 'Selecionar todos'
    }

    SelecionarEmpreendimento() {
        this.$nextTick(() => {
            if (this.selecionarTodosEmpreendimentos) {
                this.filter.empreendimentosIds = []
            } else {
                this.filter.empreendimentosIds = this.empreendimentos.slice().map((x: any) => x.id)
            }
            this.ObterRelatorio();
        })
    }

    SelecionarEmpresa() {
        this.$nextTick(() => {
            if (this.selecionarTodosEmpresas) {
                this.filter.empresasIds = []
            } else {
                this.filter.empresasIds = this.empresas.slice().map((x: any) => x.id)
            }
            this.ObterRelatorio();
        })
    }

    get iconEmpresa() {
        if (this.selecionarTodosEmpresas) return 'mdi-close-box'
        if (this.SelecionarAlgunsEmpresas) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
    }

    get textEmpresa() {
        if (this.selecionarTodosEmpresas) return 'Desmarcar'
        if (this.SelecionarAlgunsEmpresas) return 'Selecionando...'
        return 'Selecionar todos'
    }

    get selecionarTodosEmpresas() {
        return this.filter.empresasIds.length === this.empresas.length
    }

    get SelecionarAlgunsEmpresas() {
        return this.filter.empresasIds.length > 0 && !this.selecionarTodosEmpresas
    }

    containerStyle() {
        return this.isMobile ? { height: '600px' } : { height: '300px' };
    }

    mounted() {
       
        this.ObterRelatorio();

        new EmpreendimentoService().Listar(-1, -1, ['nome'], [], '', [], '', '', 'Id, nome', '').then(
            res => this.empreendimentos = res.data.items
        );

        new EmpresaService().Listar(-1, -1, ['nome'], [], '', [], '', '', 'Id, nome, nomeFantasia', '').then(
            res => this.empresas = res.data.items
        );
    }
}

class Model {
    kPITotalRecebiveisModel = new kPITotalRecebiveisModel();
    kPIDisponibilidadeLoteModel = new kPIDisponibilidadeLoteModel();
    kPIAditamentosModels: any = [];
    kPIVendidosCorretor: any = [];
    kPIVendasContratoModel = new kPIVendasContratoModel();
    aditamentoTotal: number = 0;
}

class kPITotalRecebiveisModel {
    valorReceber: number = 0;
    valorRecebido: number = 0;
    valorTotal: number = 0;

}

class kPIDisponibilidadeLoteModel {
    quantidadeVendidos: number = 0;
    quantidadeDisponiveis: number = 0;
    quantidadeReservados: number = 0;
    quantidadeInvadidos: number = 0;
    quantidadePenhorados: number = 0;
    quantidadeIndisponiveis: number = 0;
}

class kPIVendasContratoModel {
    contratosEmAndamento: number = 0;
    contratosQuitados: number = 0;
    contratosCancelados: number = 0;
    contratosNovos: number = 0;
}
