
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Cartorio from "../../../core/models/imobiliaria/Cartorio";
import { CartorioService } from "../../../core/services/imobiliaria/CartorioService";
import { Estado, Municipio } from "../../../core/models/geral";
import { EnderecoService } from "@/core/services/geral/EnderecoService";
import * as jsonpatch from 'fast-json-patch';
import { TratarErroApi } from "@/assets/scripts/helper";
 
@Component
export default class CadastroCartorio extends Vue {
  @Prop() public item!: Cartorio;
  @Prop() public value!: string;

  service = new CartorioService();
  valid = true;
  dialog = false;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  estados: Estado[] = [];
  municipios: Municipio[] = [];
 
  $refs!: {
    form: HTMLFormElement
  }

  @Watch('item')
  Item(){
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  observer! : jsonpatch.Observer<Cartorio>; 

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 

    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    }
    else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }else{
      this.CarregarMunicipio()
    }
  }
 
  CarregarMunicipio() {
    new EnderecoService().ListarMunicipiosPorIdEstadual(this.item.estadoId).then(
      res => {
        this.municipios = res.data.items;
      });
  }

  Salvar() {
   if (this.$refs.form.validate()) { 
      let pacthModel = jsonpatch.generate(this.observer);
    
      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
      res => {
        this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close(); 
      },
      (err) => TratarErroApi(err)
    );
    }
  }

  Close() {
    this.dialog = false;
  }

  mounted() {  
    new EnderecoService().ListarEstados().then(
      res => {
        this.estados = res.data.items;  
      });
  }
}
