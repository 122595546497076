export default class InfraestruturaExternaImovel {
    public vagaGaragem: boolean = false;
    public aguaIndividual: boolean = false;
    public cercaEletrica: boolean = false;
    public portaoEletrico: boolean = false;
    public boxDespejo: boolean = false;
    public elevador: boolean = false;
    public aquiecimentoEletrico: boolean = false;
    public aquecimentoGas: boolean = false;
    public gasCanalizado: boolean = false;
    public alarme: boolean = false;
    public cftv: boolean = false;
    public lavanderia: boolean = false;
    public aquecimentoSolar: boolean = false;
    public jardim: boolean = false;
    public interfone: boolean = false;
    public portaria24Hrs: boolean = false;
}