
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { DespesaParcela,} from "../../../core/models/financeiro";
import { DespesaService} from "../../../core/services/financeiro";
import { SituacaoColor } from '@/assets/scripts/helper';

@Component
export default class DetalharDespesa extends Vue {
  @Prop() public item!: DespesaParcela;
  @Prop() public value!: string;

  service = new DespesaService(); 
  loading = false;
  tabActive = 0;
  dialog = false;
 
  headers = [
    { text: "Data da Baixa", value: "dataPagamento" },
    { text: "Forma de pagamento", value: "formaPagamento.nome" },
    { text: "Valor", value: "valor" },
    { text: "Encargos", value: "juros" },
    { text: "Multa", value: "multa" },
    { text: "Desconto", value: "desconto" },
    { text: "Total", value: "total" },
    { text: "Automática", value: "automatica" },
    { text: "Cancelado", value: "cancelado" },
    { text: "Comprovante", value: "comprovante.dados" },
  ];

  headersClassificacao = [
    { text: "", value: "actions", sortable: false },
    { text: "Centro Custo", value: "centroCustoId" },
    { text: "Conta Gerencial", value: "contaGerencialId" },
    { text: "Valor", value: "valor" },
    { text: "Percentual", value: "percentual" },
  ];

  headersParcelas = [
    { text: "", value: "actions", sortable: false },
    { text: "Parcela", value: "parcela" },
    { text: "Valor", value: "valor" },
    { text: "Saldo", value: "saldo" },
    { text: "Valor Pago", value: "valorPago" },
    { text: "Situação", value: "situacao.nome" },
  ]

  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false; 
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  Close() {
    this.dialog = false;
    this.tabActive = 0;
  }

  BaixarArquivo(arquivo: any){
    const link = document.createElement("a");
    link.href = `data:${arquivo.comprovante.tipo};base64,${arquivo.comprovante.dados}`;
    link.type = arquivo.comprovante.tipo;
    link.download = `${new Date().toLocaleString().replace('','_')}`;
    link.click();
    link.remove();
  }

  SituacaoColor(item){
    return SituacaoColor(item)
  }
}
