
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { InventarioService,AlmoxarifadoService,ProdutoService,} from "@/core/services/almoxarifado";
import { Inventario,Produto} from "@/core/models/almoxarifado";

@Component
export default class Atender extends Vue {
  @Prop() public item!: Inventario;
  @Prop() public value!: string;

  service = new InventarioService(); 
  almoxarifados = [] as any;
  produtos = [] as Produto[];

  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    formInventario: HTMLFormElement;
  };

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Produto", value: "produtoId" },
    { text: "Quantidade", value: "quantidade" },
  ];

  @Watch("item")
  Item() {
    if (this.$refs.formInventario) {
      this.$refs.formInventario.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  mounted() {
  new AlmoxarifadoService().ListarTudo().then(
    res => 
      {this.almoxarifados = res.data.items;}
    ); 

  new ProdutoService().ListarTudo().then(
    res => 
      {this.produtos = res.data.items}
    ); 
  }

  Salvar(executar: boolean = false) {
    if (this.$refs.formInventario.validate()) {
      this.item.executada = executar;
 
      this.service.Salvar(this.item).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
      });     
    }
  }

  Close() {
    this.dialog = false;
  }
}
