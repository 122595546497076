
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { InventarioService,AlmoxarifadoService,ProdutoService, GrupoProdutoService } from "@/core/services/almoxarifado";
import { Inventario,InventarioItem } from "@/core/models/almoxarifado";
import * as jsonpatch from "fast-json-patch";

@Component
export default class CadastroInventario extends Vue {
  @Prop() public item!: Inventario;
  @Prop() public value!: string;
  
  observer!: jsonpatch.Observer<Inventario>;

  service = new InventarioService();
  requisicaoItem = new InventarioItem();

  almoxarifados = [];
  listaProdutos = [];

  almoxarifadoItens:any = [];
  grupos = [];
  ItensFiltrados:any = [];

  tabActive = 0;
  valid = true;
  dialog = false;
  grupoId:number=0;
  itemIndex: number = -1;
  
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    formInventario: HTMLFormElement;
    formItemRequisicao: HTMLFormElement;
  };

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Id - Nome", value: "produtoId" },
    { text: "Qtde à ajustar", value: "quantidade" },
  ];

  @Watch("item")
  Item() {
    if (this.$refs.formInventario) {
      this.$refs.formInventario.resetValidation();
    }
  }

   @Watch("requisicaoItem")
  RequisicaoItem() {
    if (this.$refs.formItemRequisicao) {
      this.$refs.formItemRequisicao.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 
    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    } else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }

  @Watch("dialog") 
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  Adicionar() {
    if (this.$refs.formItemRequisicao.validate()) {
      if(this.itemIndex == -1){
        if(this.item.itens.find(x=>x.produtoId == this.requisicaoItem.produtoId)){
          this.$swal("Aviso", "Esse produto já foi incluído na lista.", "warning");
          return
        }
      }
      if (this.itemIndex > -1) {
        Object.assign(this.item.itens[this.itemIndex], this.requisicaoItem);
      } else {
        this.item.itens.push(this.requisicaoItem);
      }
      this.requisicaoItem = new InventarioItem();
      this.itemIndex = -1;
    }
  }

  Editar(item) {
    this.itemIndex = this.item.itens.indexOf(item);
    this.requisicaoItem = item;
  }

  Excluir(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,  
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      preConfirm:() => {
        if(item.id > 0){
         item.excluido = true;
         const index = this.item.itens.indexOf(item);
         delete this.item.itens[index]
         this.dialog = false;
         this.dialog = true;
         return true;
        }else{
        const index = this.item.itens.indexOf(item);
        this.item.itens.splice(index, 1);
        return this.$swal("Aviso", "Item excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
      }
    })
  }

  Salvar(executar: boolean = false) {
    if (this.$refs.formInventario.validate()) {
       this.item.executada = executar;
       
      let patchModel = jsonpatch.generate(this.observer);
        (this.item.id > 0 ? this.service.Patch(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
          (res) => {
            this.$swal( "Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
            this.$emit("salvou");
            this.Close();
          },
          (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
        });
      }
  }

  Close() {
    this.ItensFiltrados = [];
    this.dialog = false;
    this.tabActive = 0;
  }

  itemExcluido(item:any){
    if(item.excluido)
    return 'itemExcluido'
    else
    return ''
  }

  FiltrarProdutosPorGrupo(){
    if(this.grupoId > 0)
    this.ItensFiltrados = this.almoxarifadoItens.map((x) => x.itens)[0].filter(x => x.produto!.grupoId == this.grupoId)
  }

  CarregarItens() {
    let almoxarifadoService = new AlmoxarifadoService();
    almoxarifadoService.ObterPorId(this.item.almoxarifadoId, "Itens.Produto,Itens.Produto.UnidadeMedida").then(
      (res) => {
        this.almoxarifadoItens = [res.data]
        this.FiltrarProdutosPorGrupo();
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }
    );   
  }

  Imprimir(){
    const conteudo = document.getElementById("section-to-print")!.innerHTML;
    var tela_impressao = window.open('about:blank');
    tela_impressao!.document.write(conteudo);
    tela_impressao!.window.print();
    tela_impressao!.window.close();
  }

  mounted() {
    new ProdutoService().Listar(-1, -1, ['Nome'],[],'',[], '', '' , 'Id, Nome, IdNome', '').then(
      res => { this.listaProdutos = res.data.items},
    );

    new GrupoProdutoService().ListarTudo().then(
      res => {this.grupos = res.data.items}
    ); 
    
    new AlmoxarifadoService().ListarTudo().then(
      res => {this.almoxarifados = res.data.items}
    );
  }
}
