
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { EmpreendimentoService, UsuarioService } from "@/core/services/cadastros";
import { MedicaoService } from "@/core/services/projeto";
import { ExecucaoDocumento, ExecucaoUnidade, Medicao } from "@/core/models/projeto";
import { Execucao } from "@/core/models/projeto";
import { ArquivoService } from "@/core/services/geral/ArquivoService";
 
@Component
export default class ContratoFornecedor extends Vue {
  @Prop() public item!: any;
  @Prop() public value!: string;

  service = new MedicaoService();
 
  objetoExecucao = new Execucao();
  objetoMedicao = new Medicao();
  documentosMedicao = new ExecucaoDocumento();
   
  select:any = [];
  responsaveis = [];
  files = []
  itens = [];
  
  execucaoIndex = -1;
  documentoIndex = -1;
  tabActive = null;
  tab = null;

  dialogCarrosel: boolean = false;
  valid:boolean = true;
  dialog:boolean = false;
 
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
 
  $refs!: {
    form: HTMLFormElement;
  };

  headersEtapas = [
    { text: "Etapa", value: "etapa.nome"},
    { text: "Unidades", value: "quantidade"},
    { text: "Valor por Casa", value: "valorUnitario"},
    { text: "Valor Total", value: "valorTotal"},
    { text: "Caução", value: "caucao"},
    { text: "Início", value: "dataInicio"},
    { text: "Vencimento", value: "dataFim"},
  ]; 

  headersObservacaoMedicao: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Etapas", value: "contratoItemId"},
    { text: "Responsável", value: "responsavelId"},
    { text: "Data hora", value: "data"},
    { text: "Unidades", value: "quantidade"},
    { text: "Valor / Unidade", value: "valorUnitario"},
    { text: "Valor total", value: "total"},
    { text: "Observação", value: "observacao"},
    { text: "Fotos", value: "exibirCarrosel"},
  ];

  @Watch("objetoExecucao")
  ObjetoExecucao() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }else{
      this.itens = this.item.itens;
      this.UnidadesPorEmpreendimento();
    }
  } 

  
  Salvar() {
    if(this.objetoMedicao.execucoes.length ==0){
      this.$swal("Erro!", "É necessário registrar a medição.", "error")
      return;
    }

    this.objetoMedicao.contratoFornecedorId = this.item.id
    this.objetoMedicao.despesa = undefined;
 
    let modelo = this.objetoMedicao;
    this.service.Salvar(modelo).then(
      (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close();
      },
     (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      });
  }
 
  Close() {
    this.dialog = false;
    this.objetoMedicao = new Medicao;
    this.objetoExecucao = new Execucao();
    this.select = [];
  }

  unidades = [];

  UnidadesPorEmpreendimento(){
    new EmpreendimentoService().ListarUnidades(this.item.empreendimentoId, undefined, this.item.empresaId).then(
      res => {
        this.unidades = res.data
      }) 
    }

  AbrirCarrosel(item:Execucao){
    this.objetoExecucao = item
    this.dialogCarrosel = true;
  }

 
  ValorTotalMedicao() {
    this.objetoExecucao.total = this.objetoExecucao.quantidade * this.objetoExecucao.valorUnitario
  }

  ValorUnitario() {
    this.item.itens.find((x) => x.id == this.objetoExecucao.contratoItemId).valorUnitario
    this.objetoExecucao.valorUnitario = this.item.itens.find((x) => x.id == this.objetoExecucao.contratoItemId).valorUnitario
  }


  Executar(){
    this.select.forEach((x) => {
      let model = new ExecucaoUnidade();
      model.unidadeId = x;
      this.objetoExecucao.unidades.push(model)
    });
  }

  async AdicionarMedicao(){
    if (this.$refs.form.validate()) {

     // if(this.objetoExecucao.quantidade > this.itens.reduce((x:any,y:any) => x + y.quantidade, 0))
    // return this.$swal("Atenção", "A quantidade realizada não pode ser maior do que o total de unidades do contrato.", "warning")

      if(this.select.length != this.objetoExecucao.quantidade)
        return this.$swal("Atenção", "A quantidade realizada precisa ser igual que a quantidade de unidades selecionada.", "warning")
        
      if (this.select.length == 0 && this.objetoExecucao.quantidade == 0) 
        return this.$swal("Atenção", "É necessário incluir ao menos uma unidade.", "warning");
      
      this.Executar();
      this.objetoExecucao.responsavelId = JSON.parse(localStorage.sessionApp.split('.')).dados.usuarioId
 
    if(this.execucaoIndex == -1){
      this.objetoMedicao.execucoes.push(this.objetoExecucao)
        if(this.objetoMedicao.execucoes.length != 0){
          for(let i = 0; i < this.files.length; i++){
            let dados = await new ArquivoService().Ler(this.files[i]);
            this.documentosMedicao.dados = dados.replace(/^[^,]*,/, "");
            this.objetoExecucao.documentos.push(this.documentosMedicao)
            this.documentosMedicao = new ExecucaoDocumento();
          }
        }
      this.objetoExecucao = new Execucao();
      this.select = [];
    }
     
    this.execucaoIndex = -1;
    this.files = [];
    }
  }

  ExcluirMedicao(item) {
    console.log(item)
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
    }).then((result) => {
      if (result.value) {
        const index = this.objetoMedicao.execucoes.indexOf(item);
        this.objetoMedicao.execucoes.splice(index, 1);
        this.$swal("Aviso", "Registro excluído com sucesso!", "success");
      }
    }); 
  }
  mounted() {
    new UsuarioService().ListarTudo().then((res) => {
      this.responsaveis = res.data.items;
    });
  }
}
 
