
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ReceitaService } from "@/core/services/financeiro";
import { ReceitaParcela } from "@/core/models/financeiro";

@Component
export default class AjusteAmortizacao extends Vue { 
  @Prop() public item!: ReceitaParcela;
  @Prop() public value!: string;

  tipoOperacaoId = 1;

  receitaService = new ReceitaService();
  valid = true;
  dialog = false;

  tipos = [
    {id:1, nome:'Ajustar pelo valor da parcela'},
    {id:2, nome:'Ajustar pelo valor da amortização'}
]
  
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
  }; 

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    } 
  }
  
  Salvar() {
    if (this.$refs.form.validate()) {
      let modelo = this.item;

      if(this.item.receita!.contrato!.tipoAmortizacaoId == 1){
         this.receitaService.AjustarParcelaPadrao(modelo.id, modelo.valor).then(
          (res) => {
            this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
            this.$emit("salvou");
            this.Close();
          },
          (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
          });
      }else{
         this.receitaService.AjustarAmortizacao(modelo.id, this.tipoOperacaoId, modelo.saldoAmortizacaoInicioPeriodoCorrigido, modelo.valor).then(
          (res) => {
            this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
            this.$emit("salvou");
            this.Close();
          },
          (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
          });
      }
    }
  }

  Close(){
    this.dialog = false;
  }
 
  mounted(){
  
  }
}
