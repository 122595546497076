
import { TratarErroApi } from "@/assets/scripts/helper";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {ReceitaParcela} from "../../../core/models/financeiro";
import { ReceitaService,SituacaoReceitaParcelaService} from "../../../core/services/financeiro";

@Component
export default class EditarParcela extends Vue {
  @Prop() public item!: ReceitaParcela;
  @Prop() public value!: string;

  service = new ReceitaService();
  situacoes: [] = [];
  
  valid = true;
  dialog = false;
  tabActive = null;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  Salvar(item) {
    let modelo = item;

    this.service.EditarParcela(item.id, modelo, false).then(
      (res) => { 
        this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        this.Close();
      },
       (err) => TratarErroApi(err)
      );
  }

  Close() {
    this.dialog = false;
  }

  mounted() { 
    new SituacaoReceitaParcelaService().ListarTudo().then(
      (res) => {
        this.situacoes = res.data.items;
      }
    );
  }
}
